import DynamicComponent from '../DynamicComponent'

export function Page({ blok, data, preview }) {
  return (
    <div style={{ minHeight: '100vh' }}>
      {blok.body?.map((nestedBlok, index) => (
        <DynamicComponent
          key={index}
          blok={nestedBlok}
          data={data}
          preview={preview}
        />
      ))}
    </div>
  )
}
