import { ProductCard } from './ProductCard'
import { ProductCardFullImage } from './ProductCardFullImage'

export const MainProductCard = ({
  product,
  blok,
  data,
  onClickCard,
  CSObject,
}) => {
  const getProductCard = () => {
    if (blok?.product_card?.[0]?.component === 'product_card') {
      return (
        <ProductCard
          product={product}
          CSObject={CSObject}
          blok={blok?.product_card[0]}
          data={data}
          onClickCard={() => {
            if (onClickCard) {
              onClickCard()
            }
          }}
        />
      )
    } else if (
      blok?.product_card?.[0]?.component === 'product_card_full_image'
    ) {
      return (
        <ProductCardFullImage
          product={product}
          CSObject={CSObject}
          blok={blok?.product_card[0]}
          data={data}
          onClickCard={() => {
            if (onClickCard) {
              onClickCard()
            }
          }}
        />
      )
    }

    return false
  }

  return getProductCard()
}
