import classNames from 'classnames'
import { getFontWeight } from '../../helpers/getFontWeight'
import { getFontSize } from '../../helpers/getFontSize'
import { getTextAlign } from '../../helpers/getTextAlign'
import { sbEditable } from '@storyblok/storyblok-editable'
import { getLineHeight } from '../../helpers/getLineHeight'
import { getText } from '../../helpers/getText'
import { useCheckNestedLink } from '../../hooks/useCheckNestedLink'
import { getHref } from '../../helpers/getHref'

export function Text({ data, blok, preview }) {
  let isLink = blok?.link?.url?.length > 0 || blok?.link?.id?.length > 0
  const [isNestedStyle] = useCheckNestedLink({ blok, preview, isLink: isLink })

  let Tag
  let props = sbEditable(blok)
  if (isLink) {
    Tag = 'a'
    props.href = getHref(blok?.link)
  } else {
    Tag = `${blok.text_type}`
  }

  if (blok?.link_target && blok?.link_target?.length > 0) {
    props.target = blok?.link_target
  }

  if (blok?.anchor_name?.length > 0) {
    props.id = blok?.anchor_name
  }

  let fontWeight = getFontWeight(blok?.font_weight)
  let fontSize = getFontSize(blok?.font_size)
  let fontLineHeight = getLineHeight(blok?.line_height)

  if (blok?.font_weight_desktop) {
    fontWeight += ' lg:' + getFontWeight(blok?.font_weight_desktop)
  }
  if (blok?.font_size_desktop) {
    fontSize += ' lg:' + getFontSize(blok?.font_size_desktop)
  }
  if (blok?.line_height_desktop) {
    fontLineHeight += ' lg:' + getLineHeight(blok?.line_height_desktop)
  }

  return (
    <Tag
      {...props}
      style={{ ...getStyle({ blok }), ...isNestedStyle }}
      className={classNames(
        isLink ? 'underline-offset-4 hover:underline ' : '',
        'block',
        //fontTitle,
        fontWeight,
        fontSize,
        fontLineHeight,
        getTextAlign(blok?.text_align),
      )}
    >
      {getText(blok?.text, data)}
    </Tag>
  )
}

const getStyle = ({ blok }) => {
  let styles = {}
  styles['color'] = blok.text_color ? blok.text_color : 'black'

  return styles
}
