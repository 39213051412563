const classes = {
  1: 'row-start-1',
  2: 'sm:row-start-2',
  3: 'sm:row-start-3',
  4: 'sm:row-start-4',
  5: 'sm:row-start-5',
  6: 'sm:row-start-6',
  7: 'sm:row-start-7',
}

export const geMobileOrder = ({ mobile }) => {
  const mobileClass = classes[mobile] || ''

  return `${mobileClass} lg:row-start-auto`.trim()
}
