import StoryblokClient from 'storyblok-js-client'

const Storyblok = new StoryblokClient({
  accessToken: process.env.STORYBLOK_ACCESS_TOKEN,
  cache: {
    clear: 'auto',
    type: 'memory',
  },
})

export const getStory = async ({ slug, locale, preview }) => {
  let sbParams = {
    version: 'published',
    language: locale,
  }

  if (preview) {
    sbParams.version = 'draft'
    sbParams.cv = Date.now()
  }

  try {
    let { data } = await Storyblok.get(`cdn/stories/${slug}`, sbParams)

    return data
  } catch (e) {
    throw `Storyblok slug "${slug}" not found`
  }
}

export const getFolder = async ({
  slug,
  locale,
  preview,
  isStartpage = null,
}) => {
  const per_page = 99
  let sbParams = {
    version: 'published',
    language: locale,
  }

  if (isStartpage != null) {
    sbParams.is_startpage = isStartpage
  }

  if (preview) {
    sbParams.version = 'draft'
    sbParams.cv = Date.now()
  }

  sbParams.starts_with = slug

  let initial = await Storyblok.get('cdn/stories/', {
    ...sbParams,
    per_page: 1,
    page: 1,
  })

  let requests = []
  let totalPages = Math.ceil(initial.headers.total / per_page)
  for (let i = 1; i <= totalPages; i++) {
    requests.push(
      Storyblok.get('cdn/stories/', {
        ...sbParams,
        per_page: per_page,
        page: i,
      }),
    )
  }

  let all = []
  for (const d of await Promise.all(requests)) {
    all = [...all, ...d?.data.stories]
  }
  return { stories: all }
}

export const getSpace = async () => {
  let { data } = await Storyblok.get(`cdn/spaces/me/`)

  return data
}

export default Storyblok
