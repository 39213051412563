import Script from 'next/script'
import Head from 'next/head'

export const getStampedScript = ({ data }) => {
  if (data?.settingsData?.stamped_public_api_key) {
    return (
      <>
        <Script
          src="https://cdn1.stamped.io/files/widget.min.js"
          strategy="lazyOnload"
        />
        <Head>
          <link
            rel="stylesheet"
            href="https://cdn1.stamped.io/files/widget.min.css"
            type="text/css"
          />
        </Head>
      </>
    )
  }

  return null
}
