import { getProductImages } from '../../../helpers/getProductImage'
import { Variant } from './Variant'
import Link from 'next/link'
import classNames from 'classnames'
import Image from 'next/future/image'
import { getProductPrice } from '../../../helpers/getProductPrice'
import { ProductCardRibbons } from './ProductCardRibbons'
import { getVariants } from '../../../helpers/getVariants'
import { getProductUri } from '../../../helpers/getProductUri'
import { ModalUpSell } from '../upsell/ModalUpSell'
import { getImageAlt } from '../../../helpers/getImageAlt'
import { getLowestPrice } from '../../../helpers/getLowestPrice'
import { getFontWeight } from '../../../helpers/getFontWeight'
import { getFontSize } from '../../../helpers/getFontSize'
import { getLineHeight } from '../../../helpers/getLineHeight'
import { getTextAlign } from '../../../helpers/getTextAlign'
import { Checkbox } from '../../atoms/Checkbox'
import { useContext } from 'react'
import { observer } from 'mobx-react'
import { CompareStoreContext } from '../../../store/compareStore'
import { useRouter } from 'next/router'
import { getHref } from '../../../helpers/getHref'
import { Button } from '../../atoms/Button'

export const getWidthAndHeight = (blok) => {
  if (blok?.image_size === 'square') {
    return { width: 900, height: 900 }
  } else if (blok?.image_size === 'full') {
    return { width: 1000, height: 1000 }
  }

  return { width: 900, height: 900 }
}

export const ProductCard = ({ product, CSObject, blok, data, onClickCard }) => {
  let images = getProductImages(product, blok)
  let prices = getProductPrice({ CSObject, product })
  const size = getWidthAndHeight(blok)

  return (
    <div
      className="group relative"
      onClick={() => {
        if (onClickCard) {
          onClickCard()
        }
      }}
    >
      <Link href={getProductUri(product.uri)}>
        <a>
          <div className={classNames('w-full relative overflow-hidden')}>
            {blok?.show_compare === 'yes' && (
              <div className={'absolute p-1'}>
                <CompareButton product={product} blok={blok} />
              </div>
            )}
            <div className={'absolute bottom-2 w-full left-2'}>
              <ProductCardRibbons
                data={data}
                product={product}
                CSObject={CSObject}
              />
            </div>

            {images && (
              <Image
                src={images?.[0].url}
                alt={getImageAlt({ alt: product.name, image: images?.[0].url })}
                width={size.width}
                height={size.height}
              />
            )}
            {blok?.hover_effect === 'image_2' && images.length > 1 && (
              <div
                className={classNames(
                  'absolute inset-0',
                  'opacity-0 transition-opacity',
                  'group-hover:opacity-100 group-hover:transition-opacity',
                )}
              >
                <Image
                  src={images?.[1].url}
                  alt={getImageAlt({
                    alt: product.name,
                    image: images?.[1].url,
                  })}
                  width={size.width}
                  height={size.height}
                />
              </div>
            )}
          </div>
          <div className={'w-full pt-2 pb-1 flex items-center'}>
            <div className={'flex-1 self-top'}>
              <p
                style={{
                  color: blok?.title_text_style?.[0]?.text_color
                    ? blok?.title_text_style?.[0]?.text_color
                    : 'black',
                }}
                className={classNames(
                  getFontWeight(blok?.title_text_style?.[0]?.font_weight),
                  getFontSize(blok?.title_text_style?.[0]?.font_size) ??
                    'text-xs md:text-base', // this was the default before we added text_style
                  getLineHeight(blok?.title_text_style?.[0]?.line_height),
                  getTextAlign(blok?.title_text_style?.[0]?.text_align),
                )}
              >
                {product.name}
              </p>

              <div
                style={{
                  color: blok?.price_text_style?.[0]?.text_color
                    ? blok?.price_text_style?.[0]?.text_color
                    : 'rgb(75 85 99)', // this was the default before we added text_style
                }}
                className={classNames(
                  'md:-mt-1',
                  getFontWeight(blok?.price_text_style?.[0]?.font_weight),
                  getFontSize(blok?.price_text_style?.[0]?.font_size) ??
                    'text-xs md:text-sm', // this was the default before we added text_style
                  getLineHeight(blok?.price_text_style?.[0]?.line_height),
                  getTextAlign(blok?.price_text_style?.[0]?.text_align),
                )}
              >
                {blok?.show_from === 'yes' ? (
                  <div>
                    {getLowestPrice({
                      product,
                      CSObject,
                      fromTitle: data?.settingsData.product_card_from_title
                        ? data?.settingsData.product_card_from_title
                        : 'From ',
                    })}
                  </div>
                ) : (
                  <>
                    {prices?.price !== prices?.priceBeforeDiscount && (
                      <span className="line-through mr-2">
                        {prices?.priceBeforeDiscount}
                      </span>
                    )}
                    {prices?.price}
                  </>
                )}
              </div>
            </div>
            {blok?.enable_quick_add && (
              <div className={'pr-3'}>
                <ModalUpSell
                  addedToCartTitle={data?.settingsData?.added_to_cart_message}
                  buttonTitle={
                    data?.settingsData?.add_to_cart_quick_add_button_title
                  }
                  sizeModalTitle={data?.settingsData?.pick_a_size_title}
                  sizePickerDesign={blok?.size_picker_design}
                  product={product}
                  buttonVariant={data?.settingsData?.quick_add_button_variant}
                />
              </div>
            )}
          </div>
        </a>
      </Link>
      {product?.relatedProducts?.length > 0 && blok.variant_design != 'hide' && (
        <div className={'flex items-center'}>
          {getVariants({
            mainProduct: product,
            relatedProducts: product?.relatedProducts,
          })
            .splice(0, 5)
            .map((variant, index) => (
              <Variant
                variant={variant}
                design={blok.variant_design}
                key={index}
              />
            ))}
          {product?.relatedProducts?.length >= 5 && (
            <div className={'ml-2 text-gray-500'}>
              + {product?.relatedProducts?.length - 4}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const CompareButton = observer(({ product, blok }) => {
  const { addProduct, ids, removeProduct } = useContext(CompareStoreContext)
  return (
    <div className={'bg-white flex items-center space-x-2'}>
      {blok?.compare_title && <p>{blok?.compare_title}</p>}
      <Checkbox
        h={'h-4'}
        w={'w-4'}
        checked={ids.includes(product?.product)}
        onChange={(e) => {
          e.preventDefault()
          e.stopPropagation()
          if (!ids.includes(product?.product)) {
            addProduct({
              id: product?.product,
              url: blok?.compare_url ? getHref(blok?.compare_url) : '/compare',
            })
          } else {
            removeProduct(product?.product)
          }
        }}
      ></Checkbox>
    </div>
  )
})

export const CompareBar = observer(({ blok }) => {
  const { ids, remove } = useContext(CompareStoreContext)
  const router = useRouter()
  return ids.length > 0 ? (
    <div
      style={{
        backgroundColor: blok?.compare_bar_background_color || 'black',
        zIndex: 10,
      }}
      className={
        'fixed bottom-0 left-0 right-0 min-h-[92px] bg-black  flex items-center justify-center lg:space-x-10 space-x-3 p-3'
      }
    >
      <div style={{ color: blok?.compare_bar_text_color || 'white' }}>
        <p>
          {blok?.compare_bar_info_text
            ? blok?.compare_bar_info_text?.replace('%count%', ids?.length)
            : 'Add products to compare'}
        </p>
        <p>
          {blok?.compare_bar_sub_info_text
            ? blok?.compare_bar_sub_info_text?.replace('%count%', ids?.length)
            : 'Maximum of 2 products at a time'}
        </p>
      </div>
      <Button
        variant={blok?.compare_button_variant || 'primary'}
        disabled={ids.length < 2}
        onClick={async () => {
          if (ids.length > 1) {
            await router.push(`/compare/?product1=${ids[0]}&product2=${ids[1]}`)
            remove()
          }
        }}
      >
        {blok?.compare_bar_button_text || 'Compare'}
      </Button>
    </div>
  ) : null
})
