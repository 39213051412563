import { useContext, useEffect, useState } from 'react'
import { Dialog } from '@headlessui/react'
import { CartStoreContext } from '../../../store/cartStore'
import { observer } from 'mobx-react'
import { useRouter } from 'next/router'
import { Button } from '../../atoms/Button'
import { SideModal } from '../modals/SideModal'
import classNames from 'classnames'
import { getFontWeight } from '../../../helpers/getFontWeight'
import { getFontSize } from '../../../helpers/getFontSize'
import { getText } from '../../../helpers/getText'

export const CountryLanguageChooser = ({ blok, data }) => {
  return (
    <CountryChooser
      blok={blok}
      textColor={blok?.text_color}
      autoOpen={false}
      locale={data.locale}
      locales={data.locales}
      data={data}
    />
  )
}

export const CountryChooser = observer(
  ({ locale, locales, blok, autoOpen, textColor, data }) => {
    const {
      CSObject,
      setCountryAndLanguage,
      getRouteQuery,
      getCurrentRoute,
      languageDontMatchText,
    } = useContext(CartStoreContext)
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const router = useRouter()
    const { pathname, asPath, query } = router

    useEffect(() => {
      if (
        languageDontMatchText &&
        languageDontMatchText.length > 2 &&
        !window.show_max_one_moda_hax
      ) {
        window.show_max_one_moda_hax = true
        setShowModal(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languageDontMatchText])

    useEffect(() => {
      if (
        CSObject?.location?.shipTo === false &&
        !window.show_max_one_moda_hax
      ) {
        window.show_max_one_moda_hax = true
        setShowModal(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [CSObject?.location])
    /*
    Todo fix this
    Probably need a settings in storyblok if it is going to autoopen
    And only one country chooser per site should auto open.
    useEffect(() => {
      if (CSObject?.location?.shipTo === false ) {
        setShowModal(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [CSObject?.CSObject?.location]) */

    const fontWeight = getFontWeight(blok?.font_weight)
    const fontSize = getFontSize(blok?.font_size)

    return (
      <div className={'flex items-center'}>
        <div
          className={classNames(
            'flex items-center text-sm cursor-pointer font-medium',
            fontWeight,
            fontSize,
          )}
          style={{ color: textColor }}
          onClick={() => {
            setShowModal(!showModal)
          }}
        >
          <span>{CSObject?.location?.country}</span>
          <span
            className="border-l ml-2 py-3 border"
            style={{ borderColor: textColor }}
          />
          <span className="ml-2">{CSObject?.selection?.currency}</span>
        </div>
        {CSObject && (
          <Modal
            loading={loading}
            blok={blok}
            locale={locale}
            locales={locales}
            show={showModal}
            data={data}
            CSObject={CSObject}
            languageDontMatchText={languageDontMatchText}
            onClose={() => setShowModal(false)}
            onSwitch={async ({ country, language }) => {
              setLoading(true)

              await setCountryAndLanguage({
                country,
                language,
              })

              if (data?.settingsData?.one_one_market !== 'yes') {
                const current = await getCurrentRoute({
                  path: router.asPath,
                  locale: locale,
                })

                if (current) {
                  const q = await getRouteQuery({
                    current: current,
                    language: language,
                  })

                  const search = removeCountryFromPath({
                    router: router,
                    path: window.location.search,
                  })

                  await router.push(
                    { pathname, query: q.query },
                    q.path + search,
                    {
                      locale: language,
                    },
                  )
                } else {
                  const search = removeCountryFromPath({
                    router: router,
                    path: asPath.split('?')?.[1] ?? '',
                  })

                  const path = asPath.split('?')?.[0] ?? asPath
                  await router.push({ pathname, query }, path + search, {
                    locale: language,
                  })
                }
              } else {
                // TODO: Make into one reload?
                await router.push({ pathname, query }, asPath, {
                  locale: language,
                })
                location.reload()
              }

              setLoading(false)
              setShowModal(false)
            }}
          />
        )}
      </div>
    )
  },
)

const removeCountryFromPath = ({ router, path }) => {
  if (router?.query?.country) {
    const params = new URLSearchParams(path?.replace('/', '') ?? '')
    if (params) {
      params.delete('country')
    }
    let search = params.toString()
    if (search.length > 0) {
      search = '?' + search
    }
    return search ?? ''
  }
  return path
}

const Modal = ({
  show,
  blok,
  onClose,
  CSObject,
  onSwitch,
  locale,
  locales,
  loading,
  data,
  languageDontMatchText,
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState(locale ?? '')
  const [selectedCountry, setSelectedCountry] = useState(
    CSObject?.location?.country ?? '',
  )
  const cantShipToTitle = blok?.cant_ship_to_title?.replace(
    '%country%',
    CSObject?.location?.name,
  )

  useEffect(() => {
    setSelectedLanguage(locale ?? '')
  }, [locale])

  useEffect(() => {
    setSelectedCountry(CSObject?.location?.country ?? '')
  }, [CSObject?.location?.country])

  return (
    <SideModal show={show} onClose={onClose} title={getText(blok?.title, data)}>
      <>
        {CSObject?.location?.shipTo === false && (
          <div className={'w-full mb-8 mt-8 font-bold'}>
            <p>{getText(cantShipToTitle, data)}</p>
          </div>
        )}
        {languageDontMatchText && languageDontMatchText.length > 2 && (
          <div className={'w-full mb-8 mt-8 font-bold'}>
            <p>{getText(languageDontMatchText, data)}</p>
          </div>
        )}
        <div className={'flex space-x-4'}>
          <div
            className={classNames(
              data?.settingsData?.one_one_market === 'yes' ? 'w-full' : 'w-1/2',
            )}
          >
            <Dialog.Title className="font-medium mb-1">
              {getText(blok?.country_title, data)}
            </Dialog.Title>
            <select
              className={'w-full'}
              onChange={(event) => {
                setSelectedCountry(event.target.value)
              }}
              value={selectedCountry}
            >
              {CSObject?.location?.shipTo === false && (
                <option value={''}>-</option>
              )}
              {CSObject?.countries?.map((country, index) => {
                return (
                  <option key={index} value={country.country}>
                    {country.name} | {country.currency}
                  </option>
                )
              })}
            </select>
          </div>

          {data?.settingsData?.one_one_market === 'yes' ? null : (
            <div
              className={classNames(
                data?.settingsData?.one_one_market === 'yes'
                  ? 'w-full'
                  : 'w-1/2',
              )}
            >
              <Dialog.Title className="font-medium  mb-1">
                {getText(blok?.language_title, data)}
              </Dialog.Title>
              <select
                className={'w-full'}
                onChange={(event) => {
                  setSelectedLanguage(event.target.value)
                }}
                value={selectedLanguage}
              >
                {CSObject?.languages
                  ?.filter((lang) => locales.includes(lang.language))
                  .map((lang, index) => {
                    return (
                      <option key={index} value={lang.language}>
                        {lang.name}
                      </option>
                    )
                  })}
              </select>
            </div>
          )}
        </div>
        <div className={'flex'}>
          <Button
            loading={loading}
            variant={blok?.button_style}
            className={'w-full mt-6'}
            onClick={() => {
              if (
                data?.settingsData?.one_one_market === 'yes' &&
                selectedCountry
              ) {
                const country = CSObject.countries.find(
                  (c) => c.country === selectedCountry,
                )
                onSwitch({
                  country: selectedCountry,
                  language: country.language,
                })
              } else {
                if (selectedCountry && selectedLanguage) {
                  onSwitch({
                    country: selectedCountry,
                    language: selectedLanguage,
                  })
                }
              }
            }}
          >
            {getText(blok?.confirm_button_title, data)}
          </Button>
        </div>
      </>
    </SideModal>
  )
}
