import { useContext, useEffect, useState } from 'react'
import { CartStoreContext } from '../store/cartStore'

export const useRelatedProductsFetcher = (items) => {
  const [products, setProducts] = useState([])
  const { fetchProduct } = useContext(CartStoreContext)
  useEffect(() => {
    const get = async () => {
      let relatedProducts = []

      const temp = []
      for (const item of items) {
        if (item?.product?.product) {
          const response = await fetchProduct(item?.product?.product)
          if (response?.product?.relatedProducts.length > 0) {
            temp.push(
              response?.product?.relatedProducts?.filter((v) => {
                return v.relation === 'standard'
              }),
            )
          }
        }
      }

      while (relatedProducts.length < 4) {
        if (temp.length === 0) {
          break
        }

        if (temp.length === 1) {
          relatedProducts.push(...temp[0])
          temp.pop()
        } else {
          const rps = temp.pop()
          if (rps.length > 0) {
            relatedProducts.push(rps[0])
          }
        }
      }

      if (relatedProducts.length > 4) {
        relatedProducts.splice(4, relatedProducts.length - 1)
      }

      const ids = items.map((i) => i.product.uri)
      // remove duplicates and all ready added products in cart
      // relatedProducts = relatedProducts.filter((p) => !ids.includes(p.uri))

      setProducts(relatedProducts)
    }
    get()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  return [products]
}
