import { Button } from '../atoms/Button'
import classNames from 'classnames'
import { useRef, useEffect, useState } from 'react'
import { getHref } from '../../helpers/getHref'
import Link from 'next/link'
import { getImage } from '../../helpers/getImage'
import { sbEditable } from '@storyblok/storyblok-editable'
import { getText } from '../../helpers/getText'

export const Video = ({ blok, data }) => {
  const [isClicked, setIsClicked] = useState(false)
  const getTitlePosition = () => {
    if (blok?.title_position === 'top') {
      return 'justify-start top-[10%]'
    }
    if (blok?.title_position === 'middle') {
      return 'justify-center'
    }
    if (blok?.title_position === 'bottom') {
      return 'justify-end bottom-[10%]'
    }

    return 'justify-center'
  }

  const vidRef = useRef()
  useEffect(() => {
    if (blok.auto_play !== 'no') {
      vidRef.current.play()
    }
  }, [blok.auto_play])

  return (
    <div
      {...sbEditable(blok)}
      className={classNames('relative h-full w-full overflow-hidden')}
    >
      <div
        className={classNames(
          'absolute w-full h-full flex flex-col items-center px-10 z-10',
          getTitlePosition(),
        )}
      >
        {blok?.pre_header && (
          <p
            className={
              'mb-1 text-lg lg:text-xl text-white font-title tracking-widest'
            }
          >
            {getText(blok?.pre_header, data)}
          </p>
        )}
        {blok?.title && (
          <h1 className="text-4xl text-center font-medium tracking-tight text-white lg:text-6xl font-title mb-3">
            {getText(blok?.title, data)}
          </h1>
        )}
        {blok?.subtitle && (
          <p className="text-lg lg:text-xl text-white font-title mb-3">
            {getText(blok?.subtitle, data)}
          </p>
        )}

        {blok?.button_title && (
          <Button className={'mt-4'} variant={blok?.button_style}>
            <Link href={getHref(blok?.button_link)}>
              <a>{getText(blok?.button_title, data)}</a>
            </Link>
          </Button>
        )}
      </div>

      {blok?.auto_play === 'no' && !isClicked && (
        <div
          onClick={() => {
            setIsClicked(true)
            vidRef.current.play()
          }}
          style={{
            left: '50%',
            transform: 'translate(-50%, -50%)',
            top: '50%',
            cursor: 'pointer',
          }}
          className="absolute w-[130px] h-[130px] bg-gray-400 cursor-pointer flex justify-center items-center z-20 hover:bg-gray-500"
        >
          <div
            style={{
              borderStyle: 'solid',
              borderWidth: '2.5em 0 2.5em 5em',
              borderColor: 'transparent transparent transparent #000',
              opacity: 0.75,
            }}
          />
        </div>
      )}

      <video
        className={'object-cover h-full z-0'}
        ref={vidRef}
        width="100%"
        muted
        loop
        playsInline
        poster={
          !blok?.poster?.filename && !blok?.video?.filename
            ? getImage(false)
            : blok?.poster?.filename ?? ''
        }
        {...(blok.auto_play !== 'no' ? { autoPlay: true } : {})}
      >
        <source src={blok?.video?.filename} />
      </video>
    </div>
  )
}
