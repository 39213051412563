import { useContext, useEffect, useState } from 'react'
import { Select2 } from '../atoms/Input'
import { CartStoreContext } from '../../store/cartStore'
import { getProductImages } from '../../helpers/getProductImage'
import classNames from 'classnames'
import { Button } from '../atoms/Button'
import { sbEditable } from '@storyblok/storyblok-editable'
import { useRouter } from 'next/router'
import { getBorderRadius } from '../../helpers/getBorderRadius'

export const Compare = ({ blok, data, preview }) => {
  const router = useRouter()
  const [products, setProducts] = useState([])
  const { fetchAllProducts, addToCart, fetchProductSilent } =
    useContext(CartStoreContext)
  useEffect(() => {
    const fetch = async () => {
      if (router?.query?.product1 && router?.query?.product2) {
        const p1 = await fetchProductSilent(router?.query?.product1)
        const p2 = await fetchProductSilent(router?.query?.product2)
        if (p1?.product && p2?.product) {
          setProducts([p1?.product, p2?.product] || [])
        }
      }
    }
    fetch()
  }, [router?.query])

  useEffect(() => {
    // ONLY FOR STORYBLOK PREVIEW
    if (preview) {
      const fetch = async () => {
        const allProducts = await fetchAllProducts()
        const p1 = allProducts?.products?.[0]
        const p2 = allProducts?.products?.[1]
        if (p1?.product && p2?.product) {
          setProducts([p1, p2] || [])
        }
      }
      fetch()
    }
  }, [])

  return (
    <div {...sbEditable(blok)} className={'flex flex-col'}>
      <div className={'mt-6'}>
        {preview && <Preview selected={products} />}
        <div className="w-full flex space-x-3 px-3">
          {products?.map((sp, index) => {
            const images = getProductImages(sp)
            return (
              <div
                key={index}
                style={{ borderColor: blok?.border_color || 'black' }}
                className={classNames(
                  getBorderRadius(blok?.border_radius),
                  'border flex flex-col p-3 flex-1 ',
                )}
              >
                <img
                  className={classNames(getBorderRadius(blok?.border_radius))}
                  src={images?.[0]?.url}
                  alt=""
                />
                <p className={'font-bold pt-3 pb-6'}>{sp?.name}</p>
                {blok?.attribute_mapping?.map((attribute, y_index) => {
                  return (
                    <div
                      key={y_index}
                      className="flex-1 space-y-3 pb-3"
                      style={{ color: 'rgba(0, 0, 0, 0.5)' }}
                    >
                      <p className={'font-bold '}>{attribute.key}</p>
                      <p>{sp?.[attribute.key]}</p>
                    </div>
                  )
                })}
                <Button
                  variant={blok?.button_variant || 'primary'}
                  className={'mt-10'}
                >
                  Add to cart
                </Button>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const Preview = ({ selected }) => {
  const [show, setShow] = useState(true)
  let keys =
    selected.length > 0
      ? selected?.reduce((acc, cur) => {
          if (acc && cur) {
            return [...Object.keys(acc), ...Object.keys(cur)]
          }
        })
      : []
  keys = keys ? [...new Set(keys)] : []

  return (
    <div className={classNames('w-full p-6 border-2 border-amber-300 my-6')}>
      <p
        className={classNames(
          'p-3 bg-amber-300 text-black',
          show && keys?.length > 0 && 'mb-6',
        )}
      >
        This displays product keys. You can select which keys to show to the
        user by adding them to the Attribute Mapping. (Only visible in preview
        mode){' '}
        <span
          className={'italic cursor-pointer'}
          onClick={() => {
            setShow(!show)
          }}
        >
          {show ? '(Click here to hide)' : '(Click here to show)'}
        </span>
      </p>
      {show && keys?.length ? (
        keys?.map((key, y_index) => {
          return (
            <div
              key={key}
              className={classNames('flex', y_index % 2 == 0 && 'bg-gray-100')}
            >
              <p className="p-2 font-bold flex-1">{key}</p>
              {selected?.map((sp) => {
                if (sp?.name) {
                  return (
                    <p key={sp.product} className="p-2 flex-1">
                      {(typeof sp?.[key] === 'string' ||
                        typeof sp?.[key] === 'number') &&
                        sp?.[key]}
                    </p>
                  )
                }
              })}
            </div>
          )
        })
      ) : (
        <div></div>
      )}
    </div>
  )
}

const isAllSelected = (selected) => {
  for (let i = 0; i < selected.length; i++) {
    if (!selected[i]?.name) {
      return false
    }
  }
  return true
}
