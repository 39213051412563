const parseLink = (link) => {
  const url = link?.url
  if (url?.length > 0) {
    // Not relative
    if (url.includes('://') || url.includes('www')) {
      return url
    }

    // Relative
    return ['/', '#'].includes(url.charAt(0)) ? url : '/' + url
  }

  const cached_url = link?.cached_url
  if (cached_url) {
    return ['/', '#'].includes(cached_url.charAt(0))
      ? cached_url
      : '/' + cached_url
  }

  return '#'
}

export const getHref = (link) => {
  if (link) {
    let url = parseLink(link)

    // For internal links
    if (!url.includes('://') && !url.includes('www')) {
      url = url.replace('custom-category-pages/', 'category/')
      url = url.replace('custom-product-pages/', 'product/')
      url = url.replace('home', '')
    }
    return url
  }

  return ''
}
