import { observer } from 'mobx-react'
import { useContext, useEffect, useRef, useState } from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline'
import { CartStoreContext } from '../../../../store/cartStore'
import {
  getVariants,
  isVariantSameDisplay,
} from '../../../../helpers/getVariants'
import { getProductPrice } from '../../../../helpers/getProductPrice'
import { getBorderRadius } from '../../../../helpers/getBorderRadius'
import { YotpoProductStars } from './YotpoProductStars'
import {
  needRibbons,
  ProductCardRibbons,
} from '../../../sharable/productcards/ProductCardRibbons'
import { Variant } from '../../../sharable/productcards/Variant'
import { SizePicker } from '../../../sharable/sizePicker/SizePicker'
import { Button } from '../../../atoms/Button'
import classNames from 'classnames'
import { Upsell } from '../../../sharable/upsell/Upsell'
import { StampedStars } from './StampedStars'
import { GeneralError } from '../../../atoms/GeneralError'
import { sbEditable } from '@storyblok/storyblok-editable'
import { addClassesToText } from '../../../../helpers/addClassesToText'
import { useUpdateProduct } from '../../../../hooks/useUpdateProduct'
import {
  isFewLeft,
  isItemInStock,
  isPreOrder,
} from '../../../../helpers/isItemInStock'
import { getFontWeight } from '../../../../helpers/getFontWeight'
import { getFontSize } from '../../../../helpers/getFontSize'
import { getLineHeight } from '../../../../helpers/getLineHeight'
import { getTextAlign } from '../../../../helpers/getTextAlign'
import { getText } from '../../../../helpers/getText'
import { ProductsDataStoreContext } from '../../../../store/productsDataStore'
import { PriceLoader } from './PriceLoader'
import { useRouter } from 'next/router'
import { OutOfStockInput } from '../../../sharable/outOfStockInput/OutOfStockInput'
import { Modal } from '../../../sharable/modal/Modal'

export const ProductInfoContainer = ({ preview, data, blok }) => {
  if (data?.product_info?.[blok._uid]?.product) {
    return <ProductInfoFetcher blok={blok} data={data} preview={preview} />
  } else {
    return <ProductInfo blok={blok} data={data} preview={preview} />
  }
}
const ProductInfoFetcher = observer(({ preview, data, blok }) => {
  const { CSObject } = useContext(CartStoreContext)
  const { setStandAloneProduct } = useContext(ProductsDataStoreContext)
  const [product, updated] = useUpdateProduct(
    data?.product_info?.[blok._uid]?.product,
    CSObject,
  )
  const id = data?.product_info?.[blok._uid]?.product?.product
  useEffect(() => {
    if (product?.product) {
      if (!CSObject || !updated) {
        return
      }

      setStandAloneProduct(product)
    }
  }, [product?.product, updated])

  if (!product && preview) {
    return (
      <GeneralError blok={blok}>
        No product found. Check the URI and that the product is available in
        Centra.
      </GeneralError>
    )
  }

  if (!product) {
    return <></>
  }

  return (
    <>
      <ProductInfo
        data={{
          ...data,
          product: {
            relatedProducts:
              data?.product_info?.[blok._uid]?.product?.relatedProducts,
            product: id,
          },
        }}
        blok={blok}
        isStandAlone={true}
      />
    </>
  )
})

const ProductInfo = observer(({ blok, data, preview, isStandAlone }) => {
  const router = useRouter()
  const { addToCart, CSObject, addSubscription, setShowCart } =
    useContext(CartStoreContext)
  const myRef = useRef(null)
  const {
    setSize,
    productPageProduct,
    standAloneProducts,
    setStandAloneSize,
    getItem,
  } = useContext(ProductsDataStoreContext)
  let product = productPageProduct
  if (isStandAlone) {
    product = standAloneProducts[data?.product?.product]
  }
  const [showMore, setShowMore] = useState(false)
  const [openSubscribeModal, setOpenSubscribeModal] = useState(false)
  const [outOfStockSize, setSutOfStockSize] = useState({
    y: undefined,
    x: undefined,
  })

  const [addToCartLoading, setAddToCartLoading] = useState(false)
  const [addSubToCartLoading, setAddSubToCartLoading] = useState(false)
  const isVariantSame = isVariantSameDisplay({
    mainProduct: product,
    relatedProducts: data?.product?.relatedProducts ?? [],
  })

  const prices = getProductPrice({ CSObject, product })
  const upsell = +blok?.enable_upsell > 0 ? +blok?.enable_upsell : 0
  const rounded = getBorderRadius(blok?.image_border_radius)

  const isInStock = () => {
    const i = getItem(isStandAlone && product?.product)
    if (i) {
      return isItemInStock(i?.stock)
    }

    return false
  }

  const isItemPreOrder = () => {
    const i = getItem(isStandAlone && product?.product)
    if (i) {
      return isPreOrder(i?.stock)
    }

    return false
  }

  const isItemFewLeft = () => {
    const i = getItem(isStandAlone && product?.product)
    if (i) {
      return isFewLeft(i?.stock, blok?.few_left_threshold)
    }

    return false
  }

  // const isSomeSizeOutOfStock = () => {
  //   if (product?.items) {
  //     const item = product?.items.find((i) => i.stock === 'no')
  //     if (item) {
  //       return true
  //     }
  //   }

  //   return false
  // }
  const getOutOfStockItemId = () => {
    const item = product?.items?.find(
      (item) =>
        item.itemTableX === outOfStockSize.x &&
        item.itemTableY === outOfStockSize.y,
    )
    return item?.item
  }

  if (!product) {
    return <div></div>
  }

  return (
    <div {...sbEditable(blok)} ref={myRef} className={'relative'}>
      {!product && (
        <div
          style={{ height: '475px', zIndex: 1000 }}
          className={'absolute top-0 bottom-0 left-0 right-0 bg-white'}
        ></div>
      )}
      <div className={'relative flex flex-wrap lg:flex-nowrap'}>
        <div className={'w-full '}>
          <h1 className={'font-bold text-2xl font-title md:text-4xl'}>
            {product?.name}
          </h1>
          <div className="flex min-h-[30px] relative">
            <p
              style={{
                color: blok?.price_text_style?.[0]?.text_color
                  ? blok?.price_text_style?.[0]?.text_color
                  : 'black',
              }}
              className={classNames(
                'flex-1',
                getFontWeight(blok?.price_text_style?.[0]?.font_weight),
                getFontSize(blok?.price_text_style?.[0]?.font_size) ||
                  'text-lg md:text-lg', // this was the default before we added text_style
                getLineHeight(blok?.price_text_style?.[0]?.line_height),
                getTextAlign(blok?.price_text_style?.[0]?.text_align),
              )}
            >
              {prices?.priceBeforeDiscount !== prices?.price && (
                <span className="line-through mr-2 text-gray-400">
                  {prices?.priceBeforeDiscount}
                </span>
              )}
              {prices?.price}
            </p>

            {(isItemFewLeft() || preview) && blok?.few_left_info && (
              <div className={'relative'}>
                <p className={'lg:text-sm text-xs italic'}>
                  {getText(
                    blok?.few_left_info.replace(
                      '%stock%',
                      getItem(isStandAlone && product?.product)?.stock,
                    ),
                    data,
                  )}
                </p>
              </div>
            )}
            <PriceLoader loading={!prices} />
          </div>
          {data?.settingsData?.yotpo_app_key && (
            <div className={'my-3 min-h-[20px]'}>
              <YotpoProductStars data={data} blok={blok} />
            </div>
          )}
          {data?.settingsData?.stamped_public_api_key && (
            <div className={'my-3 min-h-[20px]'}>
              <StampedStars data={data} blok={blok} />
            </div>
          )}
          {needRibbons({ product, CSObject }) && (
            <div className={'relative block'}>
              <ProductCardRibbons
                className={'text-xs mt-3 lg:pb-0'}
                product={product}
                CSObject={CSObject}
                data={data}
              />
            </div>
          )}
          {/* Variants */}
          {getVariants({
            mainProduct: data?.product,
            relatedProducts: data?.product?.relatedProducts,
            blok,
          })?.length > 0 && (
            <>
              <p className={'text-sm mt-3 mb-2'}>
                {blok?.choose_variant_title
                  ? getText(blok?.choose_variant_title, data)
                  : ''}
              </p>
              <div className="flex w-full flex-wrap">
                {getVariants({
                  mainProduct: data?.product,
                  relatedProducts: data?.product?.relatedProducts,
                  blok,
                }).map((variant, index) => (
                  <Variant
                    variant={variant}
                    key={index}
                    active={variant?.product === product?.product}
                    design={blok?.variant_design}
                    isVariantSameDisplay={isVariantSame}
                    onClick={() => {
                      setProduct(variant)
                    }}
                    className={'w-10 h-10'}
                  />
                ))}
              </div>
            </>
          )}
          {(isItemPreOrder() || preview) && blok?.pre_order_info && (
            <div className={' mt-6 relative'}>
              <p>
                {blok?.pre_order_info
                  ? getText(blok?.pre_order_info, data)
                  : ''}
              </p>
            </div>
          )}
          {/* Short Description */}
          {blok?.fixed_description_height === 'yes' &&
          product?.excerptHtml !== '' ? (
            <>
              <div
                className={classNames(
                  'text-sm leading-6 mb-3 mt-6 overflow-hidden ',

                  showMore ? '' : 'max-h-[100px] ',
                )}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: addClassesToText(product?.excerptHtml),
                  }}
                />
              </div>
              <div
                className={
                  'flex items-center w-full text-gray-500 flex justify-center items-end text-2xs pb-3 cursor-pointer font-light'
                }
                onClick={() => {
                  setShowMore(!showMore)
                }}
              >
                {showMore
                  ? getText(blok?.show_more_title, data)
                  : getText(blok?.hide_title, data)}
                {showMore ? (
                  <ChevronUpIcon className="ml-2 h-4 w-4" />
                ) : (
                  <ChevronDownIcon className="ml-2  h-4 w-4" />
                )}
              </div>
            </>
          ) : (
            <div
              className="text-sm leading-6 mb-6 mt-6"
              dangerouslySetInnerHTML={{
                __html: addClassesToText(product?.excerptHtml),
              }}
            />
          )}
          {/* Size picker */}
          {product?.itemTable?.x?.length > 1 && (
            <div className={'flex flex-col'}>
              {blok?.pick_a_size_title && (
                <p className={'mb-2 text-sm font-bold'}>
                  {getText(blok?.pick_a_size_title, data)}
                </p>
              )}
              <SizePicker
                yTitle={blok?.pick_a_size_title_y}
                product={product}
                design={blok?.size_picker_design}
                className={classNames(
                  'block',
                  blok?.size_picker_design_desktop ? 'lg:hidden' : '',
                )}
                onSizeChange={(size) => {
                  if (isStandAlone) {
                    setStandAloneSize(product?.product, size)
                  } else {
                    setSize(size)
                  }
                }}
              />

              {blok?.size_picker_design_desktop && (
                <SizePicker
                  yTitle={blok?.pick_a_size_title_y}
                  product={product}
                  design={blok?.size_picker_design_desktop}
                  className={'hidden lg:block'}
                  onSizeChange={(size) => {
                    if (isStandAlone) {
                      setStandAloneSize(product?.product, size)
                    } else {
                      setSize(size)
                    }
                  }}
                />
              )}
            </div>
          )}
          <div
            id={'v95-add-to-cart-button'}
            name={'ventanas-add-to-cart-button'}
          >
            <Button
              loading={addToCartLoading}
              afterLoadingMessage={getText(
                data?.settingsData?.added_to_cart_message,
                data,
              )}
              disabled={!isInStock()}
              type="button"
              variant={blok?.button_style}
              className={classNames('w-full mt-3 ')}
              onClick={async () => {
                setAddToCartLoading(true)
                await addToCart(
                  getItem(isStandAlone && product?.product),
                  product,
                )
                setAddToCartLoading(false)
              }}
            >
              {isInStock()
                ? getText(data?.settingsData?.add_to_cart_button_title, data)
                : getText(data?.settingsData?.out_of_stock_title, data)}
            </Button>
          </div>
          {/*{!isInStock() && blok?.show_out_of_stock === 'yes' && (*/}
          {/*  <>*/}
          {/*    <Button*/}
          {/*      className={'w-full mt-3'}*/}
          {/*      onClick={() => {*/}
          {/*        setOpenSubscribeModal(true)*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      {getText(blok?.out_of_stock_size_button_title, data)}*/}
          {/*    </Button>*/}
          {/*    <Modal*/}
          {/*      isOpen={openSubscribeModal}*/}
          {/*      title={getText(blok?.out_of_stock_size_modal_title, data)}*/}
          {/*      onClose={() => {*/}
          {/*        setOpenSubscribeModal(false)*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <div className={'py-6 flex flex-col space-y-6'}>*/}
          {/*        <SizePicker*/}
          {/*          product={product}*/}
          {/*          isOutOfStockMode={true}*/}
          {/*          design={blok?.size_picker_design}*/}
          {/*          onSizeChange={(size) => {*/}
          {/*            setSutOfStockSize(size)*/}
          {/*          }}*/}
          {/*        />*/}
          {/*        <OutOfStockInput*/}
          {/*          info={''}*/}
          {/*          subTitle={getText(*/}
          {/*            blok?.out_of_stock_sub_button_title,*/}
          {/*            data,*/}
          {/*          )}*/}
          {/*          placeholder={getText(*/}
          {/*            blok?.out_of_stock_email_place_holder,*/}
          {/*            data,*/}
          {/*          )}*/}
          {/*          successTitle={getText(*/}
          {/*            blok.out_of_stock_success_title,*/}
          {/*            data,*/}
          {/*          )}*/}
          {/*          itemId={getOutOfStockItemId()}*/}
          {/*          buttonStyle={blok?.button_style}*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*    </Modal>*/}
          {/*  </>*/}
          {/*)}*/}

          {!isInStock() && blok?.show_out_of_stock === 'yes' && (
            <div className={'pt-6 pb-6'}>
              <OutOfStockInput
                info={getText(blok?.out_of_stock_info, data)}
                subTitle={getText(blok?.out_of_stock_sub_button_title, data)}
                placeholder={getText(
                  blok?.out_of_stock_email_place_holder,
                  data,
                )}
                successTitle={getText(blok.out_of_stock_success_title, data)}
                itemId={getItem(isStandAlone && product?.product)?.item}
                buttonStyle={blok?.button_style}
              />
            </div>
          )}

          {[...Array(upsell).keys()].map((_, index) => {
            return (
              <Upsell
                addedToCartTitle={getText(
                  data?.settingsData?.added_to_cart_message,
                  data,
                )}
                buttonTitle={getText(
                  data?.settingsData?.add_to_cart_quick_add_button_title,
                  data,
                )}
                pickSizeTitle={getText(
                  data?.settingsData?.pick_a_size_title,
                  data,
                )}
                outOfStockTitle={getText(
                  data?.settingsData?.out_of_stock_title,
                  data,
                )}
                sizePickerDesign={blok?.size_picker_design}
                product={
                  product?.relatedProducts?.filter(
                    (v) => v.relation === 'standard',
                  )?.[index]
                }
                imageClass={rounded}
                key={index}
                buttonVariant={data?.settingsData?.quick_add_button_variant}
              />
            )
          })}
        </div>
      </div>
      {product?.subscriptionPlans?.length > 0 && (
        <div className={'mt-3'}>
          {product?.subscriptionPlans?.map((sub) => {
            return (
              <div key={sub?.id} className={'flex flex-col space-y-2'}>
                {/*<p>name: {sub?.name}</p>*/}
                {/*<p>oldPrice: {sub?.price}</p>*/}
                {/*<p>price: {sub?.priceAfterDiscount}</p>*/}
                {/*<p>status: {sub?.status}</p>*/}
                {/*<p>shippingtype: {sub?.shippingType}</p>*/}
                {/*<p>intervalType: {sub?.intervalType}</p>*/}
                {/*<p>intervalValue: {sub?.intervalValue}</p>*/}
                <Button
                  loading={addSubToCartLoading}
                  onClick={async () => {
                    // if (CSObject?.loggedIn?.customer) {
                    setAddSubToCartLoading(true)
                    window.dontShowFlyout = true
                    const response = await addToCart(
                      getItem(isStandAlone && product?.product),
                      product,
                    )
                    const items = response?.selection?.items ?? []
                    const lineId = items?.[items.length - 1]?.line
                    await addSubscription({
                      lineId,
                      subscriptionId: sub?.id,
                    })
                    setAddSubToCartLoading(false)
                    setShowCart(true)
                    window.dontShowFlyout = false
                    // } else {
                    //   router.push('/my-page?puri=' + product?.uri)
                    // }
                  }}
                >
                  {CSObject?.loggedIn?.customer
                    ? 'Subscribe: ' + sub?.name
                    : 'Login to subscribe: ' + sub?.name}
                </Button>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
})
