import { getVariants } from './getVariants'
import { getProductPrice } from './getProductPrice'

export const getLowestPrice = ({ product, CSObject, fromTitle }) => {
  const vairants = getVariants({
    mainProduct: product,
    relatedProducts: product?.relatedProducts,
  })
  let prices = vairants?.map((p) => {
    return getProductPrice({ CSObject, product: p })
  })

  prices.push(getProductPrice({ CSObject, product: product }))

  const uniques = []
  prices = prices.filter((element) => {
    const isDuplicate = uniques.includes(element?.price)

    if (!isDuplicate && element?.price) {
      uniques.push(element?.price)

      return true
    }

    return false
  })
  prices = prices.sort((a, b) => {
    return a.priceAsNumber - b.priceAsNumber
  })

  if (prices.length > 1) {
    return fromTitle + prices[0].price
  } else {
    return prices[0].price
  }
}
