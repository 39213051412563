import { getProductImages } from '../../../helpers/getProductImage'
import { Variant } from './Variant'
import Link from 'next/link'
import classNames from 'classnames'
import Image from 'next/future/image'
import { getProductPrice } from '../../../helpers/getProductPrice'
import { ProductCardRibbons } from './ProductCardRibbons'
import { getVariants } from '../../../helpers/getVariants'
import { getProductUri } from '../../../helpers/getProductUri'
import { ModalUpSell } from '../upsell/ModalUpSell'
import { getImageAlt } from '../../../helpers/getImageAlt'
import { getLowestPrice } from '../../../helpers/getLowestPrice'
import { getFontWeight } from '../../../helpers/getFontWeight'
import { getFontSize } from '../../../helpers/getFontSize'
import { getLineHeight } from '../../../helpers/getLineHeight'
import { getTextAlign } from '../../../helpers/getTextAlign'
import { getWidthAndHeight } from './ProductCard'

export const ProductCardFullImage = ({
  product,
  CSObject,
  blok,
  data,
  onClickCard,
}) => {
  let images = getProductImages(product, blok)
  let prices = getProductPrice({ CSObject, product })
  const size = getWidthAndHeight(blok)

  return (
    <div
      className={'relative aspect-h-7 aspect-w-6 group'}
      onClick={() => {
        if (onClickCard) {
          onClickCard()
        }
      }}
    >
      <Link
        href={getProductUri(product.uri)}
        onClickCard={() => {
          if (onClickCard) {
            onClickCard()
          }
        }}
      >
        <a>
          <div className={classNames('w-full h-full relative ')}>
            <ProductCardRibbons
              data={data}
              product={product}
              CSObject={CSObject}
              className={'absolute z-10 top-2 right-2 text-2xs -mr-1'}
            />

            {images && (
              <Image
                src={images?.[0]?.url}
                alt={getImageAlt({
                  alt: product.name,
                  image: images?.[0]?.url,
                })}
                className={'object-cover w-full h-full'}
                width={size.width}
                height={size.height}
              />
            )}
            {blok?.hover_effect === 'image_2' && images.length > 1 && (
              <div
                className={classNames(
                  'absolute inset-0',
                  'opacity-0 transition-opacity',
                  'group-hover:opacity-100 group-hover:transition-opacity',
                )}
              >
                <Image
                  src={images?.[1].url}
                  alt={getImageAlt({
                    alt: product.name,
                    image: images?.[1].url,
                  })}
                  className={'object-cover w-full h-full'}
                  width={size.width}
                  height={size.height}
                />
              </div>
            )}
          </div>
          <div
            className={
              'absolute bottom-0 bg-transparent w-full p-3 flex items-end'
            }
          >
            <div className={'flex-1 self-end'}>
              <p
                style={{
                  color: blok?.title_text_style?.[0]?.text_color
                    ? blok?.title_text_style?.[0]?.text_color
                    : 'black',
                }}
                className={classNames(
                  getFontWeight(blok?.title_text_style?.[0]?.font_weight),
                  getFontSize(blok?.title_text_style?.[0]?.font_size) ??
                    'text-xs md:text-base', // this was the default before we added text_style
                  getLineHeight(blok?.title_text_style?.[0]?.line_height),
                  getTextAlign(blok?.title_text_style?.[0]?.text_align),
                )}
              >
                {product.name}
              </p>

              <div
                style={{
                  color: blok?.price_text_style?.[0]?.text_color
                    ? blok?.price_text_style?.[0]?.text_color
                    : 'rgb(75 85 99)', // this was the default before we added text_style
                }}
                className={classNames(
                  'md:-mt-1',
                  getFontWeight(blok?.price_text_style?.[0]?.font_weight),
                  getFontSize(blok?.price_text_style?.[0]?.font_size) ??
                    'text-xs md:text-sm', // this was the default before we added text_style
                  getLineHeight(blok?.price_text_style?.[0]?.line_height),
                  getTextAlign(blok?.price_text_style?.[0]?.text_align),
                )}
              >
                {blok?.show_from === 'yes' ? (
                  <div>
                    {getLowestPrice({
                      product,
                      CSObject,
                      fromTitle: data?.settingsData.product_card_from_title
                        ? data?.settingsData.product_card_from_title
                        : 'From ',
                    })}
                  </div>
                ) : (
                  <>
                    {prices?.price !== prices?.priceBeforeDiscount && (
                      <span className="line-through mr-2">
                        {prices?.priceBeforeDiscount}
                      </span>
                    )}
                    {prices?.price}
                  </>
                )}
              </div>
            </div>
            {blok?.enable_quick_add && (
              <div>
                <ModalUpSell
                  addedToCartTitle={data?.settingsData?.added_to_cart_message}
                  buttonTitle={
                    data?.settingsData?.add_to_cart_quick_add_button_title
                  }
                  sizeModalTitle={data?.settingsData?.pick_a_size_title}
                  sizePickerDesign={blok?.size_picker_design}
                  product={product}
                  buttonVariant={data?.settingsData?.quick_add_button_variant}
                  mobileIconAbsolute={false}
                />
              </div>
            )}
          </div>
        </a>
      </Link>
      {product?.relatedProducts?.length > 0 && blok.variant_design != 'hide' && (
        <div className={'absolute top-0 p-3 h-fit '}>
          {getVariants({
            mainProduct: product,
            relatedProducts: product?.relatedProducts,
          }).map((variant, index) => (
            <Variant
              variant={variant}
              design={blok.variant_design}
              key={index}
            />
          ))}
        </div>
      )}
    </div>
  )
}
