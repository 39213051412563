import { getProductPrice } from '../../../helpers/getProductPrice'
import classNames from 'classnames'

export const needRibbons = ({ product, CSObject }) => {
  let price = getProductPrice({ product, CSObject })

  if (!isAvailable({ product }) || price?.showAsNew || price?.showAsOnSale) {
    return true
  }

  return false
}

const isAvailable = ({ product }) => {
  if (product?.available === true) {
    return true
  }

  if (
    product?.relatedProducts?.filter((p) => {
      return p.relation === 'variant' && p.available === true
    }).length > 0
  ) {
    return true
  }

  return false
}

export const ProductCardRibbons = ({ product, CSObject, className, data }) => {
  let price = getProductPrice({ product, CSObject })
  let commonClasses = 'text-white py-1 px-2 mr-1 inline-block'

  // For debugging
  // product.available = false
  // price?.showAsNew = true
  // price?.showAsOnSale = true

  return (
    <div className={className ?? 'absolute z-10 bottom-1 left-0 text-xs'}>
      {!isAvailable({ product }) && (
        <div className={classNames(commonClasses, 'bg-black')}>
          {data?.settingsData?.ribbon_out_of_stock}
        </div>
      )}
      {price?.showAsNew && (
        <div className={classNames(commonClasses, 'bg-primary')}>
          {data?.settingsData?.ribbon_new}
        </div>
      )}
      {price?.showAsOnSale && (
        <div className={classNames(commonClasses, 'bg-primary')}>
          {data?.settingsData?.ribbon_sale}
        </div>
      )}
    </div>
  )
}
