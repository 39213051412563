import classNames from 'classnames'
import { forwardRef } from 'react'
import { countries } from '../../helpers/countries'

// eslint-disable-next-line react/display-name
export const Input = forwardRef((props, ref) => {
  return (
    <input
      ref={ref}
      {...props}
      className={classNames(
        'appearance-none min-w-0 w-full text-base',
        'rounded-[var(--input-corner-radius)]',
        'border border-inputBorderColor',
        'bg-inputBackgroundColor',
        'text-inputTextColor',
        'py-[var(--input-padding-t-b)] px-[var(--input-padding-l-r)]',
        props?.overrideclassname ? props?.overrideclassname : '',
      )}
    />
  )
})

// eslint-disable-next-line react/display-name
export const Select2 = forwardRef((props, ref) => {
  return (
    <>
      <select
        ref={ref}
        {...props}
        className={classNames(
          'appearance-none min-w-0 w-full text-base',
          'rounded-[var(--input-corner-radius)]',
          'border border-inputBorderColor',
          'bg-inputBackgroundColor',
          'text-inputTextColor',
          'py-[var(--input-padding-t-b)] px-[var(--input-padding-l-r)]',
          props?.overrideclassname ? props?.overrideclassname : '',
        )}
      >
        {props?.empty === true && <option></option>}
        {props.options?.map((o) => (
          <option key={o.value} value={o.value}>
            {o.name}
          </option>
        ))}
      </select>
    </>
  )
})

// eslint-disable-next-line react/display-name
export const Select = forwardRef((props, ref) => {
  return (
    <select
      ref={ref}
      {...props}
      className={classNames(
        'appearance-none min-w-0 w-full text-base',
        'rounded-[var(--input-corner-radius)]',
        'border border-inputBorderColor',
        'bg-inputBackgroundColor',
        'text-inputTextColor',
        'py-[var(--input-padding-t-b)] px-[var(--input-padding-l-r)]',
        props?.overrideclassname ? props?.overrideclassname : '',
      )}
    >
      <option value="">{props.initialtitle}</option>
      {props.options.map((o) => (
        <option key={o.code} value={o.code}>
          {o.name}
        </option>
      ))}
    </select>
  )
})
