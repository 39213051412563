import { ProductsRowSharable } from '../sharable/productsRow/ProductsRowSharable'
import { observer } from 'mobx-react'
import { useContext, useEffect, useState } from 'react'
import { CartStoreContext } from '../../store/cartStore'
import { GeneralError } from '../atoms/GeneralError'
import { fixUri } from '../../helpers/needToFetchCentraData'
import { isServer } from '../../helpers/isServer'

export const ProductsRow = observer(({ blok, data, preview }) => {
  const { CSObject, fetchProductsFromUri } = useContext(CartStoreContext)
  // We want the server to get the server generated products. But on client we want to update.
  const [loading, setLoading] = useState(isServer ? false : true)

  const [products, setProducts] = useState(
    data?.products_row?.[blok._uid]?.products,
  )

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      const productsData = await fetchProductsFromUri({
        uri: fixUri(blok?.category_uri),
      })
      setProducts(productsData?.products)
      setLoading(false)
    }
    if (CSObject) {
      fetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    CSObject?.location?.country,
    CSObject?.location?.language?.language,
    blok?.category_uri,
  ])

  if (products?.length > 0) {
    return (
      <>
        <ProductsRowSharable
          blok={blok}
          products={products}
          CSObject={CSObject}
          data={data}
          preview={preview}
          loading={loading}
        />
      </>
    )
  } else if (preview) {
    return (
      <GeneralError blok={blok}>
        No products was found. Please make sure you entered Products URIs or
        Category URI. This message is only visible in preview.
      </GeneralError>
    )
  } else {
    return null
  }
})
