import { useEffect, useState } from 'react'
import { ClientOnly } from '../../../atoms/ClientOnly'
import { Loading } from '../../../atoms/Loading'

export const PriceLoader = ({ loading }) => {
  const [showSpinner, setShowSpinner] = useState(false)
  useEffect(() => {
    let timer
    if (loading) {
      timer = setTimeout(() => setShowSpinner(loading), 750)
    } else {
      setShowSpinner(false)
    }
    return () => timer && clearTimeout(timer)
  }, [loading])

  if (showSpinner) {
    return (
      <ClientOnly>
        <div className={'absolute top-0 left-0 right-0 bottom-0 bg-blue'}>
          <Loading />
        </div>
      </ClientOnly>
    )
  }

  return null
}
