import { Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Fragment } from 'react'

export const TransitionWrapper = ({ data, blok, children, show }) => {
  return (
    <>
      <Transition
        as={Fragment}
        show={show}
        appear={true}
        enter="transform transition ease-in-out duration-[300ms]"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transform transition ease-in-out duration-[300ms]"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div
          style={{
            backgroundColor: blok?.background_color
              ? blok?.background_color
              : 'white',
          }}
          className={classNames(
            'absolute z-20 w-full top-0',
            'right-0 bottom-0 left-0',
          )}
        >
          {children}
        </div>
      </Transition>
    </>
  )
}
