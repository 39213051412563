import { useEffect, useState } from 'react'
import { getPath } from '../../../helpers/getPath'
import { getCurrentProductFromUri } from '../../../lib/centra/centra'
import { Upsell } from '../../sharable/upsell/Upsell'
import { getBorderRadius } from '../../../helpers/getBorderRadius'
import { useRouter } from 'next/router'
import { sbEditable } from '@storyblok/storyblok-editable'
import { getText } from '../../../helpers/getText'

export const MoreSell = ({ blok, data }) => {
  const [product, setProduct] = useState()
  const rounded = getBorderRadius(blok?.image_border_radius)
  const router = useRouter()

  useEffect(() => {
    const fetch = async () => {
      const path = getPath(blok?.product_uri)
      const prdct = await getCurrentProductFromUri({
        uri: path,
        locale: data?.locale,
      })
      if (prdct) {
        setProduct(prdct)
      }
    }
    fetch().catch(() => {})
  }, [blok?.product_uri, data?.locale])

  return (
    <div {...sbEditable(blok)}>
      {product && (
        <Upsell
          addedToCartTitle={getText(
            data?.settingsData?.added_to_cart_message,
            data,
          )}
          buttonTitle={getText(
            data?.settingsData?.add_to_cart_quick_add_button_title,
            data,
          )}
          pickSizeTitle={getText(data?.settingsData?.pick_a_size_title, data)}
          outOfStockTitle={getText(
            data?.settingsData?.out_of_stock_title,
            data,
          )}
          sizePickerDesign={blok?.size_picker_design}
          product={product}
          imageClass={rounded}
          onAdd={() => {
            if (blok?.go_direct_to_checkout === 'yes') {
              router.push('/checkout')
            }
          }}
          buttonVariant={data?.settingsData?.quick_add_button_variant}
        />
      )}
    </div>
  )
}
