import { sbEditable } from '@storyblok/storyblok-editable'
import classNames from 'classnames'
import { getFontWeight } from '../../../../helpers/getFontWeight'
import { getFontSize } from '../../../../helpers/getFontSize'
import { getLineHeight } from '../../../../helpers/getLineHeight'
import { getTextAlign } from '../../../../helpers/getTextAlign'
import { getText } from '../../../../helpers/getText'
import { isPreOrder } from '../../../../helpers/isItemInStock'
import { observer } from 'mobx-react'
import { ProductsDataStoreContext } from '../../../../store/productsDataStore'
import { useContext } from 'react'

export const ProductPagePreOrder = observer(({ data, blok, preview }) => {
  const { getItem } = useContext(ProductsDataStoreContext)
  const isItemPreOrder = () => {
    const i = getItem()
    if (i) {
      return isPreOrder(i?.stock)
    }

    return false
  }

  return (
    <>
      {(isItemPreOrder() || preview) && blok?.pre_order_info && (
        <div className={' mt-6 relative'} {...sbEditable(blok)}>
          <p
            style={{
              color: blok?.text_style?.[0]?.text_color || 'black',
            }}
            className={classNames(
              getFontWeight(blok?.text_style?.[0]?.font_weight),
              getFontSize(blok?.text_style?.[0]?.font_size),
              getLineHeight(blok?.text_style?.[0]?.line_height),
              getTextAlign(blok?.text_style?.[0]?.text_align),
            )}
          >
            {blok?.pre_order_info ? getText(blok?.pre_order_info, data) : ''}
          </p>
        </div>
      )}
    </>
  )
})
