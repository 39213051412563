import Script from 'next/script'
import { parseCookies } from 'nookies'
import { useEffect, useState } from 'react'

export const YotpoScript = ({ data }) => {
  const [reload, setReload] = useState(false)
  const cookies = parseCookies()

  const handelCookieClose = () => {
    setReload(!reload)
  }
  useEffect(() => {
    window.addEventListener('cookies-modal-close-event', handelCookieClose)

    // cleanup this component
    return () => {
      window.removeEventListener('cookies-modal-close-event', handelCookieClose)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (
    cookies?.cookieConsentCategories?.includes('necessary') &&
    data?.settingsData?.yotpo_app_key
  ) {
    return (
      <Script
        id={'yotpo-widget-script'}
        src={`https://staticw2.yotpo.com/${data?.settingsData?.yotpo_app_key}/widget.js`}
        strategy="lazyOnload"
      />
    )
  }

  return null
}
