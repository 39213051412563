export const getVariants = ({ mainProduct, relatedProducts, blok }) => {
  let sorted = relatedProducts?.filter((v) => v.relation === 'variant')
  if (
    sorted?.length === 0 ||
    (blok?.hide_variants_if_only_one &&
      blok?.hide_variants_if_only_one !== 'no')
  ) {
    return []
  }

  if (mainProduct) {
    mainProduct.isVariantActive = true
    sorted.push(mainProduct)
    sorted.sort(function (a, b) {
      if (a?.variantName && b?.variantName) {
        if (a.variantName < b.variantName) {
          return -1
        }
        if (b.variantName > a.variantName) {
          return 1
        }
      }
      return 0
    })

    sorted = sorted.map((s) => {
      return { ...s, sameDisplay: true }
    })
  }

  // Check of uri is the same
  // if uri is the same we know the variants have the same display on Centra
  return sorted
}

export const isVariantSameDisplay = ({ mainProduct, relatedProducts = [] }) => {
  // Check of uri is the same
  // if uri is the same we know the variants have the same display on Centra
  let sorted = relatedProducts?.filter((v) => v.relation === 'variant')
  const test = sorted?.filter((p) => p.uri !== mainProduct?.uri)
  return test?.length === 0
}
