import { observer } from 'mobx-react'
import { useContext, useEffect, useState } from 'react'
import { CartStoreContext } from '../../../store/cartStore'
import { ShoppingBagIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { sbEditable } from '@storyblok/storyblok-editable'

export const CartButton = observer(({ blok, data }) => {
  const { CSObject, animation, showCart, setShowCart } =
    useContext(CartStoreContext)

  return (
    <div
      {...sbEditable(blok)}
      className="group flex items-center cursor-pointer w-8"
      style={{ color: blok?.text_color ? blok?.text_color : 'black' }}
      onClick={() => {
        setShowCart(!showCart)
      }}
    >
      <ShoppingBagIcon
        style={{ color: blok?.icon_color ? blok?.icon_color : 'black' }}
        className="flex-shrink-0 h-6 w-6"
        aria-hidden="true"
      />
      <span
        className={classNames(
          animation && 'animate-bounce-stop',
          'justify-center rounded-full flex items-center',
          '-ml-3 -mt-3 -mr-1 text-2xs h-4 w-4',
          'md:text-sm md:w-5 md:h-5 pt-[1px] md:pt-[3px]',
        )}
        style={{
          backgroundColor: blok?.quantity_background_color
            ? blok?.quantity_background_color
            : 'lightgray',
          color: blok?.quantity_text_color ? blok.quantity_text_color : 'black',
        }}
      >
        {CSObject?.selection?.totals?.totalQuantity ?? 0}
      </span>
      <span className="sr-only">items in cart, view bag</span>
    </div>
  )
})
