import classNames from 'classnames'

export const ProductSpecifications = ({ blok, data, preview }) => {
  const product = data?.product
  const items = []

  // For easier debugging and finding the correct attribtute names
  if (preview) {
    let p = {}
    for (const key of Object.keys(product)) {
      if (typeof product[key] !== 'object') {
        p[key] = product[key]
      }
    }
    console.log('Available specifications. Only visible in preview mode.', p)
  }

  for (const item of blok?.items) {
    if (item.attribute_name in product) {
      items.push({ title: item.title, data: product[item.attribute_name] })
    }
  }

  return (
    <table className="w-full table-auto text-sm">
      <tbody>
        {items.map((item, index) => {
          return (
            <tr
              key={index}
              className={classNames(index % 2 == 0 && 'bg-gray-100')}
            >
              <td className="font-bold p-2">{item.title}</td>
              <td className="p-2 flex justify-end">{item.data}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
