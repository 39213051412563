import { useContext, useState } from 'react'
import { FilterStoreContext } from '../../../../store/filterStore'
import { getText } from '../../../../helpers/getText'
import classNames from 'classnames'
import { getBolder, getFontWeight } from '../../../../helpers/getFontWeight'
import { getFontSize } from '../../../../helpers/getFontSize'
import { getLineHeight } from '../../../../helpers/getLineHeight'
import { getTextAlign } from '../../../../helpers/getTextAlign'
import { SwitchVerticalIcon } from '@heroicons/react/outline'
import { getHeight, getWidth } from '../../../../helpers/getSize'
import { sbEditable } from '@storyblok/storyblok-editable'
import { getSpaceY } from '../../../../helpers/getSpaceY'
import { getPadding } from '../../../../helpers/getPadding'

export const Sort = ({ blok, data }) => {
  const options = [
    {
      value: 'standard',
      name: getText(
        data?.settingsData?.sort_standard_sort_title || 'Standard',
        data,
      ),
    },
    {
      value: 'asc',
      name: getText(
        data?.settingsData?.sort_price_low_to_high_title || 'Low to high price',
        data,
      ),
    },
    {
      value: 'desc',
      name: getText(
        data?.settingsData?.sort_price_high_to_low_title ||
          ' High to low price',
        data,
      ),
    },
  ]

  const [showModal, setShowModal] = useState(false)
  const [option, setOption] = useState(options[0])
  const { setSortOrder } = useContext(FilterStoreContext)

  return (
    <div {...sbEditable(blok)} className={'w-full'}>
      <div
        className={classNames(
          'flex items-center cursor-pointer',
          blok?.title_position === 'right' && 'justify-end',
          blok?.title_position === 'center' && 'justify-center',
          blok?.title_position === 'left' && 'justify-start',
        )}
        onClick={() => {
          setShowModal(!showModal)
        }}
      >
        <p
          style={{
            color: blok?.title_text_style?.[0]?.text_color
              ? blok?.title_text_style?.[0]?.text_color
              : 'black',
          }}
          className={classNames(
            getFontWeight(blok?.title_text_style?.[0]?.font_weight) ||
              'font-medium', // this was the default before we added text_style
            getFontSize(blok?.title_text_style?.[0]?.font_size) || 'text-base', // this was the default before we added text_style
            getLineHeight(blok?.title_text_style?.[0]?.line_height),
            getTextAlign(blok?.title_text_style?.[0]?.text_align),
            'pr-2',
          )}
        >
          {blok?.title || 'Sort'}
        </p>

        {!showModal && (
          <SwitchVerticalIcon
            style={{
              color: blok?.title_text_style?.[0]?.text_color
                ? blok?.title_text_style?.[0]?.text_color
                : 'black',
            }}
            className={classNames(
              blok?.icon_size ? getHeight(blok?.icon_size) : 'h-6',
              blok?.icon_size ? getWidth(blok?.icon_size) : 'w-6',
            )}
            aria-hidden="true"
          />
        )}

        {showModal && (
          <SwitchVerticalIcon
            style={{
              color: blok?.title_text_style?.[0]?.text_color
                ? blok?.title_text_style?.[0]?.text_color
                : 'black',
            }}
            className={classNames(
              blok?.icon_size ? getHeight(blok?.icon_size) : 'h-6',
              blok?.icon_size ? getWidth(blok?.icon_size) : 'w-6',
            )}
            aria-hidden="true"
          />
        )}
      </div>

      <div
        style={{
          width: blok?.modal_width || '100%',
          backgroundColor: blok?.modal_background_color || 'white',
          color: blok?.value_title_text_style?.[0]?.text_color
            ? blok?.value_title_text_style?.[0]?.text_color
            : 'black',
          borderColor: blok?.border_color || 'white',
          transition: 'all',
          transitionTimingFunction: 'ease',
          transitionDuration: blok?.animation_duration
            ? blok?.animation_duration + 'ms'
            : '350ms',
          ...(showModal
            ? {
                opacity: 100,
                pointerEvents: 'auto',
                maxHeight: '200px',
              }
            : { opacity: 0, pointerEvents: 'none', maxHeight: '0px' }),
        }}
        className={classNames(
          ' absolute z-[99] flex flex-col border',
          blok?.space_between ? getSpaceY(blok?.space_between) : 'space-y-3',
          blok?.padding_y
            ? getPadding({
                desktop: blok?.padding_y,
                mobile: blok?.padding_y,
                prefix: 'py',
              })
            : 'py-3',
          blok?.padding_x
            ? getPadding({
                desktop: blok?.padding_x,
                mobile: blok?.padding_x,
                prefix: 'px',
              })
            : 'px-3',
          getFontSize(blok?.value_title_text_style?.[0]?.font_size),
          getLineHeight(blok?.value_title_text_style?.[0]?.line_height),
          getTextAlign(blok?.value_title_text_style?.[0]?.text_align),
        )}
      >
        {options.map((op) => {
          return (
            <div
              key={op.value}
              onClick={() => {
                if (op.value === 'standard') {
                  setSortOrder(null)
                } else {
                  setSortOrder({ field: 'priceAsNumber', order: op.value })
                }
                setOption(op)
                setShowModal(false)
              }}
              className={classNames(
                'w-full cursor-pointer',
                option?.value === op.value
                  ? getBolder(blok?.value_title_text_style?.[0]?.font_weight) ||
                      'font-bold'
                  : getFontWeight(
                      blok?.value_title_text_style?.[0]?.font_weight,
                    ) || 'font-medium',
              )}
            >
              {op.name}
            </div>
          )
        })}
      </div>
    </div>
  )
}
