const maxWidths = {
  sm: 'max-w-screen-sm mx-auto w-full',
  md: 'max-w-screen-md mx-auto w-full',
  lg: 'max-w-screen-lg mx-auto w-full',
  xl: 'max-w-screen-xl mx-auto w-full',
  '2xl': 'max-w-screen-2xl mx-auto w-full',
  full: 'w-full',
}

export const getMaxWidth = ({ data, setMaxWidth }) => {
  let maxWidth = maxWidths[setMaxWidth]
  if (maxWidth) {
    return maxWidths[setMaxWidth]
  }

  maxWidth = maxWidths[data?.layoutData?.default_wrapper_max_width]
  if (maxWidth) {
    return maxWidth
  } else {
    console.error('Error setting max width')
    return false
  }
}
