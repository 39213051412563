import classNames from 'classnames'

export const ProductSizeChart = ({ blok, data }) => {
  const chart = data?.product?.measurementChart

  return (
    chart?.contents?.length > 0 && (
      <table className="table-auto w-full text-xs text-left">
        <thead className="text-center">
          <tr>
            <th></th>
            {chart.x.map((x, index) => {
              return (
                <th key={index} className="font-bold p-1">
                  {x}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {chart.y.map((y, y_index) => {
            return (
              <tr
                key={y_index}
                className={classNames('', y_index % 2 == 0 && 'bg-gray-100')}
              >
                <td className="p-2 font-bold">{y}</td>
                {chart.x.map((x, x_index) => {
                  return (
                    <td key={x_index} className="p-2 text-center">
                      {
                        chart.contents.find(
                          (item) => item.x === x_index && item.y === y_index,
                        )?.content
                      }
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  )
}
