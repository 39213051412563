import { useState } from 'react'
import { Input } from '../atoms/Input'
import { Button } from '../atoms/Button'
import classNames from 'classnames'
import { getFontWeight } from '../../helpers/getFontWeight'
import { getFontSize } from '../../helpers/getFontSize'
import { getTextAlign } from '../../helpers/getTextAlign'
import { getLineHeight } from '../../helpers/getLineHeight'
import { sbEditable } from '@storyblok/storyblok-editable'
import { getText } from '../../helpers/getText'

const getSizes = ({ measurementChart, cm }) => {
  let sizeObject = {}

  const cmItem = measurementChart?.contents?.find((c) => c.content === cm)
  if (cmItem) {
    const sizes = measurementChart?.contents?.filter(
      (c) => c.y === cmItem.y && c.content !== cmItem.content,
    )

    sizeObject[measurementChart?.y[cmItem.y]] = ''
    sizes?.forEach((s) => {
      const sizeTitle = measurementChart?.x?.[s.x]
      sizeObject[sizeTitle] = ' ' + s.content
    })
  }

  return sizeObject
}
export const SizeCalculator = ({ blok, data }) => {
  const [size, setSize] = useState()
  const [shoeSize, setShoeSize] = useState('')
  const [cantFindText, setCantFindText] = useState('')

  const calculateSize = () => {
    const sizes = getSizes({
      measurementChart: data?.product?.measurementChart,
      cm: (parseFloat(size) + parseFloat(blok?.add_number)).toString(),
    })
    if (Object.keys(sizes).length > 0) {
      let s = ''
      for (const [key, value] of Object.entries(sizes)) {
        s = s + `${key}${value} `
      }
      setShoeSize(s)
      setCantFindText('')
    } else {
      setShoeSize('')
      setCantFindText(
        blok?.cant_find_size_text
          ? getText(blok?.cant_find_size_text, data)
          : 'Can not find your size',
      )
    }
  }

  const shoeSizeText = blok?.answer_text
    ? getText(
        blok?.answer_text.replace('%size%', shoeSize ? shoeSize : ''),
        data,
      )
    : shoeSize

  return (
    <div {...sbEditable(blok)} className={'w-full flex-col space-y-6'}>
      <div className={'w-full flex space-x-3 justify-center items-center'}>
        <Input
          id={`size-calculator`}
          type="number"
          onChange={(event) => {
            setSize(event.target.value)
          }}
          // need to be +16px
          // https://css-tricks.com/16px-or-larger-text-prevents-ios-form-zoom/
          style={{ fontSize: '16px' }}
          value={size}
          placeholder={
            blok?.placeholder_text ? getText(blok?.placeholder_text, data) : ''
          }
          overrideclassname="w-full max-w-[300px]"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              calculateSize()
            }
          }}
        />
        <Button
          onClick={() => {
            calculateSize()
          }}
        >
          {blok?.button_title ? getText(blok?.button_title, data) : 'Calculate'}
        </Button>
      </div>
      {(shoeSize || cantFindText) && (
        <p
          style={{
            color: blok?.answer_text_style?.[0]?.text_color
              ? blok?.answer_text_style?.[0]?.text_color
              : 'black',
          }}
          className={classNames(
            getFontWeight(blok?.answer_text_style?.[0]?.font_weight),
            getFontSize(blok?.answer_text_style?.[0]?.font_size),
            getLineHeight(blok?.answer_text_style?.[0]?.line_height),
            getTextAlign(blok?.answer_text_style?.[0]?.text_align),
          )}
        >
          {cantFindText ? cantFindText : shoeSizeText}
        </p>
      )}
    </div>
  )
}
