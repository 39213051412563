import { observer } from 'mobx-react'
import { useContext } from 'react'
import { ProductsDataStoreContext } from '../../../../store/productsDataStore'
import {
  getVariants,
  isVariantSameDisplay,
} from '../../../../helpers/getVariants'
import { getText } from '../../../../helpers/getText'
import { Variant } from '../../../sharable/productcards/Variant'
import { sbEditable } from '@storyblok/storyblok-editable'

export const ProductPageVariants = observer(({ data, blok }) => {
  const { productPageProduct, setProductPageProduct } = useContext(
    ProductsDataStoreContext,
  )
  const product = productPageProduct

  const isVariantSame = isVariantSameDisplay({
    mainProduct: product,
    relatedProducts: data?.product?.relatedProducts ?? [],
  })
  return (
    <div {...sbEditable(blok)}>
      {getVariants({
        mainProduct: data?.product,
        relatedProducts: data?.product?.relatedProducts,
        blok,
      }).length > 0 && (
        <>
          <p className={'text-sm mt-3 mb-2'}>
            {blok?.choose_variant_title
              ? getText(blok?.choose_variant_title, data)
              : ''}
          </p>
          <div className="flex w-full flex-wrap">
            {getVariants({
              mainProduct: data?.product,
              relatedProducts: data?.product?.relatedProducts,
              blok,
            }).map((variant, index) => (
              <Variant
                variant={variant}
                key={index}
                active={variant?.product === product?.product}
                design={blok?.variant_design}
                isVariantSameDisplay={isVariantSame}
                onClick={() => {
                  setProductPageProduct(variant)
                }}
                className={'w-10 h-10'}
              />
            ))}
          </div>
        </>
      )}
    </div>
  )
})
