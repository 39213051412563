import { observer } from 'mobx-react'
import { useContext } from 'react'
import { ProductsDataStoreContext } from '../../../../store/productsDataStore'
import classNames from 'classnames'
import { getFontWeight } from '../../../../helpers/getFontWeight'
import { getBigger, getFontSize } from '../../../../helpers/getFontSize'
import { getLineHeight } from '../../../../helpers/getLineHeight'
import { getTextAlign } from '../../../../helpers/getTextAlign'
import { sbEditable } from '@storyblok/storyblok-editable'

export const ProductPageTitle = observer(({ data, blok }) => {
  const { productPageProduct } = useContext(ProductsDataStoreContext)
  const product = productPageProduct

  return (
    <>
      <div className={'w-full'} {...sbEditable(blok)}>
        <h1
          style={{
            color: blok?.text_style?.[0]?.text_color || 'black',
          }}
          className={classNames(
            'font-title',
            getFontWeight(blok?.text_style?.[0]?.font_weight) || 'font-bold',
            getFontSize(blok?.text_style?.[0]?.font_size) || 'text-2xl',
            blok?.text_style?.[0]?.font_size
              ? 'md:' + getBigger(blok?.text_style?.[0]?.font_size)
              : 'md:text-4xl',
            getLineHeight(blok?.text_style?.[0]?.line_height),
            getTextAlign(blok?.text_style?.[0]?.text_align),
          )}
        >
          {product?.name}
        </h1>
      </div>
    </>
  )
})
