import classNames from 'classnames'
import { sbEditable } from '@storyblok/storyblok-editable'
import Marquee from 'react-fast-marquee'
import { getText } from '../../helpers/getText'
import { data } from 'autoprefixer' //TODO: Load dynamically

export function Banner({ blok, data }) {
  return !blok.hidden ? (
    <div
      {...sbEditable(blok)}
      style={{
        backgroundColor: blok?.bg_color ?? 'black',
        color: blok?.text_color ?? 'white',
        height: blok.height + 'px',
        fontSize: blok?.font_size + 'px',
      }}
      className={classNames('font-medium leading-4 flex items-center')}
    >
      {blok?.marquee ? (
        <Marquee gradient={false}>{getText(blok?.message, data)}</Marquee>
      ) : (
        <div className="px-3 text-center w-full">
          <div>{getText(blok?.message, data)}</div>
        </div>
      )}
    </div>
  ) : null
}
