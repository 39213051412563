export const getGap = ({ prefix, desktop, mobile }) => {
  const mobileName = `${prefix}-${mobile}`
  const desktopName = `lg:${prefix}-${desktop}`

  const mobileGap = classes.includes(mobileName) ? mobileName : ''
  const desktopGap = classes.includes(desktopName) ? desktopName : ''

  return `${mobileGap} ${desktopGap}`.trim()
}

const classes = [
  'gap-x-0',
  'gap-x-1',
  'gap-x-2',
  'gap-x-3',
  'gap-x-6',
  'gap-x-9',
  'gap-x-12',
  'gap-x-14',
  'gap-x-16',
  'gap-x-20',
  'gap-x-24',
  'gap-x-28',
  'gap-x-32',
  'gap-x-36',
  'gap-x-40',
  'gap-x-44',
  'gap-x-48',
  'gap-x-52',
  'gap-x-56',
  'gap-x-60',
  'gap-x-64',
  'gap-x-72',
  'gap-x-80',
  'gap-x-96',

  'lg:gap-x-0',
  'lg:gap-x-1',
  'lg:gap-x-2',
  'lg:gap-x-3',
  'lg:gap-x-6',
  'lg:gap-x-9',
  'lg:gap-x-12',
  'lg:gap-x-14',
  'lg:gap-x-16',
  'lg:gap-x-20',
  'lg:gap-x-24',
  'lg:gap-x-28',
  'lg:gap-x-32',
  'lg:gap-x-36',
  'lg:gap-x-40',
  'lg:gap-x-44',
  'lg:gap-x-48',
  'lg:gap-x-52',
  'lg:gap-x-56',
  'lg:gap-x-60',
  'lg:gap-x-64',
  'lg:gap-x-72',
  'lg:gap-x-80',
  'lg:gap-x-96',

  'gap-y-0',
  'gap-y-1',
  'gap-y-2',
  'gap-y-3',
  'gap-y-6',
  'gap-y-9',
  'gap-y-12',
  'gap-y-14',
  'gap-y-16',
  'gap-y-20',
  'gap-y-24',
  'gap-y-28',
  'gap-y-32',
  'gap-y-36',
  'gap-y-40',
  'gap-y-44',
  'gap-y-48',
  'gap-y-52',
  'gap-y-56',
  'gap-y-60',
  'gap-y-64',
  'gap-y-72',
  'gap-y-80',
  'gap-y-96',

  'lg:gap-y-0',
  'lg:gap-y-1',
  'lg:gap-y-2',
  'lg:gap-y-3',
  'lg:gap-y-6',
  'lg:gap-y-9',
  'lg:gap-y-12',
  'lg:gap-y-14',
  'lg:gap-y-16',
  'lg:gap-y-20',
  'lg:gap-y-24',
  'lg:gap-y-28',
  'lg:gap-y-32',
  'lg:gap-y-36',
  'lg:gap-y-40',
  'lg:gap-y-44',
  'lg:gap-y-48',
  'lg:gap-y-52',
  'lg:gap-y-56',
  'lg:gap-y-60',
  'lg:gap-y-64',
  'lg:gap-y-72',
  'lg:gap-y-80',
  'lg:gap-y-96',
]
