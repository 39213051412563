import classNames from 'classnames'
import Link from 'next/link'
import { Fragment } from 'react'
import { getText } from '../../../helpers/getText'

const getCategoryBreadcrumbs = ({ data }) => {
  if (
    data?.product &&
    data?.product?.categoryName &&
    data.product?.categoryUri
  ) {
    return {
      names: [...data.product.categoryName, data.product.name],
      uris: [...data.product.categoryUri.split('/'), data.product.uri],
    }
  } else if (data?.product) {
    return { names: [data.product.name], uris: [data.product.uri] }
  }

  if (data?.categoryData) {
    return {
      names: data?.categoryData?.name,
      uris: data?.categoryData?.uri.split('/') ?? [],
    }
  }

  return false
}

export const BreadcrumbsSharable = ({ data, blok }) => {
  // Using RDFa strucutred data
  const homeTitle = data?.settingsData?.breadcrumb_home_title ?? ''

  let breadcrumbs
  let isCategory
  if (getCategoryBreadcrumbs({ data })) {
    isCategory = true
    breadcrumbs = getCategoryBreadcrumbs({ data })
  } else if (data[blok._uid]?.breadcrumbs) {
    isCategory = false
    breadcrumbs = data[blok._uid].breadcrumbs
  }
  const getHref = (index) => {
    if (index !== breadcrumbs.uris.length - 1) {
      const route = breadcrumbs.uris.slice(0, index + 1).join('/')
      if (isCategory) {
        return process.env.NEXT_PUBLIC_SITE_URL + '/category/' + route
      }

      return process.env.NEXT_PUBLIC_SITE_URL + route
    }
  }

  return (
    <ol
      className={classNames(
        'flex space-x-1 items-center',
        'text-xs md:text-xs md:space-x-2 uppercase',
        'whitespace-nowrap scrollbar-hidden overflow-x-scroll',
      )}
      vocab="https://schema.org/"
      typeof="BreadcrumbList"
    >
      <BreadcrumbItem
        href={process.env.NEXT_PUBLIC_SITE_URL}
        name={getText(homeTitle, data)}
        index={1}
      />
      {breadcrumbs?.uris?.map((_, index) => {
        if (index + 1 === breadcrumbs.uris.length) {
          return (
            <BreadcrumbItem
              name={breadcrumbs.names?.[index]}
              key={index}
              index={index + 2}
            />
          )
        }

        return (
          <BreadcrumbItem
            href={getHref(index)}
            key={index}
            name={breadcrumbs.names?.[index]}
            index={index + 2}
          />
        )
      })}
    </ol>
  )
}

const BreadcrumbItem = ({ name, href = false, index }) => {
  return (
    <li
      className={'flex whitespace-nowrap'}
      property="itemListElement"
      typeof="ListItem"
    >
      {href ? (
        <Fragment>
          <Link href={href}>
            <a className="underline" property="item" typeof="WebPage">
              <span property="name">{name}</span>
            </a>
          </Link>
          <p className={'ml-1 md:ml-2'}>/</p>
        </Fragment>
      ) : (
        <Fragment>
          <span property="name">{name}</span>
        </Fragment>
      )}
      {index && <meta property="position" content={index} />}
    </li>
  )
}
