import { SideModal } from '../modals/SideModal'
import { Loading } from '../../atoms/Loading'
import { SizePicker } from '../sizePicker/SizePicker'
import { observer } from 'mobx-react'
import { useContext, useState } from 'react'
import { CartStoreContext } from '../../../store/cartStore'
import { Button } from '../../atoms/Button'
import { ShoppingBagIcon } from '@heroicons/react/outline'
import classNames from 'classnames'

export const ModalUpSell = observer(
  ({
    buttonTitle,
    sizeModalTitle,
    sizePickerDesign = 'dropdown',
    product,
    addedToCartTitle,
    onAdd,
    buttonVariant = 'secondary',
    mobileIconAbsolute = true,
    showIconOnMobile = true,
  }) => {
    const [show, setShow] = useState(false)
    const { addToCart } = useContext(CartStoreContext)
    const [addToCartLoading, setAddToCartLoading] = useState(false)

    const onClick = async () => {
      if (product?.items?.length === 1) {
        setAddToCartLoading(true)
        await addToCart(
          product?.items.find(
            (item) => item.itemTableX === 0 && item.itemTableY === 0,
          ),
          product,
        )
        if (onAdd) {
          onAdd()
        }
      } else {
        setShow(true)
      }

      setAddToCartLoading(false)
    }
    return (
      <>
        {showIconOnMobile && (
          <div
            className={classNames(
              'flex items-center p-2  justify-end lg:hidden bg-white rounded-full',
              mobileIconAbsolute && 'absolute top-0 right-0 mt-2 mr-2',
              product?.available === false && 'hidden',
            )}
          >
            {addToCartLoading ? (
              <Loading className={'inline-flex h-5 w-5'} />
            ) : (
              <ShoppingBagIcon
                style={{ color: 'black' }}
                className={classNames('flex-shrink-0 h-6 w-6')}
                aria-hidden="true"
                disabled={product?.available === false}
                onClick={async (e) => {
                  e.preventDefault()
                  onClick()
                }}
              />
            )}
          </div>
        )}

        <div
          className={classNames(
            'flex items-center justify-end',
            showIconOnMobile && 'hidden lg:flex',
          )}
        >
          <Button
            className={classNames(product?.available === false && 'hidden')}
            disabled={product?.available === false}
            afterLoadingMessage={addedToCartTitle}
            loading={addToCartLoading}
            onClick={async (e) => {
              e.preventDefault()
              onClick()
            }}
            type={'upsell'}
            variant={buttonVariant}
          >
            {buttonTitle}
          </Button>
        </div>
        <SideModal
          title={sizeModalTitle}
          show={show}
          onClose={() => {
            setShow(false)
          }}
        >
          <>
            <div className={'flex flex-col'}>
              {addToCartLoading ? (
                <Loading />
              ) : (
                <SizePicker
                  product={product}
                  design={sizePickerDesign}
                  onSizeClicked={async (size) => {
                    window.dontShowFlyout = true
                    setAddToCartLoading(true)
                    await addToCart(
                      product?.items.find(
                        (item) =>
                          item.itemTableX === size.x &&
                          item.itemTableY === size.y,
                      ),
                      product,
                    )
                    setAddToCartLoading(false)
                    await setShow(false)
                    if (onAdd) {
                      onAdd()
                    }
                    window.dontShowFlyout = false
                  }}
                />
              )}
            </div>
          </>
        </SideModal>
      </>
    )
  },
)
