export const getProductPrice = ({ CSObject, product }) => {
  let price
  if (product?.price) {
    // Client
    price = {
      price: product?.price,
      priceBeforeDiscount: product?.priceBeforeDiscount,
      discountPercent: product?.discountPercent,
      showAsOnSale: product?.showAsOnSale,
      showAsNew: product?.showAsNew,
    }
  } else if (product?.prices) {
    // Server
    if (!CSObject) {
      const firstKey = Object.keys(product?.prices)[0]
      price = product?.prices[firstKey]
    }
    price = product?.prices[CSObject?.location?.pricelist]
  }

  if (price) {
    // Change priceBeforeDiscount to lowest price last 30 days only if there is a campaign
    if (price?.priceBeforeDiscount !== price?.price) {
      price['priceBeforeDiscount'] =
        product?.lowestPrice[0]?.priceBeforeDiscount ??
        price?.priceBeforeDiscount
    }

    return price
  }
}
