import { getProductImage } from '../../../helpers/getProductImage'
import Image from 'next/future/image'
import { getImage } from '../../../helpers/getImage'
import { getProductPrice } from '../../../helpers/getProductPrice'
import { observer } from 'mobx-react'
import { CartStoreContext } from '../../../store/cartStore'
import { useContext } from 'react'
import { ModalUpSell } from './ModalUpSell'
import classNames from 'classnames'
import { getImageAlt } from '../../../helpers/getImageAlt'
import { useRouter } from 'next/router'

export const Upsell = observer(
  ({
    product,
    buttonTitle,
    pickSizeTitle,
    sizePickerDesign,
    addedToCartTitle,
    outOfStockTitle,
    imageClass,
    onAdd,
    buttonVariant,
  }) => {
    const router = useRouter()

    const { CSObject } = useContext(CartStoreContext)
    const prices = getProductPrice({ CSObject, product })
    return product ? (
      <div className={'w-full flex mt-3 justify-between'}>
        <div
          className={'flex cursor-pointer'}
          onClick={() => {
            router.push('/product/' + product.uri)
          }}
        >
          <div className={'h-16 w-16'}>
            <Image
              src={getImage(getProductImage(product))}
              alt={getImageAlt({
                image: getImage(getProductImage(product)),
                alt: product?.name,
              })}
              className={classNames(imageClass)}
              width={100}
              height={100}
            />
          </div>
          <div className={'pl-3 h-16 flex flex-col justify-center'}>
            <p className={'text-sm font-bold'}>{product?.name}</p>
            <p className={'text-xs'}>
              {prices?.priceBeforeDiscount !== prices?.price && (
                <span className="line-through mr-2 text-gray-400">
                  {prices?.priceBeforeDiscount}
                </span>
              )}
              {prices?.price}
            </p>
          </div>
        </div>

        {product?.available ? (
          <ModalUpSell
            addedToCartTitle={addedToCartTitle}
            buttonTitle={buttonTitle}
            sizePickerDesign={sizePickerDesign}
            product={product}
            sizeModalTitle={pickSizeTitle}
            onAdd={() => {
              if (onAdd) {
                onAdd()
              }
            }}
            buttonVariant={buttonVariant}
            mobileIconAbsolute={false}
            showIconOnMobile={false}
          />
        ) : (
          <div className={'h-16 flex items-center text-xs italic pr-2'}>
            <p>{outOfStockTitle ? outOfStockTitle : 'out of stock'}</p>
          </div>
        )}
      </div>
    ) : null
  },
)
