import classNames from 'classnames'
import { Loading } from './Loading'
import { useEffect, useState } from 'react'
import { CheckIcon } from '@heroicons/react/outline'

export const Button = ({
  id,
  className,
  children,
  style,
  onClick,
  loading,
  disabled,
  afterLoadingMessage,
  variant = 'primary',
  type = 'default',
}) => {
  const [showAfterLoadingMessage, setShowAfterLoadingMessage] = useState(false)
  const [loadingTriggered, setLoadingTriggered] = useState(false)
  useEffect(() => {
    if (loading) {
      setLoadingTriggered(true)
    }

    if (!loading && loadingTriggered) {
      setShowAfterLoadingMessage(true)
      setTimeout(() => {
        setShowAfterLoadingMessage(false)
        setLoadingTriggered(false)
      }, 2000)
    }
  }, [loading, loadingTriggered])

  const getContent = () => {
    if (afterLoadingMessage && showAfterLoadingMessage) {
      return (
        <div>
          <CheckIcon className={'inline-flex w-5 h-5'} />
          {afterLoadingMessage?.length > 0 && (
            <span className={'ml-3'}>{afterLoadingMessage}</span>
          )}
        </div>
      )
    } else {
      return (
        <div className="flex justify-center items-center">
          {loading && <Loading className={'inline-flex h-5 w-5'} />}
          {children && (
            <span className={classNames(loading ? 'ml-3' : '')}>
              {children}
            </span>
          )}
        </div>
      )
    }
  }

  // Size Styles
  const paddings =
    type === 'upsell'
      ? 'py-1 px-3'
      : 'py-[var(--button-padding-t-b)] lg:py-[var(--button-padding-t-b-desktop)] px-[var(--button-padding-r-l)] lg:px-[var(--button-padding-r-l-desktop)]'
  const fontSize = type === 'upsell' ? 'text-xs' : 'vt-button-custom-1337'

  return (
    <button
      id={id}
      disabled={loading || disabled}
      onClick={onClick}
      className={classNames(
        'relative cursor-pointer',
        'font-title',
        'rounded-[var(--button-corner-radius)] lg:rounded-[var(--button-corner-radius-desktop)]',
        paddings,
        fontSize,
        variantClassnames[variant ? variant : 'primary'].base,
        disabled
          ? variantClassnames[variant ? variant : 'primary'].disabled
          : variantClassnames[variant ? variant : 'primary'].hover,
        className ? className : '',
      )}
      style={style}
    >
      {getContent()}
    </button>
  )
}

const variantClassnames = {
  primary: {
    base: 'border border border-buttonBorderColorPrimary text-buttonTextColorPrimary bg-buttonPrimaryColor',
    hover: ' hover:bg-buttonPrimaryColorHover',
    disabled: 'opacity-60 cursor-not-allowed',
  },
  secondary: {
    base: 'border border border-buttonBorderColorSecondary text-buttonTextColorSecondary bg-buttonSecondaryColor',
    hover: ' hover:bg-buttonSecondaryColorHover',
    disabled: 'opacity-60 cursor-not-allowed',
  },
  third: {
    base: 'border border border-buttonBorderColorThird text-buttonTextColorThird bg-buttonThirdColor',
    hover: ' hover:bg-buttonThirdColorHover',
    disabled: 'opacity-60 cursor-not-allowed',
  },
}
