import dynamic from 'next/dynamic'

// Basics
import { SectionGrid } from '../components/bloks/SectionGrid'
import { Column } from '../components/bloks/Column'
import { Page } from '../components/page_types/Page'
import { Text } from '../components/bloks/Text'
import { Spacer } from '../components/bloks/Spacer'
import { RichText } from '../components/bloks/RichText'
import { ImageComponent } from '../components/bloks/ImageComponent'
import { ButtonComponent } from '../components/bloks/ButtonComponent'

// Other
import { Breadcrumbs } from '../components/bloks/Breadcrumbs'
import { MoreSell } from '../components/page_types/receipt/MoreSell'
import { PagesGrid } from '../components/bloks/PagesGrid'
import { NewsLetter } from '../components/bloks/NewsLetter'
import { Collapsible } from '../components/bloks/Collapsible'
import { Video } from '../components/bloks/Video'
import { Banner } from '../components/bloks/Banner'
import { Instagram } from '../components/bloks/Instagram'
import { Compare } from '../components/bloks/Compare'

// Header + Footer
import { HeaderLink } from '../components/bloks/HeaderLink'
import { SubmenuCentraCategoryLinks } from '../components/bloks/SubmenuCentraCategoryLinks'
import { SideMenuLink } from '../components/bloks/SideMenuLink'
import { CartButton } from '../components/layout/headers/CartButton'
import { FooterColumn } from '../components/layout/footers/FooterColumn'
import { Socials } from '../components/layout/footers/Socials'
import { FooterNewsLetter } from '../components/layout/footers/FooterNewsLetter'
import { CountryLanguageChooser } from '../components/sharable/countryChooser/CountryChooser'
import { SideMenu } from '../components/bloks/SideMenu'
import { SearchButton } from '../components/layout/headers/SearchButton'
// const HeaderLink = dynamic(() =>
//   import('../components/bloks/HeaderLink').then((mod) => mod.HeaderLink),
// )
// const SubmenuCentraCategoryLinks = dynamic(() =>
//   import('../components/bloks/SubmenuCentraCategoryLinks').then(
//     (mod) => mod.SubmenuCentraCategoryLinks,
//   ),
// )
// const SideMenuLink = dynamic(() =>
//   import('../components/bloks/SideMenuLink').then((mod) => mod.SideMenuLink),
// )
// const CartButton = dynamic(() =>
//   import('../components/layout/headers/CartButton').then(
//     (mod) => mod.CartButton,
//   ),
// )
// const FooterColumn = dynamic(() =>
//   import('../components/layout/footers/FooterColumn').then(
//     (mod) => mod.FooterColumn,
//   ),
// )
// const Socials = dynamic(() =>
//   import('../components/layout/footers/Socials').then((mod) => mod.Socials),
// )
// const FooterNewsLetter = dynamic(() =>
//   import('../components/layout/footers/FooterNewsLetter').then(
//     (mod) => mod.FooterNewsLetter,
//   ),
// )
// const CountryLanguageChooser = dynamic(() =>
//   import('../components/sharable/countryChooser/CountryChooser').then(
//     (mod) => mod.CountryLanguageChooser,
//   ),
// )
// const SideMenu = dynamic(() =>
//   import('../components/bloks/SideMenu').then((mod) => mod.SideMenu),
// )
// const SearchButton = dynamic(() =>
//   import('../components/layout/headers/SearchButton').then(
//     (mod) => mod.SearchButton,
//   ),
// )

// Product
import { ProductsRow } from '../components/bloks/ProductsRow'
import { ProductSpecifications } from '../components/page_types/product/bloks/ProductSpecifications'
import { ProductInfoAccordion } from '../components/page_types/product/bloks/ProductInfoAccordion'
import { ProductInfoAccordionRichText } from '../components/page_types/product/bloks/ProductInfoAccordionRichText'
import { StickyAddToCart } from '../components/bloks/StickyAddToCart'
import { ProductImagesFetcher } from '../components/page_types/product/bloks/ProductImages'
import { ProductInfoContainer } from '../components/page_types/product/bloks/ProductInfo'
import { RelatedProducts } from '../components/bloks/RelatedProducts'
import { ProductSizeChart } from '../components/page_types/product/bloks/ProductSizeChart'

// Category
import { CategoryTwoColumnHeader } from '../components/page_types/category/bloks/CategoryTwoColumnHeader'
import { CategorySingleTitleHeader } from '../components/page_types/category/bloks/CategorySingleTitleHeader'
import {
  ProductGrid,
  SearchProductGrid,
} from '../components/page_types/category/ProductGrid'
import { TrustPilot } from '../components/bloks/TrustPilot'
import { StandaloneProductCard } from '../components/bloks/StandaloneProductCard'
import { Script } from '../components/bloks/Script'
import { Modal } from '../components/bloks/Modal'
import { SizeCalculator } from '../components/bloks/SizeCalculator'
import { Filters } from '../components/page_types/category/bloks/Filters'
import { Sort } from '../components/page_types/category/bloks/Sort'
import { ProductPageAddToCartButton } from '../components/page_types/product/bloks/ProductPageAddToCartButton'
import { ProductPageTitle } from '../components/page_types/product/bloks/ProductPageTitle'
import { ProductPageVariants } from '../components/page_types/product/bloks/ProductPageVariants'
import { ProductPageShortDesc } from '../components/page_types/product/bloks/ProductPageShortDesc'
import { ProductPageRibbons } from '../components/page_types/product/bloks/ProductPageRibbons'
import { ProductPageSizePicker } from '../components/page_types/product/bloks/ProductPageSizePicker'
import { ProductPageFewLeft } from '../components/page_types/product/bloks/ProductPageFewLeft'
import { ProductPagePreOrder } from '../components/page_types/product/bloks/ProductPagePreOrder'
import { ProductPagePrice } from '../components/page_types/product/bloks/ProductPagePrice'
import { Yotpo } from '../components/page_types/product/bloks/Yotpo'
import { UpsellCart } from '../components/layout/headers/UpsellCart'

// Checkout
// import { CheckoutCart } from '../components/bloks/checkout/CheckoutCart'
// import { Klarna } from '../components/bloks/checkout/Klarna'
// import { Ingrid } from '../components/bloks/checkout/Ingrid'
// import { Receipt } from '../components/page_types/receipt/Receipt'
const CheckoutCart = dynamic(() =>
  import('../components/bloks/checkout/CheckoutCart').then(
    (mod) => mod.CheckoutCart,
  ),
)
const Klarna = dynamic(() =>
  import('../components/bloks/checkout/Klarna').then((mod) => mod.Klarna),
)
const Ingrid = dynamic(() =>
  import('../components/bloks/checkout/Ingrid').then((mod) => mod.Ingrid),
)
const Receipt = dynamic(() =>
  import('../components/page_types/receipt/Receipt').then((mod) => mod.Receipt),
)

// Third party
// import { FindifySearch } from '../components/layout/headers/findify/FindifySearch'
// import { Findify } from '../components/bloks/Findify'
// import { IngridTracking } from '../components/bloks/IngridTracking'
// import { YotpoProductReviews } from '../components/page_types/product/bloks/YotpoProductReviews'
// import { YotpoPictures } from '../components/bloks/YotpoPictures'
// import { Stamped } from '../components/page_types/product/bloks/Stamped'
// import { MailchimpContactForm } from '../components/bloks/MailchimpContactForm'
// import { YoutubePlayer } from '../components/bloks/YoutubePlayer'
// import {
//   StoreLocatorList,
//   StoreLocatorMap,
// } from '../components/bloks/StoreLocator'
const FindifySearch = dynamic(() =>
  import('../components/layout/headers/findify/FindifySearch').then(
    (mod) => mod.FindifySearch,
  ),
)
const Findify = dynamic(() =>
  import('../components/bloks/Findify').then((mod) => mod.Findify),
)
const IngridTracking = dynamic(() =>
  import('../components/bloks/IngridTracking').then(
    (mod) => mod.IngridTracking,
  ),
)
const YotpoProductReviews = dynamic(() =>
  import('../components/page_types/product/bloks/YotpoProductReviews').then(
    (mod) => mod.YotpoProductReviews,
  ),
)
const YotpoPictures = dynamic(() =>
  import('../components/bloks/YotpoPictures').then((mod) => mod.YotpoPictures),
)
const Stamped = dynamic(() =>
  import('../components/page_types/product/bloks/Stamped').then(
    (mod) => mod.Stamped,
  ),
)
const MailchimpContactForm = dynamic(() =>
  import('../components/bloks/MailchimpContactForm').then(
    (mod) => mod.MailchimpContactForm,
  ),
)
const YoutubePlayer = dynamic(() =>
  import('../components/bloks/YoutubePlayer').then((mod) => mod.YoutubePlayer),
)

const Iframe = dynamic(() =>
  import('../components/bloks/Iframe').then((mod) => mod.Iframe),
)
const StoreLocatorList = dynamic(() =>
  import('../components/bloks/StoreLocator').then(
    (mod) => mod.StoreLocatorList,
  ),
)
const StoreLocatorMap = dynamic(() =>
  import('../components/bloks/StoreLocator').then((mod) => mod.StoreLocatorMap),
)

export const allowedComponents = {
  default_page: {
    page: Page,
    banner: Banner,
    rich_text: RichText,
    spacer: Spacer,
    video: Video,
    mail_chimp_contact_form: MailchimpContactForm,
    instagram: Instagram,
    //news_letter_sign_up: NewsLetterSignUp,
    products_row: ProductsRow,
    trust_pilot: TrustPilot,
    //product_promo: ProductPromo, // REMOVE ALL CODE AS WELL IN FETCH CENTRA
    sticky_add_to_cart: StickyAddToCart,
    section_grid: SectionGrid,
    column: Column,
    text: Text,
    image: ImageComponent,
    findify: Findify,
    product_images: ProductImagesFetcher,
    product_info: ProductInfoContainer,
    breadcrumbs: Breadcrumbs,
    footer_column: FooterColumn,
    socials: Socials,
    footer_news_letter: FooterNewsLetter,
    country_language_chooser: CountryLanguageChooser,
    youtube: YoutubePlayer,
    button: ButtonComponent,
    store_locator_map: StoreLocatorMap,
    store_locator_list: StoreLocatorList,
    stamped: Stamped,
    header_link: HeaderLink,
    search_button: SearchButton,
    findify_search: FindifySearch,
    cart_button: CartButton,
    side_menu: SideMenu,
    side_menu_link: SideMenuLink,
    //detail_side_menu_links: DetailSideMenuLinks,
    ingrid_tracking: IngridTracking,
    yotpo_pictures: YotpoPictures,
    submenu_centra_category_links: SubmenuCentraCategoryLinks,
    category_two_column_header: CategoryTwoColumnHeader,
    category_single_title_header: CategorySingleTitleHeader,
    product_grid: ProductGrid,
    search_product_grid: SearchProductGrid,
    pages_grid: PagesGrid,
    newsletter: NewsLetter,
    checkout_cart: CheckoutCart,
    klarna: Klarna,
    ingrid: Ingrid,
    related_products: RelatedProducts,
    collapsible: Collapsible,
    product_info_accordion_rich_text: ProductInfoAccordionRichText,
    product_size_chart: ProductSizeChart,
    standalone_product_card: StandaloneProductCard,
    script: Script,
    modal: Modal,
    size_calculator: SizeCalculator,
    iframe: Iframe,
    compare: Compare,
    filters: Filters,
    sort: Sort,
    receipt: Receipt,
    receipt_more_sell: MoreSell,
    yotpo_product_reviews: Yotpo,
    product_specifications: ProductSpecifications,
    product_info_accordion: ProductInfoAccordion,
    product_page_add_to_cart_button: ProductPageAddToCartButton,
    product_page_title: ProductPageTitle,
    product_page_variants: ProductPageVariants,
    product_page_short_description: ProductPageShortDesc,
    product_page_ribbons: ProductPageRibbons,
    product_page_size_picker: ProductPageSizePicker,
    product_page_few_left: ProductPageFewLeft,
    product_page_pre_order: ProductPagePreOrder,
    product_page_price: ProductPagePrice,
    upsell_cart: UpsellCart,
  },
}
