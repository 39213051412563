import Link from 'next/link'
import classNames from 'classnames'
import { getFontWeight } from '../../helpers/getFontWeight'
import { getFontSize } from '../../helpers/getFontSize'
import { getSubCategories } from '../../helpers/getSubCategories'
import { SideMenuLinkItem } from './SideMenuLink'
import { useContext, useState } from 'react'
import { SideMenuStoreContext } from '../../store/sideMenuStore'
import { TransitionWrapper } from './TransitionWrapper'
import { getPadding } from '../../helpers/getPadding'

export const SubmenuCentraCategoryLinks = ({ data, blok, pr, br = 0 }) => {
  return (
    <div>
      {blok?.in_side_menu === 'yes' ? (
        <Mobile blok={blok} data={data} />
      ) : (
        <Desktop blok={blok} data={data} br={br} pr={pr} />
      )}
    </div>
  )
}

const Mobile = ({ data, blok }) => {
  const {
    viewIndex,
    setViewIndex,
    backgroundColor,
    setShowMobileMenu,
    centraCategoryUri,
  } = useContext(SideMenuStoreContext)
  const uri = centraCategoryUri ? centraCategoryUri : data?.uri
  const cat =
    uri?.length > 0 && data?.categories?.find((cat) => cat.uri === uri)
  let subCategories = []
  if (cat) {
    subCategories = getSubCategories({ id: cat?.category, data })
  }

  const [mobileCategory, setMobileCategory] = useState()

  const getTitle = (title) => {
    if (typeof title === 'string' || title instanceof String) {
      return title
    } else if (title instanceof Array) {
      return title[title.length - 1]
    }
  }

  return (
    <div>
      {data?.settingsData.side_menu_all_title && (
        <SideMenuLinkItem
          link={'/category/' + centraCategoryUri}
          blok={{
            ...blok,
            title: data?.settingsData.side_menu_all_title
              ? data?.settingsData.side_menu_all_title
              : 'All',
          }}
          onClick={() => {
            setShowMobileMenu(false)
          }}
        />
      )}
      {subCategories?.length > 0 &&
        subCategories.map((category, index) => {
          const link =
            getSubCategories({ id: category?.category, data })?.length > 0
              ? false
              : '/category/' + category?.uri
          return (
            <div key={index}>
              <>
                <SideMenuLinkItem
                  link={link}
                  blok={{
                    ...blok,
                    title: getTitle(category?.name),
                  }}
                  onClick={() => {
                    setMobileCategory(category)
                    setViewIndex(2)
                    if (link) {
                      setShowMobileMenu(false)
                    }
                  }}
                  data={data}
                />
              </>
            </div>
          )
        })}

      <>
        <TransitionWrapper
          blok={{ background_color: backgroundColor }}
          data={data}
          show={viewIndex === 2}
        >
          {getSubCategories({ id: mobileCategory?.category, data }).map(
            (c, index) => {
              const link =
                getSubCategories({ id: c?.category, data })?.length > 0
                  ? false
                  : '/category/' + c?.uri
              return (
                <SideMenuLinkItem
                  key={index}
                  link={link}
                  blok={{
                    ...blok,
                    title: getTitle(c?.name),
                  }}
                  data={data}
                  onClick={() => {
                    if (link) {
                      setShowMobileMenu(false)
                    }
                  }}
                />
              )
            },
          )}
        </TransitionWrapper>
      </>
    </div>
  )
}

const Desktop = ({ data, blok, pr, br = 0 }) => {
  const uri = data?.uri
  const cat =
    uri?.length > 0 && data?.categories?.find((cat) => cat.uri === uri)
  let subCategories = []
  if (cat) {
    subCategories = getSubCategories({ id: cat?.category, data })
  }

  if (br > 1) {
    return null
  }

  const style = pr ? { paddingLeft: pr + 'px' } : null
  return (
    <div style={style}>
      {subCategories?.length > 0 &&
        subCategories.map((category, index) => {
          return (
            <div
              key={index}
              className={getPadding({
                prefix: 'pb',
                mobile: blok?.desktop_space_between
                  ? blok?.desktop_space_between
                  : 0,
              })}
            >
              <Link href={'/category/' + category.uri}>
                <a
                  style={{
                    color: blok?.text_color ? blok?.text_color : 'black',
                  }}
                  onClick={() => {
                    if (data?.onClickCategory) {
                      data?.onClickCategory()
                    }
                  }}
                  className={classNames(
                    'inline-block underline-offset-4 hover:underline py-1',
                    getFontWeight(blok?.font_weight),
                    getFontSize(blok?.font_size),
                  )}
                >
                  {category.name[category.name.length - 1]}
                </a>
              </Link>
              {getSubCategories({ id: category?.category, data })?.length >
                0 && (
                <SubmenuCentraCategoryLinks
                  blok={blok}
                  br={br + 1}
                  pr={parseInt(parseInt(blok?.indent_size) + parseInt(pr ?? 0))}
                  data={{ ...data, uri: category?.uri }}
                />
              )}
            </div>
          )
        })}
    </div>
  )
}
