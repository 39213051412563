import { useEffect, useState } from 'react'
import { Menu } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
// asd
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export const Dropdown = ({
  items,
  onSelected,
  placeholder = '',
  maxHeight = '500px',
  initialValue,
  isOutOfStockMode = false,
}) => {
  const [title, setTitle] = useState()
  useEffect(() => {
    if (initialValue?.value) {
      setTitle(initialValue)
    }
  }, [initialValue])
  return (
    <Menu as="div" className="relative inline-block text-left w-full">
      <Menu.Button
        className={classNames(
          'inline-flex justify-center w-full border border-gray-300 px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50',
          title?.disable && 'opacity-50',
        )}
      >
        {title?.value ?? placeholder}
        <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
      </Menu.Button>

      <Menu.Items
        className="z-10 origin-top-right absolute right-0 w-full bg-white border border-gray-300 divide-y divide-gray-100 focus:outline-none"
        style={{ marginTop: '-1px', maxHeight: maxHeight, overflowY: 'auto' }}
      >
        {items?.map((item, index) => {
          return (
            <div key={index}>
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={() => {
                      if (!item.disable || isOutOfStockMode) {
                        setTitle(item)
                        onSelected(index)
                      }
                    }}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-3 text-sm',
                      item.disable &&
                        !isOutOfStockMode &&
                        'opacity-50 line-through',
                    )}
                  >
                    {item.value}
                  </a>
                )}
              </Menu.Item>
            </div>
          )
        })}
      </Menu.Items>
    </Menu>
  )
}
