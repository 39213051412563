import {
  needRibbons,
  ProductCardRibbons,
} from '../../../sharable/productcards/ProductCardRibbons'
import { observer } from 'mobx-react'
import { useContext } from 'react'
import { ProductsDataStoreContext } from '../../../../store/productsDataStore'
import { CartStoreContext } from '../../../../store/cartStore'

export const ProductPageRibbons = observer(({ data, blok }) => {
  const { productPageProduct } = useContext(ProductsDataStoreContext)
  const product = productPageProduct

  const { CSObject } = useContext(CartStoreContext)
  return (
    <>
      {needRibbons({ product, CSObject }) && (
        <div className={'relative block'}>
          <ProductCardRibbons
            className={'text-xs mt-3 lg:pb-0'}
            product={product}
            CSObject={CSObject}
            data={data}
          />
        </div>
      )}
    </>
  )
})
