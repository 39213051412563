const classes = {
  row: 'flex-row',
  col: 'flex-col',
}
export const getFlexDirection = (value) => {
  if (value == null) {
    return 'flex-col'
  }

  if (classes[value]) {
    return classes[value]
  } else {
    return 'flex-col'
  }
}
