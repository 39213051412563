import { CartStoreContext } from '../../store/cartStore'
import { sbEditable } from '@storyblok/storyblok-editable'
import classNames from 'classnames'
import { Input } from '../atoms/Input'
import { Button } from '../atoms/Button'
import { useContext, useState } from 'react'
import { getSpaceX } from '../../helpers/getSpaceX'
import { getSpaceY } from '../../helpers/getSpaceY'

import Storyblok from '../../lib/storyblok/storyblok'
import { getText } from '../../helpers/getText'

export const NewsLetter = ({ blok, data }) => {
  const { subscribeToNewLetter } = useContext(CartStoreContext)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)
  const [checked, setChecked] = useState(false)
  const needCheck = blok?.show_confirm_check === 'yes'
  const html = blok?.confirm_text?.content
    ? Storyblok.richTextResolver.render(blok?.confirm_text)
    : blok?.confirm_text
  const uid = blok?._uid

  return (
    <>
      <div {...sbEditable(blok)} className={classNames('w-full flex w-full')}>
        <form
          className={classNames(
            'flex flex-col lg:flex-row w-full',
            getSpaceX(blok?.space_x_desktop, 'lg'),
            'lg:space-y-0',
            getSpaceY(blok?.space_y_mobile),
          )}
          onSubmit={async (event) => {
            event.preventDefault()
            if (email && email.length > 3) {
              setLoading(true)
              const s = await subscribeToNewLetter({ email: email })
              if (s) {
                setSuccess(true)
              }
              setLoading(false)
            }
          }}
        >
          {success ? (
            <div className={'flex justify-center w-full'}>
              <p
                style={{
                  color: blok?.text_color ? blok?.text_color : 'black',
                }}
              >
                {getText(blok?.success_title, data)}
              </p>
            </div>
          ) : (
            <>
              <Input
                id={`email-address-${uid}`}
                type="text"
                autoComplete="email"
                onChange={(event) => {
                  setEmail(event.target.value)
                }}
                // need to be +16px
                // https://css-tricks.com/16px-or-larger-text-prevents-ios-form-zoom/
                style={{ fontSize: '16px' }}
                value={email}
                placeholder={getText(blok?.email_placeholder_text, data)}
                required
                className="max-w-xl"
              />
              <div className="flex-shrink-0">
                {needCheck ? (
                  <Button
                    disabled={!checked}
                    loading={loading}
                    type={'submit'}
                    className="w-full"
                    variant={blok?.button_style}
                  >
                    {getText(blok?.subscribe_button_title, data)}
                  </Button>
                ) : (
                  <Button
                    loading={loading}
                    type={'submit'}
                    className="w-full"
                    variant={blok?.button_style}
                  >
                    {getText(blok?.subscribe_button_title, data)}
                  </Button>
                )}
              </div>
            </>
          )}
        </form>
      </div>

      {!success && needCheck && (
        <div className={'flex space-x-3  pt-3 items-center'}>
          <input
            id={`email-check-${uid}`}
            type="checkbox"
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
          <label
            htmlFor={`email-check-${uid}`}
            style={{
              color: blok?.text_color ? blok?.text_color : 'black',
            }}
            className={'text-sm'}
            dangerouslySetInnerHTML={{
              __html: getText(html, data),
            }}
          ></label>
        </div>
      )}
    </>
  )
}
