import { Button } from '../../atoms/Button'
import { useContext, useState } from 'react'
import { CartStoreContext } from '../../../store/cartStore'
import { Input } from '../../atoms/Input'

export const OutOfStockInput = ({
  placeholder = 'email',
  subTitle = 'Subscribe',
  successTitle = 'Success',
  productId,
  itemId,
  info,
  buttonStyle,
}) => {
  const { subscribeToNewLetter } = useContext(CartStoreContext)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)

  return (
    <div>
      {info && <p className="mb-1 text-sm">{info}</p>}

      <form
        onSubmit={async (event) => {
          event.preventDefault()
          if (email && email.length > 3) {
            setLoading(true)
            const s = await subscribeToNewLetter({
              email: email,
              product: productId,
              item: itemId,
            })
            if (s) {
              setSuccess(true)
            }
            setLoading(false)
          }
        }}
      >
        {success ? (
          <div className={'flex justify-center w-full'}>
            <p>{successTitle}</p>
          </div>
        ) : (
          <div
            className={'flex flex-col lg:flex-row lg:items-center lg:space-x-3'}
          >
            <Input
              id={`email-address-out-of-stock`}
              type="text"
              autoComplete="email"
              onChange={(event) => {
                setEmail(event.target.value)
              }}
              value={email}
              placeholder={placeholder}
              required
              className="lg:max-w-sm"
            />
            <div className="mt-3 lg:mt-0 flex-1">
              <Button
                loading={loading}
                type={'submit'}
                className="w-full"
                variant={buttonStyle}
              >
                {subTitle}
              </Button>
            </div>
          </div>
        )}
      </form>
    </div>
  )
}
