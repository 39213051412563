import classNames from 'classnames'
import DynamicComponent from '../DynamicComponent'
import { getPadding } from '../../helpers/getPadding'
import { getMaxWidth } from '../../helpers/getMaxWidth'
import { getGridCols } from '../../helpers/getGridCols'
import { getGap } from '../../helpers/getGap'
import { sbEditable } from '@storyblok/storyblok-editable'
import { getBackground } from '../../helpers/getBackground'

export function SectionGrid({ data, blok, preview }) {
  data.aboveFold = blok?.above_fold === 'yes'

  return (
    <div
      {...sbEditable(blok)}
      className={classNames(
        getVisibleOn(blok?.visible_on),
        'relative align-middle',
        getPadding({
          prefix: 'px',
          mobile:
            blok.padding_x_mobile ||
            data?.layoutData?.default_wrapper_padding_x_mobile,
          desktop:
            blok.padding_x_desktop ||
            data?.layoutData?.default_wrapper_padding_x_desktop,
        }),
        getPadding({
          prefix: 'py',
          mobile: blok.padding_y_mobile,
          desktop: blok.padding_y_desktop,
        }),
        getMaxWidth({ data, setMaxWidth: blok.max_width }),
        getGridCols({
          mobile: blok?.grid_cols_mobile,
          desktop: blok?.grid_cols_desktop,
        }),
        getGap({
          prefix: 'gap-x',
          mobile: blok?.gap_x_mobile,
          desktop: blok?.gap_x_desktop,
        }),
        getGap({
          prefix: 'gap-y',
          mobile: blok?.gap_y_mobile,
          desktop: blok?.gap_y_desktop,
        }),
      )}
      style={getStyles({ blok })}
    >
      {getBackground({ blok, aboveFold: data.aboveFold })}
      {blok.bloks?.map((nestedBlok, index) => (
        <DynamicComponent
          key={index}
          blok={nestedBlok}
          data={data}
          preview={preview}
        />
      ))}
    </div>
  )
}

const getStyles = ({ blok }) => {
  let styles = {}

  if (blok?.background_color) {
    styles['backgroundColor'] = blok?.background_color
  }
  if (blok?.border_top) {
    styles['borderTop'] = blok?.border_top
  }
  if (blok?.border_right) {
    styles['borderRight'] = blok?.border_right
  }
  if (blok?.border_bottom) {
    styles['borderBottom'] = blok?.border_bottom
  }
  if (blok?.border_left) {
    styles['borderLeft'] = blok?.border_left
  }

  return styles
}

const getVisibleOn = (visibleOn) => {
  if (visibleOn === 'mobile') {
    return 'lg:hidden grid'
  } else if (visibleOn === 'desktop') {
    return 'hidden lg:grid'
  } else {
    return 'grid'
  }
}
