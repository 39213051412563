import Link from 'next/link'
import { getProductImage } from '../../../helpers/getProductImage'
import classNames from 'classnames'
import Image from 'next/future/image'
import { getImageAlt } from '../../../helpers/getImageAlt'
import { image } from 'tailwindcss/lib/util/dataTypes'

export const Variant = ({
  variant,
  className,
  active = false,
  design = 'image',
  isVariantSameDisplay = false,
  onClick,
}) => {
  const html = ({ design, Tag, onClick }) => {
    if (design == 'name') {
      return (
        <Tag
          className={classNames(
            'mr-1 mb-1 border hover:border-black text-2xs justify-center items-center',
            className ?? '',
            active ? 'border-black' : 'cursor-pointer',
            'w-auto md:w-auto',
          )}
          onClick={onClick}
        >
          <p className="w-full h-full flex items-center p-2">
            {variant.variantName}
          </p>
        </Tag>
      )
    }

    if (design == 'color') {
      const variantColor = variant?.sh_swatch //TODO FIX NAMING OF ATTRIBUTES
      return (
        <Tag
          className={classNames(
            'inline-block mr-1 rounded-full p-[1px] w-8 h-8',
            className ?? '',
          )}
          onClick={onClick}
        >
          <div
            className={classNames(
              'h-full w-full overflow-hidden rounded-full flex p-[2px] justify-center items-center hover:border-black',
              active ? 'border border-black ' : 'cursor-pointer',
            )}
          >
            <div
              style={{ backgroundColor: variantColor?.hex?.trim() }}
              className={'w-full h-full rounded-full'}
            />
          </div>
        </Tag>
      )
    }

    return (
      <Tag
        className={classNames(
          'inline-block mr-1 border rounded-full p-[2px] hover:border-black w-8 h-8',
          className ?? '',
          active ? 'border-black' : 'cursor-pointer',
        )}
        onClick={onClick}
      >
        <div className="h-full w-full overflow-hidden rounded-full">
          <Image
            src={getProductImage(variant)}
            alt={getImageAlt({
              alt: variant.variantName,
              image: getProductImage(variant),
            })}
            width={100}
            height={100}
          />
        </div>
      </Tag>
    )
  }
  const setOnClick = () => {
    if (onClick) {
      onClick()
    } else {
      return false
    }
  }

  // Selected should never be clickable
  if (active) {
    return html({ design, Tag: 'div' })
  }

  // If all variants have the same URL
  if (isVariantSameDisplay) {
    return html({ design, Tag: 'div', onClick: setOnClick })
  }

  // If variant have different URLs
  return (
    <Link href={`/product/${variant.uri}`}>
      {html({ design, Tag: 'a', onCLick: setOnClick })}
    </Link>
  )
}
