import Head from 'next/head'
import dynamic from 'next/dynamic'
import Script from 'next/script'
import { parseCookies } from 'nookies'
import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { localeToLang } from '../helpers/localeToLang'
import { triggerPageView } from '../lib/analytics/events'
import classNames from 'classnames'
import { FindifyLoadScript } from './layout/headers/findify/FindifyLoadScript'
import { getSlugOnly } from '../helpers/getSlugOnly'
import { isServer } from '../helpers/isServer'
import { MainMeta } from './MainMeta'
import { setThirdPartyConsent } from '../helpers/setThirdPartyConsent'

const CookieModal = dynamic(() =>
  import('./sharable/cookieModal/CookieModal').then((mod) => mod.CookieModal, {
    ssr: false,
  }),
)

export const Main = ({ data, children, preview = false, meta }) => {
  const router = useRouter()
  const cookies = parseCookies()
  const consent = cookies?.cookieConsentCategories
  const querySlug = 'show_cookie_modal' in router?.query

  const [showCookieModal, setShowCookieModal] = useState(() => {
    if (preview) {
      return false
    }

    return !consent?.length > 0
  })

  useEffect(() => {
    setThirdPartyConsent({ consent, preview })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (querySlug) {
      setShowCookieModal(true)
    }
  }, [querySlug])

  useEffect(() => {
    triggerPageView()
    router.events.on('routeChangeComplete', triggerPageView)
    return () => {
      router.events.off('routeChangeComplete', triggerPageView)
    }
  }, [router.events])

  const bgColor = data?.layoutData?.background_color ?? 'white'
  const noBgPages = ['/checkout', '/receipt']
  const bgImage =
    data?.layoutData?.background_image?.filename?.length > 0 &&
    !noBgPages.includes(router.pathname)
      ? data?.layoutData?.background_image?.filename
      : false
  const bgRepeatClass =
    data?.layoutData?.background_repeat == 'no-repeat'
      ? 'bg-no-repeat'
      : 'bg-repeat'

  const getRobotsContent = () => {
    if (
      data?.settingsData?.meta_robots === 'noindex' ||
      process.env.NEXT_PUBLIC_SITE_URL?.includes('ventanas95')
    ) {
      return 'NOINDEX'
    }

    if (!isServer && window.location.search.length > 0) {
      return 'NOINDEX,FOLLOW'
    }

    return 'INDEX,FOLLOW'
  }
  const getCanonicalUrl = () => {
    const locale_uri =
      router.locale === router.defaultLocale ? '/' : `/${data.locale}/`

    return `${process.env.NEXT_PUBLIC_SITE_URL}${locale_uri}${getSlugOnly(
      router.asPath,
    )}`
  }

  return (
    <>
      <Head>
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="robots" content={getRobotsContent()} />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />

        <link rel="icon" href="/favicon-48.png" type="image/png" />
        <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
        <link rel="apple-touch-icon" href="/favicon-apple-touch.png" />
        <link rel="manifest" href="/manifest.json" />

        <link rel="canonical" href={getCanonicalUrl()} />
        {router.locales.map((locale) => {
          return (
            <link
              key={localeToLang(locale)}
              rel="alternate"
              hrefLang={localeToLang(locale)}
              href={`${
                process.env.NEXT_PUBLIC_SITE_URL
              }/${locale}/${getSlugOnly(router.asPath)}`}
            />
          )
        })}
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`${process.env.NEXT_PUBLIC_SITE_URL}/${getSlugOnly(
            router.asPath,
          )}`}
        />
      </Head>
      <MainMeta meta={meta} />
      {/* Set meta data */}
      {/* <MainMeta meta={meta} /> */}

      {/* Tracking and stuff. NextJS want all Script's outside of Head */}

      {/* Findify */}
      {data?.settingsData?.findify_mapping?.length > 0 && (
        <FindifyLoadScript data={data} />
      )}

      {/* Load tag manager */}
      {data?.settingsData?.gtm_container_id?.length > 0 && (
        <Script id="gtm">
          {getTagManager({ tag: data?.settingsData?.gtm_container_id })}
        </Script>
      )}

      {/* Load facebook pixel */}
      {data?.settingsData?.fb_pixel_id?.length > 0 && (
        <Script id="fb-pixel" data-cookieconsent="marketing">
          {getFacebookPixel({ pixel_id: data?.settingsData?.fb_pixel_id })}
        </Script>
      )}

      {data?.settingsData?.mail_chimp_mail_script && (
        <Script id="mcjs" strategy="lazyOnload">
          {data.settingsData.mail_chimp_mail_script}
        </Script>
      )}

      <main
        className={classNames('flex-grow', bgRepeatClass)}
        style={{
          ...(bgColor ? { backgroundColor: bgColor } : {}),
          ...(bgImage
            ? { backgroundImage: bgImage ? `url(${bgImage})` : '' }
            : {}),
        }}
      >
        <CookieModal
          data={data}
          blok={data?.settingsData}
          isOpen={showCookieModal}
          closeModal={() => {
            setShowCookieModal(false)
            setThirdPartyConsent({ consent, preview })
          }}
          preview={preview}
        />
        {children}
      </main>
    </>
  )
}

const getTagManager = ({ tag }) => {
  tag = tag?.includes('GTM-') ? tag : `GTM-${tag}`
  return `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}

    gtag('consent', 'default', {
      'ad_storage': 'denied',
      'analytics_storage': 'denied'
    });

    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${tag}');`
}

const getFacebookPixel = ({ pixel_id }) => {
  return `!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('consent', 'revoke');
  fbq('init', '${pixel_id}');
  fbq('track', 'PageView');
  window.addEventListener('CookiebotOnConsentReady',function(e){
    fbq('consent',Cookiebot.consent.marketing?'grant':'revoke')},!1)`
}
