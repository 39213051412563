import { Loading } from '../../atoms/Loading'

export const Loader = () => {
  return (
    <div
      className={
        'absolute top-0 bottom-0 right-0 left-0 z-20 flex justify-center items-center'
      }
    >
      <Loading className={'h-12 w-12'} />
    </div>
  )
}
