import { getProductImages } from '../../../../helpers/getProductImage'
import React, { useEffect, useState } from 'react'
import { getBorderRadius } from '../../../../helpers/getBorderRadius'
import classNames from 'classnames'
import { getSpaceY } from '../../../../helpers/getSpaceY'
import Image from 'next/future/image'
import { getPadding } from '../../../../helpers/getPadding'
import { Dot } from '../../../atoms/Dot'
import { sbEditable } from '@storyblok/storyblok-editable'
import { GeneralError } from '../../../atoms/GeneralError'
import { getImageAlt } from '../../../../helpers/getImageAlt'
import { useRef } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'
import { ImageZoom } from './ImageZoom'
import { getText } from '../../../../helpers/getText'
import { getFontWeight } from '../../../../helpers/getFontWeight'
import { getFontSize } from '../../../../helpers/getFontSize'
import { getLineHeight } from '../../../../helpers/getLineHeight'
import { getTextAlign } from '../../../../helpers/getTextAlign'

export const ProductImagesFetcher = ({ blok, data, preview }) => {
  let product = data?.product_images?.[blok._uid]?.product
  product = product ? product : data?.product

  if (!product && preview) {
    return (
      <GeneralError blok={blok}>
        No product found. Check the URI and that the product is available in
        Centra.
      </GeneralError>
    )
  }

  if (!product) {
    return <></>
  }

  return <ProductImagesSelect blok={blok} product={product} data={data} />
}

const ProductImagesSelect = ({ blok, product, data }) => {
  const type = blok?.style

  if (type === 'columns') {
    return (
      <ProductImagesColumns
        blok={blok}
        product={product}
        data={data}
        aboveFold={data?.aboveFold}
      />
    )
  }

  return (
    <ProductImages blok={blok} product={product} aboveFold={data.aboveFold} />
  )
}

const getWidthAndHeight = (blok) => {
  if (blok?.image_size === 'square') {
    return { width: 900, height: 900 }
  } else if (blok?.image_size === 'full') {
    return { width: 1000, height: 1000 }
  }

  return { width: 900, height: 900 }
}

const ProductImagesColumns = ({ blok, product, aboveFold, data }) => {
  const [pagination, setPagination] = useState(blok?.pagination_threshold || 4)
  let images = getProductImages(product, blok)
  const rounded = getBorderRadius(blok?.image_border_radius)
  const size = getWidthAndHeight(blok)

  return (
    <>
      <div
        {...sbEditable(blok)}
        className={classNames('w-full flex-wrap h-auto hidden md:flex ')}
      >
        {images.slice(0, pagination).map((image, index) => {
          return (
            <div
              className={classNames(
                'relative overflow-hidden w-1/2',
                rounded,
                getPadding({
                  prefix: 'pr',
                  desktop: blok?.space_between_images_desktop,
                  mobile: blok?.space_between_images_desktop,
                }),
                getPadding({
                  prefix: 'pb',
                  desktop: blok?.space_between_images_desktop,
                  mobile: blok?.space_between_images_desktop,
                }),
              )}
              key={index}
            >
              {image?.type === 'video' ? (
                <div className={classNames('w-full h-full', rounded)}>
                  <video
                    controls
                    poster={image?.url}
                    className={classNames('w-full h-full', rounded)}
                  >
                    <source src={image?.videoUrl} />
                  </video>
                </div>
              ) : (
                <Image
                  src={image?.url}
                  className={classNames(rounded, 'w-full h-full')}
                  alt={getImageAlt({ image: image?.url, alt: product?.name })}
                  width={size.width}
                  height={size.height}
                  priority={aboveFold}
                />
              )}
            </div>
          )
        })}
        {pagination < images.length && (
          <div className={'flex justify-center py-10 w-full'}>
            <p
              onClick={() => {
                setPagination(images.length)
              }}
              style={{
                color: blok?.load_more_tite_text_style?.[0]?.text_color
                  ? blok?.load_more_tite_text_style?.[0]?.text_color
                  : 'black',
              }}
              className={classNames(
                'underline cursor-pointer w-full',
                getFontWeight(
                  blok?.load_more_tite_text_style?.[0]?.font_weight,
                ),
                getFontSize(blok?.load_more_tite_text_style?.[0]?.font_size),
                getLineHeight(
                  blok?.load_more_tite_text_style?.[0]?.line_height,
                ) || 'text-center',
                getTextAlign(blok?.load_more_tite_text_style?.[0]?.text_align),
              )}
            >
              {getText(blok?.load_more_tite, data) || 'Load more'}
            </p>
          </div>
        )}
      </div>

      <div
        {...sbEditable(blok)}
        className={'relative  w-full items-stretch flex flex-col md:hidden'}
      >
        <MobileImageSlider
          images={images}
          rounded={rounded}
          aboveFold={aboveFold}
        />
      </div>
    </>
  )
}

const ProductImages = ({ blok, product, aboveFold }) => {
  let images = getProductImages(product, blok)
  const [bigImage, setBigImage] = useState(images[0])
  const rounded = getBorderRadius(blok?.image_border_radius)
  const size = getWidthAndHeight(blok)

  const firstImage = images?.[0]?.url
  useEffect(() => {
    setBigImage(images?.[0])
  }, [firstImage])

  return (
    <div {...sbEditable(blok)} className={'relative flex w-full items-stretch'}>
      <DesktopThumbs
        images={images}
        product={product}
        blok={blok}
        rounded={rounded}
        setBigImage={setBigImage}
      />

      <div
        className={classNames(
          'relative h-full hidden md:flex md:flex-1 z-30',
          images.length > 1 ? 'md:ml-28' : null,
          getPadding({
            prefix: 'px',
            desktop: blok?.big_image_padding_left_desktop,
          }),
        )}
      >
        {bigImage?.type === 'video' ? (
          <div className={classNames('w-full h-full', rounded)}>
            <video
              controls
              poster={bigImage?.url}
              className={classNames('w-full h-full', rounded)}
            >
              <source src={bigImage?.videoUrl} />
            </video>
          </div>
        ) : (
          <ImageZoom
            src={bigImage?.url}
            rounded={rounded}
            className={classNames('w-full h-full', rounded)}
            alt={getImageAlt({ image: bigImage?.url, alt: product?.name })}
            width={size.width}
            height={size.height}
            priority={aboveFold}
          />
        )}
      </div>

      <MobileImageSlider
        images={images}
        rounded={rounded}
        aboveFold={aboveFold}
      />
    </div>
  )
}

const DesktopThumbs = ({ product, images, blok, rounded, setBigImage }) => {
  // Desktop thumb scroll
  const thumbScrollRef = useRef()
  const [thumbsScrollAtTop, setThumbsScrollAtTop] = useState(true)
  const [thumbsScrollAtBottom, setThumbsScrollAtBottom] = useState(true)
  const handleScroll = () => {
    setThumbsScrollAtTop(thumbScrollRef?.current?.scrollTop === 0)
    setThumbsScrollAtBottom(
      thumbScrollRef?.current?.offsetHeight +
        thumbScrollRef?.current?.scrollTop >=
        thumbScrollRef?.current?.scrollHeight,
    )
  }
  const getThumbHeight = (e) => {
    const firstY = e.querySelectorAll('div')[0].getBoundingClientRect().top
    const secondY = e.querySelectorAll('div')[1].getBoundingClientRect().top

    return secondY - firstY
  }
  const scrollUp = () => {
    const height = getThumbHeight(thumbScrollRef.current)
    const pos =
      (Math.floor(thumbScrollRef.current.scrollTop / height) - 1) * height
    thumbScrollRef.current.scrollTop = pos

    if (pos <= 0) {
      thumbScrollRef.current.scrollTop = pos
    }
  }
  const scrollDown = () => {
    const height = getThumbHeight(thumbScrollRef.current)
    const pos =
      (Math.ceil(thumbScrollRef.current.scrollTop / height) + 1) * height

    if (pos + height <= thumbScrollRef.current.scrollHeight) {
      thumbScrollRef.current.scrollTop = pos
    }
  }
  useEffect(() => {
    // Always start on top when switch product images
    if (thumbScrollRef?.current) {
      thumbScrollRef.current.scrollTop = 0
    }
    handleScroll()
  }, [product?.product])

  const scrollClasses = `
    hidden md:block absolute p-1 z-20 w-28 left-0 text-center
    cursor-pointer bg-gray-100 bg-opacity-80
    hover:bg-gray-200 hover:bg-opacity-80
    transition-opacity duration-300
    select-none`
  const scrollBtnClasses = 'inline-block h-4 w-4 opacity-50 pointer-events-none'

  if (images.length <= 1) return <></>
  return (
    <>
      <div
        className={classNames(
          scrollClasses,
          'top-0',
          thumbsScrollAtTop && 'opacity-0 height-0',
        )}
        onClick={scrollUp}
      >
        <ChevronUpIcon className={scrollBtnClasses} />
      </div>

      <div
        className={classNames(
          scrollClasses,
          'bottom-0',
          thumbsScrollAtBottom && 'opacity-0 height-0',
        )}
        onClick={scrollDown}
      >
        <ChevronDownIcon className={scrollBtnClasses} />
      </div>

      <div
        ref={thumbScrollRef}
        onScroll={handleScroll}
        className={classNames(
          'hidden md:flex flex-col absolute overflow-y-scroll scrollbar-hidden bottom-0 top-0 z-10 scroll-smooth transition-all duration-500',
          getSpaceY(blok?.space_between_images_desktop),
        )}
      >
        {images.map((image, index) => {
          return (
            <div
              onClick={() => {
                setBigImage(image)
              }}
              key={index}
              className={classNames('w-28 h-28 cursor-pointer relative')}
            >
              {image.type === 'video' && (
                <div
                  className={
                    'absolute top-2 bottom-2 left-2 right-2 flex justify-center items-center'
                  }
                >
                  <div
                    style={{
                      borderStyle: 'solid',
                      borderWidth: '1em 0 1em 1.7em',
                      borderColor: 'transparent transparent transparent #000',
                      opacity: 0.75,
                    }}
                  />
                </div>
              )}
              <Image
                src={image?.url}
                className={classNames(rounded)}
                alt={getImageAlt({ image: image?.url, alt: product?.name })}
                width={120}
                height={120}
              />
            </div>
          )
        })}
      </div>
    </>
  )
}

const MobileImageSlider = ({ images, rounded, aboveFold }) => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = (event) => {
    const { scrollLeft, clientWidth } = event.target

    setScrollPosition(parseInt((scrollLeft + clientWidth / 2) / clientWidth))
  }

  return (
    <div
      className={classNames(
        'w-full flex flex-col md:hidden',
        rounded ? 'px-3 md:px-0' : '',
      )}
    >
      <div
        onScroll={handleScroll}
        className={classNames(
          'scrollbar-hidden',
          'flex flex-row overflow-x-scroll overscroll-x-contain snap-x snap-mandatory',
          rounded,
        )}
      >
        {images.map((image, index) => {
          return (
            <div
              key={index}
              className={classNames(
                'w-screen flex-shrink-0 relative snap-center',
              )}
            >
              {image.type === 'video' ? (
                <video
                  controls
                  poster={image?.url}
                  className={classNames('w-full h-full')}
                >
                  <source src={image?.videoUrl} />
                </video>
              ) : (
                <Image
                  src={image?.url}
                  alt={getImageAlt({ image: image?.url })}
                  width={600}
                  height={600}
                  className={'w-full h-auto'}
                  priority={index == 0 && aboveFold ? true : false}
                />
              )}
            </div>
          )
        })}
      </div>
      {images.length > 1 && (
        <div className={'flex space-x-2 justify-center mt-3'}>
          {images.map((_, index) => {
            return <Dot key={index} selected={index === scrollPosition} />
          })}
        </div>
      )}
    </div>
  )
}
