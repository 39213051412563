import { getImage } from './getImage'

export const getProductImage = (product) => {
  return getImage(
    product?.media?.square?.length > 0 && product?.media?.square[0],
  )
}

export const getProductImages = (product, blok) => {
  const type = blok?.image_size || 'square'
  const gif = getProductGif(product)
  const video = getProductVideo(product)
  let data = []

  if (product?.media?.[type]?.length > 0) {
    data.push(
      ...product?.media?.[type].map((image) => {
        return { type: 'image', url: image }
      }),
    )
  }

  if (gif) {
    data.splice(1, 0, { type: 'image', url: gif })
  }

  if (video) {
    data.splice(1, 0, {
      type: 'video',
      url: video?.url,
      videoUrl: video?.videoUrl,
    })
  }

  if (data.length == 0) {
    data.push({ type: 'image', url: getImage(false) })
  }

  return data
}

export const getProductGif = (product) => {
  if (product?.pr_gif?.url?.length > 0) {
    return product.pr_gif.url
  } else {
    return false
  }
}

export const getProductVideo = (product) => {
  if (product?.va_video?.url?.length > 0) {
    return {
      videoUrl: product.va_video.url,
      url: product?.va_video_placeholder?.url ?? getImage(false),
    }
  } else {
    return false
  }
}

// https://fagerbits.com/wp-content/uploads/2022/08/Freya-navy-5-864x1080.jpg
//
// <video controls="" poster="https://fagerbits.com/wp-content/uploads/2022/08/Freya-navy-5-864x1080.jpg">
//   <source src="https://fagerbits.com/wp-content/uploads/2022/08/Freya-navy.mp4" type="">
// </video>
