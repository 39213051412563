import { PlusSmIcon, MinusSmIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import DynamicComponent from '../../../DynamicComponent'
import { getText } from '../../../../helpers/getText'

export const ProductInfoAccordion = ({ blok, data, preview }) => {
  const product = data?.product
  const bloks = blok?.bloks
    ?.map((b) => {
      if (
        b?.component === 'product_size_chart' &&
        !product?.measurementChart?.contents
      ) {
        return false
      }

      return b
    })
    .filter((b) => b)

  return (
    <div
      className={'flex justify-center'}
      style={{ backgroundColor: blok?.background_color ?? 'white' }}
    >
      <div className={'flex w-full flex-col border-b'}>
        <InfoCell title={blok?.description_title}>
          <div
            dangerouslySetInnerHTML={{
              __html: data?.product.descriptionHtml,
            }}
          ></div>
        </InfoCell>
        {bloks?.map((tab, index) => {
          if (!preview && tab.component === 'product_specifications') {
            const items = []
            for (const item of tab?.items) {
              if (item.attribute_name in product) {
                items.push({
                  title: item.title,
                  data: product[item.attribute_name],
                })
              }
            }
            if (items?.length > 0) {
              return (
                <InfoCell key={index} title={tab?.title}>
                  {infoCellContent({ blok: tab, data, preview })}
                </InfoCell>
              )
            }
          } else {
            return (
              <InfoCell key={index} title={getText(tab?.title, data)}>
                {infoCellContent({ blok: tab, data, preview })}
              </InfoCell>
            )
          }
        })}
      </div>
    </div>
  )
}

const infoCellContent = ({ blok, data, preview }) => {
  if (blok.component === 'product_info_accordion_bloks') {
    return blok?.bloks?.map((b, index) => {
      return (
        <DynamicComponent key={index} blok={b} data={data} preview={preview} />
      )
    })
  }

  return <DynamicComponent blok={blok} data={data} preview={preview} />
}

const InfoCell = ({ children, title }) => {
  const [show, setShow] = useState(false)

  return (
    <div className={'w-full'}>
      <div
        onClick={() => {
          setShow(!show)
        }}
        className={'py-3 flex justify-between w-full border-t cursor-pointer'}
      >
        <p className={'font-medium text-base'}>{title}</p>
        {!show && <PlusSmIcon className="h-6 w-6" aria-hidden="true" />}
        {show && <MinusSmIcon className="h-6 w-6" aria-hidden="true" />}
      </div>
      {show && <div className={'pb-3'}>{children}</div>}
    </div>
  )
}
