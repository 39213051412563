import classNames from 'classnames'
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import { CartStoreContext } from '../../store/cartStore'
import { useRouter } from 'next/router'
import { Loading } from '../atoms/Loading'
import { sbEditable } from '@storyblok/storyblok-editable'
import { Button } from '../atoms/Button'
import { getText } from '../../helpers/getText'

export const StickyAddToCart = ({ blok, data }) => {
  const { addToCart } = useContext(CartStoreContext)
  const [loading, setLoading] = useState(false)
  const router = useRouter()
  const product = data?.product

  const [animate, setAnimate] = useState(false)
  const [y, setY] = useState(0)
  const thresholdHeight = blok?.threshold ? blok?.threshold : 50

  const handleNavigation = useCallback(
    (e) => {
      if (y < thresholdHeight) {
        // SCROLL UP
        setAnimate(false)
      } else if (y > thresholdHeight) {
        // SCROLL DOWN
        setAnimate(true)
      }
      setY(window.scrollY)
    },
    [y, thresholdHeight],
  )
  useEffect(() => {
    window.addEventListener('scroll', handleNavigation)

    return () => {
      window.removeEventListener('scroll', handleNavigation)
    }
  }, [handleNavigation])

  return (
    <Button
      {...sbEditable(blok)}
      onClick={async () => {
        const item = product?.items[0]
        if (item && product) {
          setLoading(true)
          // 🫡
          if (blok?.go_to_checkout_direct === 'yes') {
            window.dontShowFlyout = true
          }
          await addToCart(item, product)
          if (blok?.go_to_checkout_direct === 'yes') {
            await router.push('/checkout')
          }
          window.dontShowFlyout = false
          setLoading(false)
        }
      }}
      loading={loading}
      style={{
        backgroundColor: blok?.background_color ?? 'lightgray',
        bottom: animate ? 0 : -60,
        color: blok?.text_color ?? 'white',
        position: 'fixed',
      }}
      className={classNames(
        'bottom-0 inset-x-0 z-50',
        'flex justify-center items-center cursor-pointer',
        'transition-all ease-out duration-300',
        getVisibleOn(blok?.visible_on),
      )}
    >
      <p>{getText(blok?.title, data) ?? 'Add to cart'} </p>
    </Button>
  )
}

const getVisibleOn = (visibleOn) => {
  if (visibleOn === 'mobile') {
    return 'lg:hidden flex'
  } else if (visibleOn === 'desktop') {
    return 'hidden lg:flex'
  } else {
    return 'flex'
  }
}
