import { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import { CartStoreContext } from '../../../store/cartStore'
import { PaginationLoadMore } from '../../sharable/paginations/PaginationLoadMore'
import { useSearch } from '../../../hooks/useSearch'
import { useGetProducts } from '../../../hooks/useGetProducts'
import { MainProductCard } from '../../sharable/productcards/MainProductCard'
import classNames from 'classnames'
import { getPadding } from '../../../helpers/getPadding'
import { sbEditable } from '@storyblok/storyblok-editable'
import { Text } from '../../bloks/Text'
import { useRouter } from 'next/router'
import { ClientOnly } from '../../atoms/ClientOnly'
import { Loader } from '../../sharable/loader/Loader'
import { triggerSelectItem } from '../../../lib/analytics/events'
import { getText } from '../../../helpers/getText'
import { FilterStoreContext } from '../../../store/filterStore'
import { CompareBar } from '../../sharable/productcards/ProductCard'

export const ProductGrid = observer(({ data, blok, preview }) => {
  const { CSObject } = useContext(CartStoreContext)
  const { filters, sortOrder } = useContext(FilterStoreContext)

  const router = useRouter()
  const [products, productCount, loadMoreLoading, page, setPage, loading] =
    useGetProducts({ data, CSObject, filters, sortOrder })

  if (router?.query?.q?.length > 0) {
    return <SearchProductGrid data={data} blok={blok} />
  }

  return (
    <>
      <ClientOnly>{loading && <Loader />}</ClientOnly>

      <Grid
        products={products}
        blok={blok}
        data={data}
        loadMoreLoading={loadMoreLoading}
        page={page}
        setPage={setPage}
        productCount={productCount ?? []}
      />

      <ClientOnly>
        <CompareBar blok={blok} />
      </ClientOnly>
    </>
  )
})

export const SearchProductGrid = ({ blok, data }) => {
  const [
    products,
    productCount,
    searchLoading,
    loadMoreLoading,
    page,
    setPage,
    router,
  ] = useSearch()
  const story = data?.story?.content
  const searchTitle = story?.search_result_title
    ? story?.search_result_title.replace(
        '%search%',
        router?.query?.q ? router?.query?.q : '',
      )
    : ''

  return (
    <>
      <div
        className={classNames(
          'flex justify-center',
          getPadding({ prefix: 'py', mobile: blok?.padding_y }),
        )}
      >
        <Text blok={{ text_type: 'h2', text: searchTitle }} data={data} />
      </div>
      {products?.length > 0 ? (
        <Grid
          products={products}
          blok={blok}
          data={data}
          loadMoreLoading={loadMoreLoading}
          page={page}
          setPage={setPage}
          productCount={productCount}
        />
      ) : (
        <div className={'h-full w-full flex justify-center'}>
          {!searchLoading && (
            <p className={'pt-12 text-lg'}>{story?.search_not_found_title}</p>
          )}
        </div>
      )}
    </>
  )
}

SearchProductGrid.getInitialProps = ({ query }) => {
  return { query }
}

const Grid = observer(
  ({ blok, products, productCount, loadMoreLoading, page, setPage, data }) => {
    const { CSObject } = useContext(CartStoreContext)

    const getSpace = () => {
      const space = blok?.product_card?.[0]?.space_between ?? 's'
      if (space === 'none') {
        return 'gap-y-0 gap-x-0 '
      } else if (space === 's') {
        return 'gap-y-3 gap-x-3 '
      } else if (space === 'm') {
        return 'gap-y-6 gap-x-6 '
      } else if (space === 'l') {
        return 'gap-y-9 gap-x-9'
      }

      return 'gap-y-3 gap-x-3 '
    }

    return (
      <div
        {...sbEditable(blok)}
        className={classNames(
          'flex justify-center',
          getPadding({ prefix: 'py', mobile: blok?.padding_y }),
        )}
      >
        <div className={classNames(' w-full flex flex-col h-full w-full ')}>
          {products?.length > 0 && (
            <div
              id={'v95-product-grid'}
              className={classNames(
                'grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 ',
                getSpace(),
              )}
            >
              {products?.map((product, index) => {
                return (
                  <MainProductCard
                    product={product}
                    CSObject={CSObject}
                    blok={blok}
                    data={data}
                    key={index}
                    onClickCard={() => {
                      if (CSObject) {
                        const item = product?.items[0] ? product?.items[0] : {}
                        triggerSelectItem({
                          itemListId: data?.uri ?? 'category_grid',
                          itemListName:
                            data?.categoryData?.name?.toString() ??
                            'Category Grid',
                          item: { ...item, product: product },
                          CSObject,
                        })
                      }
                    }}
                  />
                )
              })}
            </div>
          )}
          <PaginationLoadMore
            total={productCount}
            loading={loadMoreLoading}
            skipPages={page}
            limit={process.env.NEXT_PUBLIC_CENTRA_PRODUCT_LIMIT}
            onPageClick={(page) => {
              setPage(page)
            }}
            loadMoreTitle={getText(blok?.pagination_load_more_title, data)}
            info={getText(blok?.pagination_info_title, data)}
          />
        </div>
      </div>
    )
  },
)
