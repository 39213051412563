import { getStampedScript } from '../../../../helpers/getStampedScript'

export const StampedStars = ({ blok, data }) => {
  const product = data?.product
  return (
    <>
      {getStampedScript({ data })}
      <span
        className="stamped-product-reviews-badge stamped-main-badge"
        data-id={product?.centraProduct}
      ></span>
    </>
  )
}
