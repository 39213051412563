import { BreadcrumbsSharable } from '../../../sharable/breadcrumbs/BreadcrumbsSharable'
import classNames from 'classnames'
import { sbEditable } from '@storyblok/storyblok-editable'

export const CategorySingleTitleHeader = ({ blok, data }) => {
  return (
    <div {...sbEditable(blok)} className={'w-full flex justify-center'}>
      <div className={classNames('w-full')}>
        {blok?.show_breadcrumbs && <BreadcrumbsSharable data={data} />}

        <h1 className={'font-bold text-xl font-title'}>
          {data?.categoryData?.name.slice(-1)[0]}
        </h1>
      </div>
    </div>
  )
}
