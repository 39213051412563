import { useContext, useEffect, useState } from 'react'
import { getUniqueListBy } from '../helpers/getUniqueListBy'
import { CartStoreContext } from '../store/cartStore'
import { isServer } from '../helpers/isServer'
import { triggerViewItemList } from '../lib/analytics/events'

export const useGetProducts = ({ data, CSObject, filters, sortOrder }) => {
  const { fetchProducts } = useContext(CartStoreContext)
  const [page, setPage] = useState(0)
  const [loadMoreLoading, setLoadMoreLoading] = useState(false)

  const [products, setProducts] = useState(data?.products ?? [])
  const [productCount, setProductCount] = useState(data?.productCount ?? [])
  // We want the server to get the server generated products. But on client we want to update.
  const [loading, setLoading] = useState(isServer ? false : true)

  const fetch = async () => {
    if (data?.categoryId) {
      setLoading(true)
      setLoadMoreLoading(true)
      const productsData = await fetchProducts({
        categories: [data?.categoryId],
        skip: page * process.env.NEXT_PUBLIC_CENTRA_PRODUCT_LIMIT,
        filters,
        sortOrder,
      })

      if (page === 0) {
        setProductCount(productsData?.productCount ?? [])
        setProducts(productsData.products)
      } else {
        setProducts(
          getUniqueListBy({
            list: [...products, ...productsData.products],
            key: 'product',
          }),
        )
      }
      setLoadMoreLoading(false)
      triggerViewItemList({
        itemListId: data?.uri ?? 'category_grid',
        itemListName: data?.categoryData?.name?.toString() ?? 'Category Grid',
        items: products?.map((p) => {
          const item = p?.items[0] ? p?.items[0] : {}
          return { ...item, product: p }
        }),
        CSObject,
      })
      setLoading(false)
    }
  }

  useEffect(() => {
    if (CSObject) {
      if (data?.products) {
        fetch()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.uri,
    page,
    CSObject?.location?.country,
    CSObject?.location?.language?.language,
    JSON.stringify(filters ?? ''),
    JSON.stringify(sortOrder ?? ''),
  ])

  return [products, productCount, loadMoreLoading, page, setPage, loading]
}
