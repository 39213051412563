import { BreadcrumbsSharable } from '../sharable/breadcrumbs/BreadcrumbsSharable'
import classNames from 'classnames'
import { sbEditable } from '@storyblok/storyblok-editable'

export const Breadcrumbs = ({ data, blok }) => {
  return (
    <div {...sbEditable(blok)} className={classNames('w-full')}>
      <BreadcrumbsSharable data={data} blok={blok} />
    </div>
  )
}
