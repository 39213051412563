import { useEffect } from 'react'

const id = 'nested-link-info-div'
export const useCheckNestedLink = ({ blok, preview, isLink }) => {
  useEffect(() => {
    if (blok.parentIsLink && preview && isLink) {
      const info = `Blok ${blok?.component} is nested inside a link. This is bad for SEO. The nested link will have a red border (only visible in preview-mode)`
      let div = window.document.getElementById(id)
      if (!div) {
        div = window.document.createElement('div')
        div.id = id
        // TODO style in tailwind? Cant fint tailwind classes? weeeeird 🫠
        div.classList.add('vt-nested-link-preview-div')
        let text = window.document.createTextNode(info)
        div.appendChild(text)
        window.document.body.insertBefore(div, window.document.body.firstChild)
      }
    }
    return () => {
      if (window.document.getElementById(id)) {
        let div = document.getElementById(id)
        let parent = div.parentNode
        parent.removeChild(div)
      }
    }
  }, [blok?.parentIsLink])

  const style =
    blok.parentIsLink && preview && isLink ? { border: '3px solid red' } : {}
  return [style]
}
