import { Fragment, useState } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import { Button } from '../atoms/Button'
import DynamicComponent from '../DynamicComponent'
import { XIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { getFontWeight } from '../../helpers/getFontWeight'
import { getFontSize } from '../../helpers/getFontSize'
import { getLineHeight } from '../../helpers/getLineHeight'
import { getTextAlign } from '../../helpers/getTextAlign'
import { getText } from '../../helpers/getText'

export const Modal = ({ blok, data }) => {
  const [show, setShow] = useState(false)

  return (
    <Fragment>
      {blok?.variant === 'button' ? (
        <Button
          onClick={() => {
            setShow(true)
          }}
        >
          {blok?.open_title ? getText(blok?.open_title, data) : 'Show'}
        </Button>
      ) : (
        <p
          onClick={() => {
            setShow(true)
          }}
          style={{
            color: blok?.link_text_style?.[0]?.text_color
              ? blok?.link_text_style?.[0]?.text_color
              : 'black',
          }}
          className={classNames(
            'underline cursor-pointer',
            getFontWeight(blok?.link_text_style?.[0]?.font_weight),
            getFontSize(blok?.link_text_style?.[0]?.font_size),
            getLineHeight(blok?.link_text_style?.[0]?.line_height),
            getTextAlign(blok?.link_text_style?.[0]?.text_align),
          )}
        >
          {blok?.open_title ? getText(blok?.open_title, data) : 'Show'}
        </p>
      )}
      <Transition show={show} as={Fragment}>
        <Dialog
          style={{ zIndex: 999 }}
          as="div"
          className="fixed inset-0 overflow-y-auto"
          onClose={() => {}}
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-50 ">
            <div
              className={'h-full w-full flex justify-end items-start '}
              onClick={() => {
                setShow(false)
              }}
            />
          </Dialog.Overlay>
          <button
            style={{
              color: blok?.close_icon_color ? blok?.close_icon_color : 'black',
              backgroundColor: blok?.close_icon_background_color
                ? blok?.close_icon_background_color
                : 'white',
            }}
            type="button"
            className="z-[999] rounded-full absolute top-1 right-1 p-1 lg:right-2 lg:top-2 lg:p-2 text-black hover:text-gray-500 outline-none"
            onClick={() => setShow(false)}
          >
            <span className="sr-only">Close panel</span>
            <XIcon className="h-6 w-6 lg:h-7 lg:w-7" aria-hidden="true" />
          </button>
          <div className="min-h-screen flex flex-col justify-center items-center px-3 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className=" w-full overflow-hidden  transition-all transform relative">
                {blok.bloks?.map((nestedBlok, index) => (
                  <DynamicComponent key={index} blok={nestedBlok} data={data} />
                ))}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </Fragment>
  )
}
