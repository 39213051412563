import classNames from 'classnames'

export const Checkbox = ({
  w = 'w-6',
  h = 'h-6',
  borderColor = 'lightGrey',
  selectedBackgroundColor = 'lightGrey',
  selectedColor = 'black',
  backgroundColor,
  checked,
  onChange,
}) => {
  return (
    <div
      style={{
        borderColor: borderColor,
        borderWidth: '1px',
        backgroundColor: backgroundColor,
      }}
      className={classNames(
        'cursor-pointer border flex justify-center items-center',
        w,
        h,
      )}
      onClick={onChange}
    >
      {checked && (
        <p
          className={'w-full h-full flex justify-center items-center'}
          style={{
            backgroundColor: selectedBackgroundColor,
            color: selectedColor,
          }}
        >
          <svg viewBox="0 0 100 100" width="80%" height="80%">
            <line
              x1="0"
              y1="0"
              x2="100"
              y2="100"
              stroke={selectedColor}
              strokeWidth="4"
            />
            <line
              x1="0"
              y1="100"
              x2="100"
              y2="0"
              stroke={selectedColor}
              strokeWidth="4"
            />
          </svg>
        </p>
      )}
    </div>
  )
}
