import { CartStoreContext } from '../../store/cartStore'
import { isServer } from '../../helpers/isServer'
import { fixUri } from '../../helpers/needToFetchCentraData'
import { GeneralError } from '../atoms/GeneralError'
import { ClientOnly } from '../atoms/ClientOnly'
import { Loader } from '../sharable/loader/Loader'
import { useContext, useEffect, useState } from 'react'
import { MainProductCard } from '../sharable/productcards/MainProductCard'

export const StandaloneProductCard = ({ blok, data, preview }) => {
  const { fetchProductFromUri, CSObject } = useContext(CartStoreContext)
  // We want the server to get the server generated products. But on client we want to update.
  const [loading, setLoading] = useState(isServer ? false : true)
  const [product, setProduct] = useState(
    data?.standalone_product_card?.[blok._uid]?.product,
  )

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      const p = await fetchProductFromUri({
        uri: fixUri(blok.override_product_uri),
      })
      setProduct(p)
      setLoading(false)
    }
    if (blok.override_product_uri) {
      fetch()
    }
  }, [blok.override_product_uri, fetchProductFromUri])

  if (!product && preview && !loading) {
    return (
      <GeneralError blok={blok}>
        No product found. Check the URI and that the product is available in
        Centra.
      </GeneralError>
    )
  }

  if (!product) {
    return <></>
  }

  return (
    <div className={'relative'}>
      <ClientOnly>{loading && <Loader />}</ClientOnly>
      <MainProductCard
        product={product}
        CSObject={CSObject}
        blok={blok}
        data={data}
      />
    </div>
  )
}
