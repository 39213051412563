import { ComponentPlaceholder } from './atoms/ComponentPlaceholder'
import { allowedComponents } from '../helpers/allowedComponents'
import { GeneralError } from './atoms/GeneralError'

const DynamicComponent = ({ children, blok, data, preview }) => {
  const Components = allowedComponents.default_page

  if (typeof Components[blok.component] !== 'undefined') {
    const Component = Components[blok.component]
    return (
      <Component blok={blok} data={data} preview={preview}>
        {children}
      </Component>
    )
  }

  // If user didn't add real path in storyblok
  if (
    preview &&
    (blok.component === 'custom_product_page' ||
      blok.component === 'custom_category_page')
  ) {
    return (
      <GeneralError>
        You need to add the <b>URI in Real Path</b> under the <b>config tab</b>{' '}
        on this page.
        <br />
        <br />
        If your URL is https://ventanas95.com/product/gul-byxa then the uri
        would be: <b>product/gul-byxa</b>. If you are creating a custom category
        the URI would probably be <b>category/gul-byxa</b> instead.
      </GeneralError>
    )
  }

  return <ComponentPlaceholder componentName={blok.component} />
}

export default DynamicComponent
