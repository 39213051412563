import { CartStoreContext } from '../../../store/cartStore'
import { useContext } from 'react'
import { observer } from 'mobx-react'
import { Upsell } from '../../sharable/upsell/Upsell'
import { getText } from '../../../helpers/getText'
import { useRelatedProductsFetcher } from '../../../hooks/useRelatedProductsFetcher'
import { getBorderRadius } from '../../../helpers/getBorderRadius'
import { ProductsDataStoreContext } from '../../../store/productsDataStore'

export const UpsellCart = observer(({ blok, data, onAdd }) => {
  const type = blok?.type || 'cart'
  const { CSObject } = useContext(CartStoreContext)
  const { productPageProduct } = useContext(ProductsDataStoreContext)

  if (type === 'cart') {
    return (
      <RelatedProductsFetcher
        items={CSObject?.selection?.items}
        blok={blok}
        data={data}
        onAdd={() => {
          if (data?.settingsData?.add_to_cart_animation === 'flyout') {
            onAdd()
          }
        }}
      />
    )
  }

  if (type === 'product') {
    const rounded = getBorderRadius(blok?.image_border_radius)
    const upsell = +blok?.upsell_count > 0 ? +blok?.upsell_count : 0
    const product = productPageProduct

    return (
      <>
        {[...Array(upsell).keys()].map((_, index) => {
          return (
            <Upsell
              addedToCartTitle={getText(
                data?.settingsData?.added_to_cart_message,
                data,
              )}
              buttonTitle={getText(
                data?.settingsData?.add_to_cart_quick_add_button_title,
                data,
              )}
              pickSizeTitle={getText(
                data?.settingsData?.pick_a_size_title,
                data,
              )}
              outOfStockTitle={getText(
                data?.settingsData?.out_of_stock_title,
                data,
              )}
              sizePickerDesign={blok?.size_picker_design}
              product={
                product?.relatedProducts?.filter(
                  (v) => v.relation === 'standard',
                )?.[index]
              }
              imageClass={rounded}
              key={index}
              buttonVariant={data?.settingsData?.quick_add_button_variant}
            />
          )
        })}
      </>
    )
  }

  return <div />
})

const RelatedProductsFetcher = ({ items = [], blok, data, onAdd }) => {
  const [products] = useRelatedProductsFetcher(items)
  const rounded = getBorderRadius(blok?.image_border_radius)
  return (
    <div>
      {products && products.length > 0 && (
        <div className={'border-t w-full'}>
          {products.map((product, index) => {
            return (
              <Upsell
                key={index}
                addedToCartTitle={getText(
                  data?.settingsData?.added_to_cart_message,
                  data,
                )}
                buttonTitle={getText(
                  data?.settingsData?.add_to_cart_quick_add_button_title,
                  data,
                )}
                pickSizeTitle={getText(
                  data?.settingsData?.pick_a_size_title,
                  data,
                )}
                outOfStockTitle={getText(
                  data?.settingsData?.out_of_stock_title,
                  data,
                )}
                sizePickerDesign={blok?.size_picker_design}
                product={product}
                onAdd={onAdd}
                buttonVariant={data?.settingsData?.quick_add_button_variant}
                imageClass={rounded}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}
