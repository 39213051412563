import { sbEditable } from '@storyblok/storyblok-editable'

export const Spacer = ({ blok }) => {
  return (
    <div
      {...sbEditable(blok)}
      style={{
        width: '100%',
        height: blok?.height ? blok?.height + 'px' : '0px',
        backgroundColor: blok?.background_color,
      }}
    />
  )
}
