import Image from 'next/future/image'
import { getImage } from '../../helpers/getImage'
import Link from 'next/link'
import { getImageAlt } from '../../helpers/getImageAlt'
import { getStoryblokImageSize } from '../../helpers/getStoryblokImageSize'
import { useCheckNestedLink } from '../../hooks/useCheckNestedLink'
import { getHref } from '../../helpers/getHref'

export function ImageComponent({ data, blok, preview }) {
  const url = blok?.image?.filename
  const href = getHref(blok?.link)
  const [isNestedStyle] = useCheckNestedLink({
    blok,
    preview,
    isLink: href !== '#',
  })

  const { width, height } = getStoryblokImageSize(url)
  const maxWidth = blok?.max_width ? blok?.max_width + 'px' : '100%'

  const image = (
    <Image
      src={getImage(blok?.image?.filename)}
      alt={getImageAlt({
        alt: blok?.image?.alt,
        image: getImage(blok?.image?.filename),
      })}
      width={width}
      height={height}
      style={{
        maxWidth: maxWidth,
        maxHeight: '100%',
        width: 'auto',
        height: 'auto',
        ...isNestedStyle,
      }}
      priority={data.aboveFold}
    />
  )

  if (href !== '#') {
    return (
      <Link href={href}>
        <a>{image}</a>
      </Link>
    )
  }

  return image
}
