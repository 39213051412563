import { ProductsRowSharable } from '../sharable/productsRow/ProductsRowSharable'
import { observer } from 'mobx-react'
import { useContext, useEffect, useState } from 'react'
import { CartStoreContext } from '../../store/cartStore'
import { fixUri } from '../../helpers/needToFetchCentraData'

export const RelatedProducts = observer(({ blok, data, preview }) => {
  const { CSObject, fetchProductFromUri } = useContext(CartStoreContext)
  const [loading, setLoading] = useState(false)
  const [product, setProduct] = useState(data?.product)

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)
      const productData = await fetchProductFromUri({
        uri: fixUri(data?.uri),
      })
      setProduct(productData)
      setLoading(false)
    }
    if (blok?.in_view === 'product' && CSObject) {
      fetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    CSObject?.location?.country,
    CSObject?.location?.language?.language,
    data?.uri,
  ])

  if (blok?.in_view === 'product') {
    const products = product?.relatedProducts?.filter(
      (v) => v.relation === 'standard',
    )
    return (
      <>
        {products && products?.length > 0 ? (
          <ProductsRowSharable
            blok={blok}
            loading={loading}
            products={products}
            CSObject={CSObject}
            preview={preview}
            data={data}
          />
        ) : (
          <div />
        )}
      </>
    )
  } else if (blok?.in_view === 'checkout') {
    return CSObject?.selection?.items ? (
      <RelatedProductsFetcher
        items={CSObject?.selection?.items}
        blok={blok}
        data={data}
      />
    ) : (
      <div />
    )
  }

  return <div />
})

const RelatedProductsFetcher = ({ items = [], blok, data }) => {
  const [products, setProducts] = useState([])
  const { fetchProduct, CSObject } = useContext(CartStoreContext)
  useEffect(() => {
    const get = async () => {
      let relatedProducts = []

      const temp = []
      for (const item of items) {
        if (item?.product?.product) {
          const response = await fetchProduct(item?.product?.product)
          if (response?.product?.relatedProducts.length > 0) {
            temp.push(response?.product?.relatedProducts)
          }
        }
      }
      while (relatedProducts.length < 4) {
        if (temp.length === 0) {
          break
        }

        if (temp.length === 1) {
          relatedProducts.push(...temp[0])
          temp.pop()
        } else {
          const rps = temp.pop()
          if (rps.length > 0) {
            relatedProducts.push(rps[0])
          }
        }
      }

      if (relatedProducts.length > 2) {
        relatedProducts.splice(2, relatedProducts.length - 1)
      }

      const ids = items.map((i) => i.product.uri)
      // remove duplicates and all ready added products in cart
      // relatedProducts = relatedProducts.filter((p) => !ids.includes(p.uri))

      setProducts(relatedProducts)
    }
    get()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  // Track view items list when in viewport
  // const [ref] = useOnScreen(() => {
  //   console.log(products)
  //   triggerViewItemList({
  //     products,
  //     item_list_id: 'related-products-checkout',
  //     item_list_name: 'Related Products Checkout',
  //   })
  // })

  return (
    <div>
      {products && products.length > 0 && (
        <div className={'border-t w-full'}>
          {/* Desktop */}
          <ProductsRowSharable
            blok={blok}
            hideTitle={true}
            products={products}
            CSObject={CSObject}
            data={data}
          />
        </div>
      )}
    </div>
  )
}
