import { CartStoreContext } from '../store/cartStore'
import { useRouter } from 'next/router'
import { updateProduct } from '../helpers/updateProduct'
import { useContext, useEffect, useState } from 'react'

export const useUpdateProduct = (initialProduct, CSObject) => {
  const { fetchProductSilent } = useContext(CartStoreContext)
  const [product, setProduct] = useState(initialProduct)
  const [updated, setUpdated] = useState(false)
  const router = useRouter()

  const fetch = async () => {
    setUpdated(false)
    const p = await fetchProductSilent(initialProduct?.product)
    if (p) {
      setProduct(
        updateProduct({ oldProduct: initialProduct, newProduct: p?.product }),
      )
      setUpdated(true)
    } else {
      await router.replace('/404')
    }
  }

  useEffect(() => {
    if (CSObject) {
      fetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    initialProduct.product,
    CSObject?.location?.country,
    CSObject?.location?.language?.language,
  ])

  return [product, updated]
}
