import { getHref } from '../../helpers/getHref'
import { Button } from '../atoms/Button'
import Link from 'next/link'
import { sbEditable } from '@storyblok/storyblok-editable'
import { getText } from '../../helpers/getText'
import { useCheckNestedLink } from '../../hooks/useCheckNestedLink'

export const ButtonComponent = ({ data, blok, preview }) => {
  const url = getHref(blok?.link)
  const [isNestedStyle] = useCheckNestedLink({ blok, preview, isLink: url })

  if (url) {
    return (
      <Link {...sbEditable(blok)} href={url}>
        <a style={{ ...isNestedStyle }}>
          <PreparedButton blok={blok} data={data} />
        </a>
      </Link>
    )
  }

  return (
    <div>
      <PreparedButton blok={blok} data={data} />
    </div>
  )
}

const PreparedButton = ({ blok, data }) => {
  const title = blok?.title ? getText(blok?.title, data) : 'Title here'

  return (
    <Button {...sbEditable(blok)} variant={blok?.style}>
      {title}
    </Button>
  )
}
