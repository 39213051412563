import { useContext, useEffect, useState } from 'react'
import { getUniqueListBy } from '../helpers/getUniqueListBy'
import { useRouter } from 'next/router'
import { CartStoreContext } from '../store/cartStore'

export const useSearch = () => {
  const { fetchProducts } = useContext(CartStoreContext)
  const [page, setPage] = useState(0)
  const [searchLoading, setSearchLoading] = useState(true)
  const [loadMoreLoading, setLoadMoreLoading] = useState(false)
  const [productCount, setProductCount] = useState([])
  const [products, setProducts] = useState([])
  const router = useRouter()

  useEffect(() => {
    const search = async () => {
      setSearchLoading(true)
      setLoadMoreLoading(true)

      const d = await fetchProducts({
        search: router.query.q ? router.query.q : '',
        skip: page * process.env.NEXT_PUBLIC_CENTRA_PRODUCT_LIMIT,
      })
      setProductCount(d?.productCount)
      if (page === 0) {
        setProducts(d.products)
      } else {
        setProducts(
          getUniqueListBy({
            list: [...products, ...d?.products],
            key: 'product',
          }),
        )
      }
      setSearchLoading(false)
      setLoadMoreLoading(false)
    }
    search()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.query?.q, page])

  return [
    products,
    productCount,
    searchLoading,
    loadMoreLoading,
    page,
    setPage,
    router,
  ]
}
