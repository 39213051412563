export const setThirdPartyConsent = ({ consent, preview }) => {
  if (preview) console.log('Set consent', consent)
  /* Update tracking consent */

  /* FB PIXEL */
  if (typeof fbq === 'function') {
    if (preview) console.log('setting consent fb')
    if (consent?.includes('marketing')) {
      fbq('consent', 'grant')
    }
  }

  /* GTM */
  if (typeof gtag === 'function') {
    if (preview) console.log('setting consent gtm')

    if (consent?.includes('marketing'))
      gtag('consent', 'update', {
        ad_storage: 'granted',
      })

    if (consent?.includes('analytics'))
      gtag('consent', 'update', {
        analytics_storage: 'granted',
      })
  }
}
