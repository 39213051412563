import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Dropdown } from '../../Dropdown'
import { getMappedSizeName } from '../../../helpers/getMappedSizeName'
import { isItemInStock } from '../../../helpers/isItemInStock'
import { getText } from '../../../helpers/getText'

export const SizePicker = ({
  product,
  design,
  onSizeChange = () => {},
  onSizeClicked = () => {},
  isOutOfStockMode = false,
  className,
  yTitle,
}) => {
  const [size, setSize] = useState({ x: 0, y: 0 })

  useEffect(() => {
    for (const item of product?.items) {
      if (isItemInStock(item.stock)) {
        setSize({ x: item?.itemTableX, y: item?.itemTableY })
        if (design === 'button') {
          onSizeClicked({ x: item?.itemTableX, y: item?.itemTableY })
        }
        onSizeChange({ x: item?.itemTableX, y: item?.itemTableY })
        break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product?.product, product?.defaultLocalizedChart])
  useEffect(() => {
    if (design === 'dropdown') {
      onSizeChange(size)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size])

  const isXSizeInStock = (i) => {
    const item = product?.items.find(
      (item) => item.itemTableX === i && isItemInStock(item.stock),
    )

    return item ? false : true
  }
  const isYSizeInStock = (i) => {
    const item = product?.items.find(
      (item) =>
        item.itemTableX === size.x &&
        item.itemTableY === i &&
        isItemInStock(item.stock),
    )

    return item ? false : true
  }

  return (
    <div className={classNames('flex flex-col space-y-2', className)}>
      {product?.itemTable?.x.length > 1 && (
        <>
          {design === 'dropdown' && (
            <Dropdown
              isOutOfStockMode={isOutOfStockMode}
              maxHeight={'250px'}
              items={product?.itemTable?.x.map((i, index) => {
                return {
                  value: getMappedSizeName({
                    size: i,
                    tableMappings: product?.tableMappings,
                    defaultLocalizedChart: product?.defaultLocalizedChart,
                  }),
                  disable: isXSizeInStock(index),
                }
              })}
              initialValue={{
                value: getMappedSizeName({
                  size: product.itemTable?.x?.[size?.x],
                  tableMappings: product?.tableMappings,
                  defaultLocalizedChart: product?.defaultLocalizedChart,
                }),
              }}
              onSelected={(i) => {
                setSize({ x: i, y: size.y })
                onSizeClicked({ x: i, y: size.y })
              }}
            />
          )}

          {design === 'buttons' && (
            <Picker
              isOutOfStockMode={isOutOfStockMode}
              items={product?.itemTable?.x.map((i, index) => {
                return {
                  value: getMappedSizeName({
                    size: i,
                    tableMappings: product?.tableMappings,
                    defaultLocalizedChart: product?.defaultLocalizedChart,
                  }),
                  disable: isXSizeInStock(index),
                }
              })}
              initialValue={size.x}
              onSelected={(i) => {
                setSize({ x: i, y: size.y })
                onSizeClicked({ x: i, y: size.y })
                onSizeChange({ x: i, y: size.y })
              }}
            />
          )}
        </>
      )}

      {product?.itemTable?.y.length > 1 && (
        <>
          {design === 'dropdown' && (
            <>
              {yTitle && <p className={'mb-2 text-sm font-bold'}>{yTitle}</p>}
              <Dropdown
                isOutOfStockMode={isOutOfStockMode}
                maxHeight={'250px'}
                items={product?.itemTable?.y.map((i, index) => {
                  return {
                    value: getMappedSizeName({
                      size: i,
                      tableMappings: product?.tableMappings,
                      defaultLocalizedChart: product?.defaultLocalizedChart,
                      sizeKey: 'y',
                    }),
                    disable: isYSizeInStock(index),
                  }
                })}
                initialValue={{
                  value: getMappedSizeName({
                    size: product.itemTable?.y?.[size?.y],
                    tableMappings: product?.tableMappings,
                    defaultLocalizedChart: product?.defaultLocalizedChart,
                    sizeKey: 'y',
                  }),
                }}
                onSelected={(i) => {
                  setSize({ x: size.x, y: i })
                  onSizeClicked({ x: i, y: size.y })
                }}
              />
            </>
          )}
          {design === 'buttons' && (
            <>
              {yTitle && <p className={'mb-2 text-sm font-bold'}>{yTitle}</p>}
              <Picker
                isOutOfStockMode={isOutOfStockMode}
                items={product?.itemTable?.y.map((i, index) => {
                  return {
                    value: getMappedSizeName({
                      size: i,
                      tableMappings: product?.tableMappings,
                      defaultLocalizedChart: product?.defaultLocalizedChart,
                      sizeKey: 'y',
                    }),
                    disable: isYSizeInStock(index),
                  }
                })}
                initialValue={size.y}
                onSelected={(i) => {
                  setSize({ x: size.x, y: i })
                  onSizeClicked({ x: i, y: size.y })
                  onSizeChange({ x: i, y: size.y })
                }}
              />
            </>
          )}
        </>
      )}
    </div>
  )
}

const Picker = ({ items, onSelected, isOutOfStockMode, initialValue }) => {
  const [selected, setSelected] = useState(0)

  useEffect(() => {
    setSelected(initialValue ?? 0)
  }, [initialValue])

  return (
    <div className={'grid grid-cols-6 gap-3'}>
      {items.map((item, index) => {
        return (
          <div
            onClick={() => {
              if (!item.disable || isOutOfStockMode) {
                setSelected(index)
                onSelected(index)
              }
            }}
            className={classNames(
              'w-full h-12 border flex justify-center items-center cursor-pointer text-sm text-center',
              'lg:hover:bg-black lg:hover:text-white',
              selected === index &&
                (!item.disable || isOutOfStockMode) &&
                'bg-black text-white',
              item.disable && !isOutOfStockMode && 'opacity-50 line-through',
            )}
            key={index}
          >
            {item.value}
          </div>
        )
      })}
    </div>
  )
}
