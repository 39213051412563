const inStockValues = ['preorder', 'infinite', 'yes', 'few']
const inPreOrderValues = ['preorder', 'infinite']
const isFewValues = ['few']

export const isPreOrder = (stock) => {
  if (inPreOrderValues.includes(stock)) {
    return true
  }

  return false
}

export const isFewLeft = (stock, threshold) => {
  if (!threshold && isFewValues.includes(stock)) {
    return true
  } else if (threshold >= stock) {
    return true
  }

  return false
}

export const isItemInStock = (stock) => {
  // First check string values
  if (inStockValues.includes(stock)) {
    return true
  }
  // If Centra settings Stock visible === yes check if stock is bigger than 0
  // Also check when the maximum visible limit is set.
  // It will return for example 15+ as string. So if we parse as int first we
  // Will get back 15 🖖🤘🫶
  const s = parseInt(stock)
  if (s > 0) {
    return true
  }

  return false
}
