import { observer } from 'mobx-react'
import { SizePicker } from '../../../sharable/sizePicker/SizePicker'
import classNames from 'classnames'
import { useContext } from 'react'
import { ProductsDataStoreContext } from '../../../../store/productsDataStore'

export const ProductPageSizePicker = observer(({ data, blok }) => {
  const { setSize, productPageProduct } = useContext(ProductsDataStoreContext)
  const product = productPageProduct

  return (
    <>
      {product?.itemTable?.x?.length > 1 && (
        <div className={'flex flex-col'}>
          <SizePicker
            product={product}
            design={blok?.size_picker_design}
            className={classNames(
              'block',
              blok?.size_picker_design_desktop ? 'lg:hidden' : '',
            )}
            onSizeChange={(size) => {
              setSize(size, product)
            }}
          />
          {blok?.size_picker_design_desktop && (
            <SizePicker
              product={product}
              design={blok?.size_picker_design_desktop}
              className={'hidden lg:block'}
              onSizeChange={(size) => {
                setSize(size, product)
              }}
            />
          )}
        </div>
      )}
    </>
  )
})
