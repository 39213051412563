import { useState } from 'react'
import classNames from 'classnames'
import { MinusSmIcon, PlusSmIcon } from '@heroicons/react/outline'
import Link from 'next/link'
import Storyblok from '../../../lib/storyblok/storyblok'
import { getFontSize } from '../../../helpers/getFontSize'
import { getText } from '../../../helpers/getText'
import { useCheckNestedLink } from '../../../hooks/useCheckNestedLink'
import { getHref } from '../../../helpers/getHref'

export const FooterColumn = ({ blok, data, preview }) => {
  const alwaysExpanded = blok?.mobile_display_style === 'always_expanded'
  const textColor = blok?.text_color ? blok.text_color : 'black'
  const [show, setShow] = useState(alwaysExpanded ? true : false)

  return (
    <div className="flex-1 w-full lg:w-fit">
      <div
        style={{ color: textColor }}
        className={classNames(
          !alwaysExpanded ? 'cursor-pointer' : '',
          'flex justify-between items-center py-4',
          'lg:cursor-auto lg:p-0',
        )}
        onClick={() => {
          if (alwaysExpanded) {
            return
          }
          setShow(!show)
        }}
      >
        <h4
          className={classNames(
            'font-bold lg:mb-3 font-title',
            getFontSize(blok?.font_size),
          )}
        >
          {getText(blok?.title, data)}
        </h4>
        {!alwaysExpanded && !show && (
          <PlusSmIcon className="h-6 w-6 lg:hidden" aria-hidden="true" />
        )}
        {!alwaysExpanded && show && (
          <MinusSmIcon className="h-6 w-6 lg:hidden" aria-hidden="true" />
        )}
      </div>
      <div
        className={classNames(
          'flex flex-col flex-1',
          show || alwaysExpanded ? '' : 'hidden lg:block',
        )}
      >
        <div className={'py-4 flex flex-col lg:py-0'}>
          <div className={'mb-4 flex flex-col space-y-4 lg:space-y-1'}>
            {blok?.bloks?.length > 0 &&
              blok?.bloks?.map((columnBlok, index) => {
                return (
                  <ColumnBlok
                    key={index}
                    blok={{ ...columnBlok, parentIsLink: blok?.parentIsLink }}
                    index={index}
                    textColor={textColor}
                    data={data}
                    preview={preview}
                  />
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}

const ColumnBlok = ({ blok, textColor, index, data, preview }) => {
  const [isNestedStyle] = useCheckNestedLink({
    blok,
    preview,
    isLink: blok?.component === 'footer_link',
  })

  const linkClassName = 'underline-offset-4 hover:underline '

  if (blok?.component === 'footer_link') {
    return (
      <Link key={index} href={getHref(blok?.href)}>
        <a
          style={{ color: textColor, ...isNestedStyle }}
          className={classNames(linkClassName, getFontSize(blok?.font_size))}
        >
          {getText(blok.title, data)}
        </a>
      </Link>
    )
  } else if (blok?.component === 'footer_paragraph') {
    return (
      <div
        key={index}
        style={{ color: textColor }}
        dangerouslySetInnerHTML={{
          __html: getText(Storyblok.richTextResolver.render(blok.text), data),
        }}
      />
    )
  }
}
