import Script from 'next/script'
import { useEffect } from 'react'

export const FindifyLoadScript = ({ data }) => {
  const findify = data?.settingsData?.findify_mapping?.find(
    (c) => c.language_code === data.locale,
  )

  return (
    <>
      {findify ? (
        <>
          <Script
            id={'vt-findify-script'}
            src={`//${findify?.findify_asset_url}`}
          />
        </>
      ) : null}
    </>
  )
}
