import classNames from 'classnames'
import { MainProductCard } from '../productcards/MainProductCard'
import { sbEditable } from '@storyblok/storyblok-editable'
import { GeneralError } from '../../atoms/GeneralError'
import { Loader } from '../loader/Loader'
import { ClientOnly } from '../../atoms/ClientOnly'

export const ProductsRowSharable = ({
  blok,
  products,
  CSObject,
  data,
  preview,
  loading = false,
}) => {
  const getProductCard = ({ product, blok, preview }) => {
    if (preview && !product) {
      return <GeneralError>No product found on URI</GeneralError>
    }

    if (!product) {
      return false
    }

    if (blok?.product_card?.[0]?.component) {
      return (
        <MainProductCard
          product={product}
          CSObject={CSObject}
          blok={blok}
          data={data}
        />
      )
    }

    return false
  }

  const getSpace = () => {
    const size = blok?.product_card?.[0]?.space_between ?? 's'
    if (size === 'none') {
      return 'space-x-0 '
    } else if (size === 's') {
      return 'space-x-3 '
    } else if (size === 'm') {
      return 'space-x-6 '
    } else if (size === 'l') {
      return 'space-x-9'
    }

    return 'space-x-3 '
  }

  if (products.length > 4) {
    products.splice(4, products.length - 1)
  }

  return (
    <div
      {...sbEditable(blok)}
      className={'flex justify-center w-full relative'}
    >
      <ClientOnly>{loading && <Loader />}</ClientOnly>
      {products?.length > 0 && (
        <div className={classNames('w-full')}>
          {getProductCard({ blok, product: products?.[0] }) ? (
            <div>
              {/* Mobile */}
              <div
                className={classNames(
                  'md:hidden',
                  'scrollbar-hidden',
                  'flex flex-row flex-nowrap overflow-x-scroll overscroll-x-contain snap-x snap-mandatory',
                  getSpace(),
                )}
              >
                {products?.map((product, index) => {
                  const productCard = getProductCard({
                    blok,
                    product,
                    preview,
                  })
                  if (productCard) {
                    return (
                      <div
                        style={{ width: '75vw' }}
                        className={'flex-shrink-0 relative snap-start'}
                        key={index}
                      >
                        <div className={classNames('w-full')}>
                          {productCard}
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
              {/*products?.length > 1 && (
                  <div className={'md:hidden flex space-x-2 justify-center'}>
                    {products.map((_, index) => {
                      return (
                        <Dot key={index} selected={index === scrollPosition} />
                      )
                    })}
                  </div>
                ) */}

              {/* Desktop */}
              <div
                className={classNames('hidden md:flex flex-row', getSpace())}
              >
                {products?.map((product, index) => {
                  const productCard = getProductCard({
                    blok,
                    product,
                    preview,
                  })
                  if (productCard) {
                    return (
                      <div className={'flex-1'} key={index}>
                        {getProductCard({ blok, product, preview })}
                      </div>
                    )
                  }
                })}

                {products.length === 1 && (
                  <>
                    <div className={'flex-1'} /> <div className={'flex-1'} />
                  </>
                )}
                {products.length === 2 && (
                  <>
                    <div className={'flex-1'} />
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="flex justify-center w-full h-32 items-center">
              Please add related products product card design blok
            </div>
          )}
        </div>
      )}
    </div>
  )
}
