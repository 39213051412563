/* For section grid and columns */
import classNames from 'classnames'
import Image from 'next/future/image'
import { getImageObjectFit } from '../helpers/getImageObjectFit'
import { getImage } from '../helpers/getImage'
import { getImageAlt } from '../helpers/getImageAlt'
import { getBorderRadius } from './getBorderRadius'

export const getBackground = ({ blok, aboveFold }) => {
  const fixForOldDesktop = () => {
    // Detta är för de som har satt background_image_object_fit_desktop bara.
    // Innan hade vi glömt att den ska ta object_fit först
    // Utan den tog bara den gamla background_image_object_fit_desktop
    // Så för att inte fucka upp de som bara har detta så gör vi så här
    // 🖖
    if (blok?.background_image_object_fit) {
      return blok?.background_image_object_fit
    }

    return blok?.background_image_object_fit_desktop
  }
  return (
    <>
      {blok?.background_image?.filename && (
        <Image
          src={getFocalImageIfExisit({
            imageFocal: blok?.image_focal,
            image: blok?.background_image,
            width: blok?.focal_image_width,
            height: blok?.focal_image_height,
          })}
          alt={getImageAlt({
            alt: blok?.background_image?.alt,
            image: getImage(blok?.background_image?.filename),
          })}
          fill
          style={{
            objectFit: getImageObjectFit(fixForOldDesktop()),
          }}
          className={classNames(
            blok?.background_image_desktop?.filename ? 'lg:hidden' : '',
            getBorderRadius(blok?.border_radius),
          )}
          priority={aboveFold}
        />
      )}
      {blok?.background_image_desktop?.filename && (
        <Image
          src={getFocalImageIfExisit({
            imageFocal: blok?.image_focal_desktop,
            image: blok?.background_image_desktop,
            width: blok?.focal_image_width_desktop,
            height: blok?.focal_image_height_desktop,
          })}
          alt={getImageAlt({
            alt: blok?.background_image_desktop?.alt,
            image: getImage(blok?.background_image_desktop?.filename),
          })}
          fill
          style={{
            objectFit: getImageObjectFit(
              blok?.background_image_object_fit_desktop
                ? blok?.background_image_object_fit_desktop
                : blok?.background_image_object_fit,
            ),
          }}
          className={classNames(
            blok?.background_image?.filename ? 'hidden lg:block' : '',
          )}
          priority={aboveFold}
        />
      )}
      {blok?.overlay_color?.length > 0 && (
        <div
          className={classNames('absolute top-0 left-0 right-0 bottom-0')}
          style={{ backgroundColor: blok?.overlay_color }}
        ></div>
      )}
    </>
  )
}

const getFocalImageIfExisit = ({ imageFocal, image, width, height }) => {
  if (width && height) {
    if (imageFocal === 'smart') {
      return `${image?.filename}/m/${width}x${height}/smart`
    } else if (image?.focus?.length && imageFocal === 'focal') {
      return `${image?.filename}/m/${width}x${height}/filters:focal(${image?.focus})`
    }
  }
  return image?.filename
}
