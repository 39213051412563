import dynamic from 'next/dynamic'
const SocialIcon = dynamic(() =>
  import('react-social-icons').then((mod) => mod.SocialIcon),
)
/* Social not takes react social fork where all not used icons are removed. So if you want to add new icon you need to find it in the orignal repo <3 */

export const Socials = ({ blok }) => {
  const textColor = blok?.color?.length > 0 ? blok.color : 'black'
  const bgColor =
    blok?.background_color?.length > 0 ? blok.background_color : 'white'

  let socials = []
  if (blok?.instagram) {
    socials.push('https://www.instagram.com/' + blok?.instagram)
  }
  if (blok?.tiktok) {
    socials.push('https://www.tiktok.com/@' + blok?.tiktok)
  }
  if (blok?.facebook) {
    socials.push('https://www.facebook.com/' + blok?.facebook)
  }
  if (blok?.youtube) {
    socials.push('https://www.youtube.com/' + blok?.youtube)
  }
  if (blok?.twitter) {
    socials.push('https://twitter.com/' + blok?.twitter)
  }
  if (blok?.pinterest) {
    socials.push('https://www.pinterest.com/' + blok?.pinterest)
  }

  return (
    <div className={'flex pb-4'} style={{ color: textColor }}>
      {socials.map((url, index) => {
        return (
          <SocialIcon
            key={index}
            className={'mr-1'}
            style={{ width: 36, height: 36 }}
            bgColor={bgColor}
            fgColor={textColor}
            url={url}
          />
        )
      })}
    </div>
  )
}
