import { useState } from 'react'
import { SearchIcon } from '@heroicons/react/outline'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import { sbEditable } from '@storyblok/storyblok-editable'
import { getText } from '../../../helpers/getText'

export const SearchButton = ({ blok, data }) => {
  const router = useRouter()
  const [show, setShow] = useState(false)
  const [showLoader, setShowLoader] = useState(true)
  const [searchString, setSearchString] = useState('')
  const blur = (e) => {
    if (e?.relatedTarget?.id !== 'searchBtn') {
      setShow(false)
    }
  }

  return (
    <div {...sbEditable(blok)} className="flex items-center ">
      <SearchIcon
        style={{ color: blok?.icon_color ? blok?.icon_color : 'black' }}
        className="flex-shrink-0 h-6 w-6 cursor-pointer"
        aria-hidden="true"
        onClick={() => {
          setShow(!show)
          setShowLoader(false)
        }}
      />

      {show && (
        <div
          className={classNames(
            'fixed top-0 z-50 left-0 right-0 w-full flex items-center ',
          )}
          style={{
            backgroundColor: data?.layoutData?.header_background_color,
            height: data?.headerHeight,
          }}
        >
          <div
            className={
              'bg-transparent w-full mx-auto px-2 flex  flex justify-between items-center'
            }
          >
            <input
              // need to be +16px
              // https://css-tricks.com/16px-or-larger-text-prevents-ios-form-zoom/
              style={{ fontSize: '16px', color: blok?.text_color || 'black' }}
              className={classNames(
                'border-0 border-gray-300 bg-transparent h-10 pl-2',
                'focus:outline-none focus:ring-0 focus:border-black',
                'w-full',
              )}
              type="search"
              name="search"
              id="headerSearchInput"
              placeholder={
                blok?.placeholder
                  ? getText(blok?.placeholder, data)
                  : 'Search here'
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setShowLoader(true)
                  router.push('/search?q=' + searchString)
                  setShow(false)
                }
              }}
              value={searchString}
              onChange={(e) => {
                setSearchString(e.target.value)
              }}
              onBlur={(e) => blur(e)}
              autoFocus
            />
            <button
              id="searchBtn"
              onClick={() => {
                setShowLoader(true)
                router.push('/search?q=' + searchString)
                setShow(false)
              }}
              className={' bg-transparent flex justify-center items-center'}
              variant={blok?.button_style}
              loading={showLoader}
            >
              {!showLoader && (
                <SearchIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                  style={{
                    color: blok?.icon_color ? blok?.icon_color : 'black',
                  }}
                />
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
