import { getFontWeight } from './getFontWeight'
import { getFontSize } from './getFontSize'
import { getLineHeight } from './getLineHeight'
import { getMargin } from './getMargin'

export const addClassesToText = (text, data) => {
  const defaultClasses = getDefaultClasses({ data })
  const classes = {
    ul: 'list-disc ml-6',
    ol: 'list-decimal ml-6',
    hr: 'my-6',
    // h1: 'font-title',
    // h2: 'font-title',
    // h3: 'font-title',
    // h4: 'font-title',
    // h5: 'font-title',
  }
  const allClasses = { ...classes, ...defaultClasses }

  for (const element in allClasses) {
    let findElement = `<${element}`
    if (typeof text === 'string') {
      text = text
        .split(findElement)
        .join(`${findElement} class='${allClasses[element]}'`)
    }
  }

  // Add font family from default classes
  const defaultStyles = getDefaultStyles({ data })
  for (const element in defaultStyles) {
    let findElement = `<${element}`
    if (typeof text === 'string') {
      text = text
        .split(findElement)
        .join(`${findElement} style="${defaultStyles[element]}"`)
    }
  }

  return text
}

const getDefaultStyles = ({ data }) => {
  const defaultElements =
    data?.layoutData?.default_rich_text_elements_style ?? []
  let defaultStyles = {}

  for (let element of defaultElements) {
    if (element?.font_family?.length > 0) {
      defaultStyles[
        element.text_type
      ] = `font-family: '${element?.font_family}'`
    }
    if (element?.text_color?.length > 0) {
      defaultStyles[element.text_type] = `color: ${element?.text_color}`
    }
  }

  return defaultStyles
}

const getDefaultClasses = ({ data }) => {
  const defaultElements =
    data?.layoutData?.default_rich_text_elements_style ?? []
  let defaultClasses = {}

  const defaultElementsDesktop =
    data?.layoutData?.default_rich_text_elements_style_desktop ?? []

  for (let element of defaultElements) {
    let classes = ''
    classes += getFontWeight(element.font_weight)
      ? ` ${getFontWeight(element.font_weight)}`
      : ''
    classes += getFontSize(element.font_size)
      ? ` ${getFontSize(element.font_size)}`
      : ''
    classes += getLineHeight(element.line_height)
      ? ` ${getLineHeight(element.line_height)}`
      : ''
    classes += getMargin({ prefix: 'mt', mobile: element.margin_top })
      ? ` ${getMargin({ prefix: 'mt', mobile: element.margin_top })}`
      : ''
    classes += getMargin({ prefix: 'mb', mobile: element.margin_bottom })
      ? ` ${getMargin({ prefix: 'mb', mobile: element.margin_bottom })}`
      : ''

    if (classes) {
      defaultClasses[element.text_type] = classes.trim()
    }
  }

  for (let element of defaultElementsDesktop) {
    let classes = ''
    classes += getFontWeight(element.font_weight)
      ? ` lg:${getFontWeight(element.font_weight)}`
      : ''
    classes += getFontSize(element.font_size)
      ? ` lg:${getFontSize(element.font_size)}`
      : ''
    classes += getLineHeight(element.line_height)
      ? ` lg:${getLineHeight(element.line_height)}`
      : ''
    classes += getMargin({ prefix: 'mt', desktop: element.margin_top })
      ? ` ${getMargin({ prefix: 'mt', desktop: element.margin_top })}`
      : ''
    classes += getMargin({ prefix: 'mb', desktop: element.margin_bottom })
      ? ` ${getMargin({ prefix: 'mb', desktop: element.margin_bottom })}`
      : ''

    if (classes) {
      defaultClasses[element.text_type] += ' ' + classes.trim()
    }
  }

  return defaultClasses
}
