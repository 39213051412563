import { YotpoProductReviews } from './YotpoProductReviews'
import { YotpoProductStars } from './YotpoProductStars'

export const Yotpo = ({ blok, data }) => {
  const type = blok?.type || 'reviews'
  if (type === 'reviews') {
    return <YotpoProductReviews blok={blok} data={data} />
  }
  if (type === 'stars') {
    return <YotpoProductStars blok={blok} data={data} />
  }

  return null
}
