import {
  getBricKAndMortar,
  getMarkets,
  getProductFromUri,
  getProductsFromCategoryUri,
} from '../lib/centra/centra'

const COMPONENTS = {
  productsRow: 'products_row',
  promoGrid: 'promo_grid',
  productPromo: 'product_promo',
  onlyMobileBloks: 'only_mobile_bloks',
  onlyDesktopBloks: 'only_desktop_bloks',
  sectionGrid: 'section_grid',
  column: 'column',
  productImages: 'product_images',
  productInfo: 'product_info',
  storeLocatorMap: 'store_locator_map',
  storeLocatorList: 'store_locator_list',
  standaloneProductCard: 'standalone_product_card',
}

/*
*   if (component.component === COMPONENTS.section) {
        await loopSection({ component, market, locale, data })
      } else
* */

const fetch = async ({ bloks, market, data, locale }) => {
  for (let component of bloks) {
    if (component.component === COMPONENTS.sectionGrid) {
      await fetch({ bloks: component.bloks, market, data })
    } else if (component.component === COMPONENTS.column) {
      await fetch({ bloks: component.bloks, market, data })
    }

    if (component.component === COMPONENTS.productsRow) {
      await fetchProductRow({ market, component, data, locale })
    } else if (component.component === COMPONENTS.promoGrid) {
      for (const column of component?.columns) {
        if (column.component === 'promo_product') {
          await fetchPromoProduct({ column, market, data, locale })
        }
      }
    } else if (component.component === COMPONENTS.productImages) {
      await fetchProduct({
        component,
        data,
        market,
        locale,
        key: COMPONENTS.productImages,
      })
    } else if (component.component === COMPONENTS.productInfo) {
      await fetchProduct({
        component,
        data,
        market,
        locale,
        key: COMPONENTS.productInfo,
      })
    } else if (component.component === COMPONENTS.standaloneProductCard) {
      await fetchProduct({
        component,
        data,
        market,
        locale,
        key: COMPONENTS.standaloneProductCard,
      })
    } else if (component.component === COMPONENTS.productPromo) {
      await fetchProductPromo({ component, data, market, locale })
    } else if (component.component === COMPONENTS.storeLocatorMap) {
      await fetchBrickAndMortar({
        component,
        data,
        key: COMPONENTS.storeLocatorMap,
      })
    } else if (component.component === COMPONENTS.storeLocatorList) {
      await fetchBrickAndMortar({
        component,
        data,
        key: COMPONENTS.storeLocatorList,
      })
    } else if (
      component.component === COMPONENTS.onlyMobileBloks ||
      component.component === COMPONENTS.onlyDesktopBloks
    ) {
      for (let innerComponent of component.bloks) {
        if (innerComponent.component === COMPONENTS.productsRow) {
          await fetchProductRow({
            market,
            component: innerComponent,
            data,
            locale,
          })
        } else if (innerComponent.component === COMPONENTS.promoGrid) {
          for (const column of innerComponent?.columns) {
            if (column.component === 'promo_product') {
              await fetchPromoProduct({ column, market, data, locale })
            }
          }
        } else if (innerComponent.component === COMPONENTS.productPromo) {
          await fetchProductPromo({
            component: innerComponent,
            data,
            market,
            locale,
          })
        }
      }
    }
  }
}

export const needToFetchCentraData = async ({ story, locale, market }) => {
  let data = {}
  if (story?.content?.body) {
    await fetch({ bloks: story.content.body, data, market, locale })
  }

  return data
}

const fetchProduct = async ({ component, market, data, locale, key }) => {
  if (component.override_product_uri?.length > 0) {
    const product = await getProductFromUri({
      uri: fixUri(component.override_product_uri),
      pricelist: market.pricelist,
      market: market.market,
      locale: locale,
    })

    data[key] = {
      ...data[key],
      [component._uid]: {
        product: product,
      },
    }
  }
}
const fetchBrickAndMortar = async ({ component, data, key }) => {
  const stores = await getBricKAndMortar()
  data[key] = {
    ...data[key],
    [component._uid]: {
      stores: stores,
    },
  }
}

const fetchProductPromo = async ({ component, market, data, locale }) => {
  const product = await getProductFromUri({
    uri: fixUri(component.product_uri),
    pricelist: market.pricelist,
    market: market.market,
    locale: locale,
  })
  data[COMPONENTS.productPromo] = {
    ...data[COMPONENTS.productPromo],
    [component._uid]: {
      product: product,
    },
  }
}

const fetchPromoProduct = async ({ column, market, data, locale }) => {
  const product = await getProductFromUri({
    uri: fixUri(column.product_uri),
    pricelist: market.pricelist,
    market: market.market,
    locale: locale,
  })
  data[COMPONENTS.promoGrid] = {
    ...data[COMPONENTS.promoGrid],
    [column._uid]: {
      product: product,
    },
  }
}
export const fetchProductRow = async ({ market, component, data, locale }) => {
  let productsData = {}

  if (component?.category_uri && component?.category_uri?.length > 0) {
    productsData = await getProductsFromCategoryUri({
      uri: fixUri(component?.category_uri),
      pricelist: market.pricelist,
      market: market.market,
      locale: locale,
    })
  } /*
    Keep if we want to add product_uris back to the products row
  else {
    const products = []
    for (const productUri of component.product_uris) {
      const product = await getProductFromUri({
        uri: fixUri(productUri.uri),
        pricelist: priceList,
        market: market,
        locale: locale,
      })
      products.push(product)
    }
    productsData.products = products
  } */

  data[COMPONENTS.productsRow] = {
    ...data[COMPONENTS.productsRow],
    [component._uid]: {
      products: productsData?.products ?? [],
    },
  }
}
export const fixUri = (uri) => {
  uri = uri.replace(process.env.NEXT_PUBLIC_SITE_URL + '/category', '')
  uri = uri.replace(process.env.NEXT_PUBLIC_SITE_URL + '/product', '')
  uri = uri.replace('category', '')
  uri = uri.replace('product', '')
  uri = uri.replace(process.env.NEXT_PUBLIC_SITE_URL, '')

  // Remove / in start and end
  while (uri.substr(-1) === '/') {
    uri = uri.substr(0, str.length - 1)
  }

  while (uri.substr(0, 1) === '/') {
    uri = uri.substr(1, uri.length - 1)
  }

  return uri
}
