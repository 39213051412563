import classNames from 'classnames'

export const Dot = ({ selected }) => {
  return (
    <div
      className={classNames(
        'w-2 h-2 rounded-full',
        selected ? 'bg-gray-700' : 'bg-gray-300',
      )}
    />
  )
}
