const classes = {
  100: 'font-thin',
  200: 'font-extralight',
  300: 'font-light',
  400: 'font-normal',
  500: 'font-medium',
  600: 'font-semibold',
  700: 'font-bold',
  800: 'font-extrabold',
  900: 'font-black',
}

export const getFontWeight = (fontWeight) => {
  if (!fontWeight || fontWeight === '' || fontWeight == null) {
    return
  }

  if (classes[fontWeight]) {
    return classes[fontWeight]
  } else {
    console.error('Error setting font weight', fontWeight)
    return false
  }
}

// For tailwind
const classesDesktop = {
  100: 'lg:font-thin',
  200: 'lg:font-extralight',
  300: 'lg:font-light',
  400: 'lg:font-normal',
  500: 'lg:font-medium',
  600: 'lg:font-semibold',
  700: 'lg:font-bold',
  800: 'lg:font-extrabold',
  900: 'lg:font-black',
}

export const getBolder = (fontWeight) => {
  if (!fontWeight || fontWeight === '' || fontWeight == null) {
    return
  }
  const keys = Object.keys(classes)
  const index = keys.indexOf(fontWeight)
  const next = classes[keys[index + 2]]
  if (next) {
    return classes[keys[index + 2]]
  } else {
    return classes[fontWeight]
  }
}
