import Head from 'next/head'
import { Fragment } from 'react'

export const MainMeta = ({ meta }) => {
  return (
    <Head>
      {/* Default meta */}
      <title key="title">{meta?.title}</title>
      {meta?.description && (
        <meta
          key="description"
          name="description"
          content={meta?.description}
        />
      )}

      {/* Open Graph */}
      {meta?.type && (
        <meta key="og:type" property="og:type" content={meta?.type} />
      )}
      <meta key="og:title" property="og:title" content={meta?.title} />
      {meta?.description && (
        <meta
          key="og:description"
          property="og:description"
          content={meta?.description}
        />
      )}
      {meta?.image && (
        <meta key="og:image" property="og:image" content={meta?.image} />
      )}
      <meta
        key="og:site_name"
        property="og:site_name"
        content={process.env.NEXT_PUBLIC_SITE_NAME}
      />
      {meta?.price && meta?.currency && (
        <>
          <meta
            key="product:price.amount"
            property="product:price.amount"
            content={meta?.price}
          />
          <meta
            key="product:price.currency"
            property="product:price.currency"
            content={meta?.currency}
          />
        </>
      )}
      {meta?.url && <meta key="og:url" property="og:url" content={meta?.url} />}
    </Head>
  )
}
