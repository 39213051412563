const sizes = {
  0: 'space-x-0',
  1: 'space-x-1',
  2: 'space-x-2',
  3: 'space-x-3',
  6: 'space-x-6',
  9: 'space-x-9',
  12: 'space-x-12',
  14: 'space-x-14',
  16: 'space-x-16',
  20: 'space-x-20',
  24: 'space-x-24',
  28: 'space-x-28',
  32: 'space-x-32',
  36: 'space-x-36',
  40: 'space-x-40',
  44: 'space-x-44',
  48: 'space-x-48',
  52: 'space-x-52',
  56: 'space-x-56',
  60: 'space-x-60',
  64: 'space-x-64',
  72: 'space-x-72',
  80: 'space-x-80',
  96: 'space-x-96',
}

const sizesLG = {
  0: 'lg:space-x-0',
  1: 'lg:space-x-1',
  2: 'lg:space-x-2',
  3: 'lg:space-x-3',
  6: 'lg:space-x-6',
  9: 'lg:space-x-9',
  12: 'lg:space-x-12',
  14: 'lg:space-x-14',
  16: 'lg:space-x-16',
  20: 'lg:space-x-20',
  24: 'lg:space-x-24',
  28: 'lg:space-x-28',
  32: 'lg:space-x-32',
  36: 'lg:space-x-36',
  40: 'lg:space-x-40',
  44: 'lg:space-x-44',
  48: 'lg:space-x-48',
  52: 'lg:space-x-52',
  56: 'lg:space-x-56',
  60: 'lg:space-x-60',
  64: 'lg:space-x-64',
  72: 'lg:space-x-72',
  80: 'lg:space-x-80',
  96: 'lg:space-x-96',
}

export const getSpaceX = (size, prefix) => {
  if (prefix === 'lg') {
    return sizesLG[size] ?? ''
  } else {
    return sizes[size] ?? ''
  }
}
