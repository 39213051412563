import { getYotpoProductId } from '../../../../helpers/getProductTrackingId'
import { YotpoScript } from '../../../../helpers/getYotpoScript'
import { ClientOnly } from '../../../atoms/ClientOnly'
import { useContext, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { observer } from 'mobx-react'
import { ProductsDataStoreContext } from '../../../../store/productsDataStore'

export const YotpoProductStars = observer(({ blok, data }) => {
  const { product } = useContext(ProductsDataStoreContext)
  const element = useRef()
  const router = useRouter()
  useEffect(() => {
    if (window?.yotpo && window.yotpo?.refreshWidgets) {
      window.yotpo.refreshWidgets()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product?.sku, router?.asPath, element?.current])
  return (
    <ClientOnly>
      <YotpoScript data={data} />
      <div
        ref={element}
        className="yotpo bottomLine"
        data-product-id={getYotpoProductId({ product: product })}
      ></div>
    </ClientOnly>
  )
})
