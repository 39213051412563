const classes = {
  xs: 'text-xs',
  sm: 'text-sm',
  base: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl',
  '2xl': 'text-2xl',
  '3xl': 'text-3xl',
  '4xl': 'text-4xl',
  '5xl': 'text-5xl',
  '6xl': 'text-6xl',
  '7xl': 'text-7xl',
  '8xl': 'text-8xl',
  '9xl': 'text-9xl',
}

export const getFontSize = (fontSize) => {
  if (!fontSize || fontSize === '' || fontSize == null) {
    return
  }

  if (classes[fontSize]) {
    return classes[fontSize]
  } else {
    console.error('Error setting font size', fontSize)
    return false
  }
}

export const getBigger = (fontSize) => {
  if (!fontSize || fontSize === '' || fontSize == null) {
    return
  }
  const keys = Object.keys(classes)
  const index = keys.indexOf(fontSize)
  const next = classes[keys[index + 2]]
  if (next) {
    return classes[keys[index + 2]]
  } else {
    return classes[fontSize]
  }
}

// For tailwind
const classesDesktop = {
  xs: 'lg:text-xs',
  sm: 'lg:text-sm',
  base: 'lg:text-base',
  lg: 'lg:text-lg',
  xl: 'lg:text-xl',
  '2xl': 'lg:text-2xl',
  '3xl': 'lg:text-3xl',
  '4xl': 'lg:text-4xl',
  '5xl': 'lg:text-5xl',
  '6xl': 'lg:text-6xl',
  '7xl': 'lg:text-7xl',
  '8xl': 'lg:text-8xl',
  '9xl': 'lg:text-9xl',
}
const classesDesktopMD = {
  xs: 'md:text-xs',
  sm: 'md:text-sm',
  base: 'md:text-base',
  lg: 'md:text-lg',
  xl: 'md:text-xl',
  '2xl': 'md:text-2xl',
  '3xl': 'md:text-3xl',
  '4xl': 'md:text-4xl',
  '5xl': 'md:text-5xl',
  '6xl': 'md:text-6xl',
  '7xl': 'md:text-7xl',
  '8xl': 'md:text-8xl',
  '9xl': 'md:text-9xl',
}
