import { sbEditable } from '@storyblok/storyblok-editable'
import classNames from 'classnames'
import { getFontWeight } from '../../../../helpers/getFontWeight'
import { getFontSize } from '../../../../helpers/getFontSize'
import { getLineHeight } from '../../../../helpers/getLineHeight'
import { getTextAlign } from '../../../../helpers/getTextAlign'
import { getText } from '../../../../helpers/getText'
import { isFewLeft } from '../../../../helpers/isItemInStock'
import { observer } from 'mobx-react'
import { useContext } from 'react'
import { ProductsDataStoreContext } from '../../../../store/productsDataStore'

export const ProductPageFewLeft = observer(({ data, blok, preview }) => {
  const { getItem } = useContext(ProductsDataStoreContext)
  const isItemFewLeft = () => {
    const i = getItem()
    if (i) {
      return isFewLeft(i?.stock, blok?.few_left_threshold)
    }

    return false
  }
  return (
    <>
      {(isItemFewLeft() || preview) && blok?.few_left_info && (
        <div className={'relative'} {...sbEditable(blok)}>
          <p
            style={{
              color: blok?.text_style?.[0]?.text_color || 'black',
            }}
            className={classNames(
              getFontWeight(blok?.text_style?.[0]?.font_weight),
              getFontSize(blok?.text_style?.[0]?.font_size),
              getLineHeight(blok?.text_style?.[0]?.line_height),
              getTextAlign(blok?.text_style?.[0]?.text_align),
            )}
          >
            {getText(
              blok?.few_left_info.replace('%stock%', getItem()?.stock),
              data,
            )}
          </p>
        </div>
      )}
    </>
  )
})
