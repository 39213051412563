export const getPadding = ({ prefix, desktop, mobile }) => {
  const mobileName = `${prefix}-${mobile}`
  const desktopName = `lg:${prefix}-${desktop}`

  const mobilePadding = classes.includes(mobileName) ? mobileName : ''
  const desktopPadding = classes.includes(desktopName) ? desktopName : ''

  return `${mobilePadding} ${desktopPadding}`.trim()
}

const classes = [
  'px-0',
  'px-1',
  'px-2',
  'px-3',
  'px-6',
  'px-9',
  'px-12',
  'px-14',
  'px-16',
  'px-20',
  'px-24',
  'px-28',
  'px-32',
  'px-36',
  'px-40',
  'px-44',
  'px-48',
  'px-52',
  'px-56',
  'px-60',
  'px-64',
  'px-72',
  'px-80',
  'px-96',

  'lg:px-0',
  'lg:px-1',
  'lg:px-2',
  'lg:px-3',
  'lg:px-6',
  'lg:px-9',
  'lg:px-12',
  'lg:px-14',
  'lg:px-16',
  'lg:px-20',
  'lg:px-24',
  'lg:px-28',
  'lg:px-32',
  'lg:px-36',
  'lg:px-40',
  'lg:px-44',
  'lg:px-48',
  'lg:px-52',
  'lg:px-56',
  'lg:px-60',
  'lg:px-64',
  'lg:px-72',
  'lg:px-80',
  'lg:px-96',

  'py-0',
  'py-1',
  'py-2',
  'py-3',
  'py-6',
  'py-9',
  'py-12',
  'py-14',
  'py-16',
  'py-20',
  'py-24',
  'py-28',
  'py-32',
  'py-36',
  'py-40',
  'py-44',
  'py-48',
  'py-52',
  'py-56',
  'py-60',
  'py-64',
  'py-72',
  'py-80',
  'py-96',

  'lg:py-0',
  'lg:py-1',
  'lg:py-2',
  'lg:py-3',
  'lg:py-6',
  'lg:py-9',
  'lg:py-12',
  'lg:py-14',
  'lg:py-16',
  'lg:py-20',
  'lg:py-24',
  'lg:py-28',
  'lg:py-32',
  'lg:py-36',
  'lg:py-40',
  'lg:py-44',
  'lg:py-48',
  'lg:py-52',
  'lg:py-56',
  'lg:py-60',
  'lg:py-64',
  'lg:py-72',
  'lg:py-80',
  'lg:py-96',

  'pb-0',
  'pb-1',
  'pb-2',
  'pb-3',
  'pb-6',
  'pb-9',
  'pb-12',
  'pb-14',
  'pb-16',
  'pb-20',
  'pb-24',
  'pb-28',
  'pb-32',
  'pb-36',
  'pb-40',
  'pb-44',
  'pb-48',
  'pb-52',
  'pb-56',
  'pb-60',
  'pb-64',
  'pb-72',
  'pb-80',
  'pb-96',

  'lg:pb-0',
  'lg:pb-1',
  'lg:pb-2',
  'lg:pb-3',
  'lg:pb-6',
  'lg:pb-9',
  'lg:pb-12',
  'lg:pb-14',
  'lg:pb-16',
  'lg:pb-20',
  'lg:pb-24',
  'lg:pb-28',
  'lg:pb-32',
  'lg:pb-36',
  'lg:pb-40',
  'lg:pb-44',
  'lg:pb-48',
  'lg:pb-52',
  'lg:pb-56',
  'lg:pb-60',
  'lg:pb-64',
  'lg:pb-72',
  'lg:pb-80',
  'lg:pb-96',

  'pt-0',
  'pt-1',
  'pt-2',
  'pt-3',
  'pt-6',
  'pt-9',
  'pt-12',
  'pt-14',
  'pt-16',
  'pt-20',
  'pt-24',
  'pt-28',
  'pt-32',
  'pt-36',
  'pt-40',
  'pt-44',
  'pt-48',
  'pt-52',
  'pt-56',
  'pt-60',
  'pt-64',
  'pt-72',
  'pt-80',
  'pt-96',

  'lg:pt-0',
  'lg:pt-1',
  'lg:pt-2',
  'lg:pt-3',
  'lg:pt-6',
  'lg:pt-9',
  'lg:pt-12',
  'lg:pt-14',
  'lg:pt-16',
  'lg:pt-20',
  'lg:pt-24',
  'lg:pt-28',
  'lg:pt-32',
  'lg:pt-36',
  'lg:pt-40',
  'lg:pt-44',
  'lg:pt-48',
  'lg:pt-52',
  'lg:pt-56',
  'lg:pt-60',
  'lg:pt-64',
  'lg:pt-72',
  'lg:pt-80',
  'lg:pt-96',

  'pr-0',
  'pr-1',
  'pr-2',
  'pr-3',
  'pr-6',
  'pr-9',
  'pr-12',
  'pr-14',
  'pr-16',
  'pr-20',
  'pr-24',
  'pr-28',
  'pr-32',
  'pr-36',
  'pr-40',
  'pr-44',
  'pr-48',
  'pr-52',
  'pr-56',
  'pr-60',
  'pr-64',
  'pr-72',
  'pr-80',
  'pr-96',

  'lg:pr-0',
  'lg:pr-1',
  'lg:pr-2',
  'lg:pr-3',
  'lg:pr-6',
  'lg:pr-9',
  'lg:pr-12',
  'lg:pr-14',
  'lg:pr-16',
  'lg:pr-20',
  'lg:pr-24',
  'lg:pr-28',
  'lg:pr-32',
  'lg:pr-36',
  'lg:pr-40',
  'lg:pr-44',
  'lg:pr-48',
  'lg:pr-52',
  'lg:pr-56',
  'lg:pr-60',
  'lg:pr-64',
  'lg:pr-72',
  'lg:pr-80',
  'lg:pr-96',

  'pl-0',
  'pl-1',
  'pl-2',
  'pl-3',
  'pl-6',
  'pl-9',
  'pl-12',
  'pl-14',
  'pl-16',
  'pl-20',
  'pl-24',
  'pl-28',
  'pl-32',
  'pl-36',
  'pl-40',
  'pl-44',
  'pl-48',
  'pl-52',
  'pl-56',
  'pl-60',
  'pl-64',
  'pl-72',
  'pl-80',
  'pl-96',

  'lg:pl-0',
  'lg:pl-1',
  'lg:pl-2',
  'lg:pl-3',
  'lg:pl-6',
  'lg:pl-9',
  'lg:pl-12',
  'lg:pl-14',
  'lg:pl-16',
  'lg:pl-20',
  'lg:pl-24',
  'lg:pl-28',
  'lg:pl-32',
  'lg:pl-36',
  'lg:pl-40',
  'lg:pl-44',
  'lg:pl-48',
  'lg:pl-52',
  'lg:pl-56',
  'lg:pl-60',
  'lg:pl-64',
  'lg:pl-72',
  'lg:pl-80',
  'lg:pl-96',

  'mt-0',
  'mt-1',
  'mt-2',
  'mt-3',
  'mt-6',
  'mt-9',
  'mt-12',
  'mt-14',
  'mt-16',
  'mt-20',
  'mt-24',
  'mt-28',
  'mt-32',
  'mt-36',
  'mt-40',
  'mt-44',
  'mt-48',
  'mt-52',
  'mt-56',
  'mt-60',
  'mt-64',
  'mt-72',
  'mt-80',
  'mt-96',

  'lg:mt-0',
  'lg:mt-1',
  'lg:mt-2',
  'lg:mt-3',
  'lg:mt-6',
  'lg:mt-9',
  'lg:mt-12',
  'lg:mt-14',
  'lg:mt-16',
  'lg:mt-20',
  'lg:mt-24',
  'lg:mt-28',
  'lg:mt-32',
  'lg:mt-36',
  'lg:mt-40',
  'lg:mt-44',
  'lg:mt-48',
  'lg:mt-52',
  'lg:mt-56',
  'lg:mt-60',
  'lg:mt-64',
  'lg:mt-72',
  'lg:mt-80',
  'lg:mt-96',
]
