export const getMargin = ({ prefix, desktop, mobile }) => {
  const mobileName = `${prefix}-${mobile}`
  const desktopName = `lg:${prefix}-${desktop}`

  const mobileMargin = classes.includes(mobileName) ? mobileName : ''
  const desktopMargin = classes.includes(desktopName) ? desktopName : ''

  return `${mobileMargin} ${desktopMargin}`.trim()
}

const classes = [
  'mx-0',
  'mx-1',
  'mx-2',
  'mx-3',
  'mx-6',
  'mx-9',
  'mx-12',
  'mx-14',
  'mx-16',
  'mx-20',
  'mx-24',
  'mx-28',
  'mx-32',
  //   'mx-36',
  //   'mx-40',
  //   'mx-44',
  //   'mx-48',
  //   'mx-52',
  //   'mx-56',
  //   'mx-60',
  //   'mx-64',
  //   'mx-72',
  //   'mx-80',
  //   'mx-96',

  'lg:mx-0',
  'lg:mx-1',
  'lg:mx-2',
  'lg:mx-3',
  'lg:mx-6',
  'lg:mx-9',
  'lg:mx-12',
  'lg:mx-14',
  'lg:mx-16',
  'lg:mx-20',
  'lg:mx-24',
  'lg:mx-28',
  'lg:mx-32',
  //   'lg:mx-36',
  //   'lg:mx-40',
  //   'lg:mx-44',
  //   'lg:mx-48',
  //   'lg:mx-52',
  //   'lg:mx-56',
  //   'lg:mx-60',
  //   'lg:mx-64',
  //   'lg:mx-72',
  //   'lg:mx-80',
  //   'lg:mx-96',

  'my-0',
  'my-1',
  'my-2',
  'my-3',
  'my-6',
  'my-9',
  'my-12',
  'my-14',
  'my-16',
  'my-20',
  'my-24',
  'my-28',
  'my-32',
  //   'my-36',
  //   'my-40',
  //   'my-44',
  //   'my-48',
  //   'my-52',
  //   'my-56',
  //   'my-60',
  //   'my-64',
  //   'my-72',
  //   'my-80',
  //   'my-96',

  'lg:my-0',
  'lg:my-1',
  'lg:my-2',
  'lg:my-3',
  'lg:my-6',
  'lg:my-9',
  'lg:my-12',
  'lg:my-14',
  'lg:my-16',
  'lg:my-20',
  'lg:my-24',
  'lg:my-28',
  'lg:my-32',
  //   'lg:my-36',
  //   'lg:my-40',
  //   'lg:my-44',
  //   'lg:my-48',
  //   'lg:my-52',
  //   'lg:my-56',
  //   'lg:my-60',
  //   'lg:my-64',
  //   'lg:my-72',
  //   'lg:my-80',
  //   'lg:my-96',

  'mb-0',
  'mb-1',
  'mb-2',
  'mb-3',
  'mb-6',
  'mb-9',
  'mb-12',
  'mb-14',
  'mb-16',
  'mb-20',
  'mb-24',
  'mb-28',
  'mb-32',
  //   'mb-36',
  //   'mb-40',
  //   'mb-44',
  //   'mb-48',
  //   'mb-52',
  //   'mb-56',
  //   'mb-60',
  //   'mb-64',
  //   'mb-72',
  //   'mb-80',
  //   'mb-96',

  'lg:mb-0',
  'lg:mb-1',
  'lg:mb-2',
  'lg:mb-3',
  'lg:mb-6',
  'lg:mb-9',
  'lg:mb-12',
  'lg:mb-14',
  'lg:mb-16',
  'lg:mb-20',
  'lg:mb-24',
  'lg:mb-28',
  'lg:mb-32',
  //   'lg:mb-36',
  //   'lg:mb-40',
  //   'lg:mb-44',
  //   'lg:mb-48',
  //   'lg:mb-52',
  //   'lg:mb-56',
  //   'lg:mb-60',
  //   'lg:mb-64',
  //   'lg:mb-72',
  //   'lg:mb-80',
  //   'lg:mb-96',

  'mt-0',
  'mt-1',
  'mt-2',
  'mt-3',
  'mt-6',
  'mt-9',
  'mt-12',
  'mt-14',
  'mt-16',
  'mt-20',
  'mt-24',
  'mt-28',
  'mt-32',
  //   'mt-36',
  //   'mt-40',
  //   'mt-44',
  //   'mt-48',
  //   'mt-52',
  //   'mt-56',
  //   'mt-60',
  //   'mt-64',
  //   'mt-72',
  //   'mt-80',
  //   'mt-96',

  'lg:mt-0',
  'lg:mt-1',
  'lg:mt-2',
  'lg:mt-3',
  'lg:mt-6',
  'lg:mt-9',
  'lg:mt-12',
  'lg:mt-14',
  'lg:mt-16',
  'lg:mt-20',
  'lg:mt-24',
  'lg:mt-28',
  'lg:mt-32',
  //   'lg:mt-36',
  //   'lg:mt-40',
  //   'lg:mt-44',
  //   'lg:mt-48',
  //   'lg:mt-52',
  //   'lg:mt-56',
  //   'lg:mt-60',
  //   'lg:mt-64',
  //   'lg:mt-72',
  //   'lg:mt-80',
  //   'lg:mt-96',

  'mr-0',
  'mr-1',
  'mr-2',
  'mr-3',
  'mr-6',
  'mr-9',
  'mr-12',
  'mr-14',
  'mr-16',
  'mr-20',
  'mr-24',
  'mr-28',
  'mr-32',
  //   'mr-36',
  //   'mr-40',
  //   'mr-44',
  //   'mr-48',
  //   'mr-52',
  //   'mr-56',
  //   'mr-60',
  //   'mr-64',
  //   'mr-72',
  //   'mr-80',
  //   'mr-96',

  'lg:mr-0',
  'lg:mr-1',
  'lg:mr-2',
  'lg:mr-3',
  'lg:mr-6',
  'lg:mr-9',
  'lg:mr-12',
  'lg:mr-14',
  'lg:mr-16',
  'lg:mr-20',
  'lg:mr-24',
  'lg:mr-28',
  'lg:mr-32',
  //   'lg:mr-36',
  //   'lg:mr-40',
  //   'lg:mr-44',
  //   'lg:mr-48',
  //   'lg:mr-52',
  //   'lg:mr-56',
  //   'lg:mr-60',
  //   'lg:mr-64',
  //   'lg:mr-72',
  //   'lg:mr-80',
  //   'lg:mr-96',

  'ml-0',
  'ml-1',
  'ml-2',
  'ml-3',
  'ml-6',
  'ml-9',
  'ml-12',
  'ml-14',
  'ml-16',
  'ml-20',
  'ml-24',
  'ml-28',
  'ml-32',
  //   'ml-36',
  //   'ml-40',
  //   'ml-44',
  //   'ml-48',
  //   'ml-52',
  //   'ml-56',
  //   'ml-60',
  //   'ml-64',
  //   'ml-72',
  //   'ml-80',
  //   'ml-96',

  'lg:ml-0',
  'lg:ml-1',
  'lg:ml-2',
  'lg:ml-3',
  'lg:ml-6',
  'lg:ml-9',
  'lg:ml-12',
  'lg:ml-14',
  'lg:ml-16',
  'lg:ml-20',
  'lg:ml-24',
  'lg:ml-28',
  'lg:ml-32',
  //   'lg:ml-36',
  //   'lg:ml-40',
  //   'lg:ml-44',
  //   'lg:ml-48',
  //   'lg:ml-52',
  //   'lg:ml-56',
  //   'lg:ml-60',
  //   'lg:ml-64',
  //   'lg:ml-72',
  //   'lg:ml-80',
  //   'lg:ml-96',

  'mt-0',
  'mt-1',
  'mt-2',
  'mt-3',
  'mt-6',
  'mt-9',
  'mt-12',
  'mt-14',
  'mt-16',
  'mt-20',
  'mt-24',
  'mt-28',
  'mt-32',
  //   'mt-36',
  //   'mt-40',
  //   'mt-44',
  //   'mt-48',
  //   'mt-52',
  //   'mt-56',
  //   'mt-60',
  //   'mt-64',
  //   'mt-72',
  //   'mt-80',
  //   'mt-96',

  'lg:mt-0',
  'lg:mt-1',
  'lg:mt-2',
  'lg:mt-3',
  'lg:mt-6',
  'lg:mt-9',
  'lg:mt-12',
  'lg:mt-14',
  'lg:mt-16',
  'lg:mt-20',
  'lg:mt-24',
  'lg:mt-28',
  'lg:mt-32',
  //   'lg:mt-36',
  //   'lg:mt-40',
  //   'lg:mt-44',
  //   'lg:mt-48',
  //   'lg:mt-52',
  //   'lg:mt-56',
  //   'lg:mt-60',
  //   'lg:mt-64',
  //   'lg:mt-72',
  //   'lg:mt-80',
  //   'lg:mt-96',
]
