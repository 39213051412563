import classNames from 'classnames'
import { sbEditable } from '@storyblok/storyblok-editable'
import { GeneralError } from '../atoms/GeneralError'
import Image from 'next/future/image'
import { getImage } from '../../helpers/getImage'
import Link from 'next/link'
import { getImageAlt } from '../../helpers/getImageAlt'

export function PagesGrid({ blok, data, preview }) {
  const pages = data[blok?._uid]?.pages
  if (pages?.length > 0) {
    return (
      <>
        {pages.map((page, key) => (
          <Link href={`/${page?.full_slug}`} key={key}>
            <a className={classNames('flex flex-col group')}>
              <h4
                className={'text-xl font-bold leading-6 group-hover:underline'}
              >
                {getTitle({ page })}
              </h4>

              {blok?.date != 'hide' && (
                <p className={'text-xs text-gray-400'}>
                  {page?.published_at?.split('T')[0] ??
                    page?.created_at?.split('T')[0]}
                </p>
              )}

              <div className={'relative w-full mt-3'}>
                <Image
                  src={getImage(page?.content?.meta_image?.filename)}
                  width={800}
                  height={600}
                  alt={getImageAlt({
                    alt: page?.content?.meta_image?.alt,
                    image: getImage(page?.content?.meta_image?.filename),
                  })}
                  className={'w-full h-auto'}
                />
              </div>
              {page?.content?.meta_desc?.length > 0 && (
                <p className={'mt-3'}>{page?.content?.meta_desc}</p>
              )}
            </a>
          </Link>
        ))}
      </>
    )
  } else {
    const error = `No pages found in folder <b>${blok?.pages_folder}</b>`
    return (
      <GeneralError blok={blok} preview={preview}>
        {error}
      </GeneralError>
    )
  }
}

const getTitle = ({ page }) => {
  if (page?.content?.title?.length > 0) {
    return page?.content?.title
  }

  return page?.name
}
