const sizes = {
  0: 'space-y-0',
  1: 'space-y-1',
  2: 'space-y-2',
  3: 'space-y-3',
  6: 'space-y-6',
  9: 'space-y-9',
  12: 'space-y-12',
  14: 'space-y-14',
  16: 'space-y-16',
  20: 'space-y-20',
  24: 'space-y-24',
  28: 'space-y-28',
  32: 'space-y-32',
  36: 'space-y-36',
  40: 'space-y-40',
  44: 'space-y-44',
  48: 'space-y-48',
  52: 'space-y-52',
  56: 'space-y-56',
  60: 'space-y-60',
  64: 'space-y-64',
  72: 'space-y-72',
  80: 'space-y-80',
  96: 'space-y-96',
}

const sizesLG = {
  0: 'lg:space-y-0',
  1: 'lg:space-y-1',
  2: 'lg:space-y-2',
  3: 'lg:space-y-3',
  6: 'lg:space-y-6',
  9: 'lg:space-y-9',
  12: 'lg:space-y-12',
  14: 'lg:space-y-14',
  16: 'lg:space-y-16',
  20: 'lg:space-y-20',
  24: 'lg:space-y-24',
  28: 'lg:space-y-28',
  32: 'lg:space-y-32',
  36: 'lg:space-y-36',
  40: 'lg:space-y-40',
  44: 'lg:space-y-44',
  48: 'lg:space-y-48',
  52: 'lg:space-y-52',
  56: 'lg:space-y-56',
  60: 'lg:space-y-60',
  64: 'lg:space-y-64',
  72: 'lg:space-y-72',
  80: 'lg:space-y-80',
  96: 'lg:space-y-96',
}

export const getSpaceY = (size, prefix) => {
  if (prefix === 'lg') {
    return sizesLG[size] ?? ''
  } else {
    return sizes[size] ?? ''
  }
}
