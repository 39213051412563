import { sbEditable } from '@storyblok/storyblok-editable'
export const GeneralError = ({ children, blok, preview }) => {
  if (!preview) {
    return null
  }
  return (
    <div
      {...sbEditable(blok)}
      className={
        'bg-red-100 p-3 text-red-700 text-center border border-red-700'
      }
      dangerouslySetInnerHTML={{ __html: children }}
    ></div>
  )
}
