import DynamicComponent from '../DynamicComponent'
import { useState } from 'react'
import classNames from 'classnames'

export const Collapsible = ({ blok, data }) => {
  const [show, setShow] = useState(false)

  return (
    <div
      onClick={() => {
        setShow(!show)
      }}
      className={classNames(
        'w-full flex ',
        blok?.sticky_top === 'yes' ? 'flex-col-reverse' : 'flex-col',
      )}
    >
      {show && (
        <div className={'w-full'}>
          {blok?.content?.map((nestedBlok, index) => {
            return (
              <DynamicComponent key={index} blok={nestedBlok} data={data} />
            )
          })}
        </div>
      )}

      {blok?.sticky_top !== 'yes' && (
        <div
          className={'w-full cursor-pointer'}
          onClick={() => {
            setShow(!show)
          }}
        >
          {show &&
            blok?.bottom?.map((nestedBlok, index) => {
              return (
                <DynamicComponent key={index} blok={nestedBlok} data={data} />
              )
            })}

          {!show &&
            blok?.top?.map((nestedBlok, index) => {
              return (
                <DynamicComponent key={index} blok={nestedBlok} data={data} />
              )
            })}
        </div>
      )}

      {blok?.sticky_top === 'yes' && (
        <div
          className={'w-full cursor-pointer'}
          onClick={() => {
            setShow(!show)
          }}
        >
          {show &&
            blok?.bottom?.map((nestedBlok, index) => {
              return (
                <DynamicComponent key={index} blok={nestedBlok} data={data} />
              )
            })}

          {!show &&
            blok?.top?.map((nestedBlok, index) => {
              return (
                <DynamicComponent key={index} blok={nestedBlok} data={data} />
              )
            })}
        </div>
      )}
    </div>
  )
}
