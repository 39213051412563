import Storyblok from '../../../../lib/storyblok/storyblok'
import classNames from 'classnames'
import Image from 'next/future/image'
import { BreadcrumbsSharable } from '../../../sharable/breadcrumbs/BreadcrumbsSharable'
import { getImage } from '../../../../helpers/getImage'
import { getImageAlt } from '../../../../helpers/getImageAlt'
import { sbEditable } from '@storyblok/storyblok-editable'
import { useState } from 'react'
import { getStoryblokImageSize } from '../../../../helpers/getStoryblokImageSize'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'
import { getText } from '../../../../helpers/getText'

export const CategoryTwoColumnHeader = ({ blok, data }) => {
  const html = Storyblok.richTextResolver.render(blok?.description ?? '')
  const [showMore, setShowMore] = useState(false)
  const getImageTransformEffect = () => {
    if (blok?.image_transform_effect === 'left_side_skew') {
      return 'clip-path-right-side'
    }

    return ''
  }
  const url = blok?.image?.filename
  const { width: imageWidth, height: imageHeight } = getStoryblokImageSize(url)

  const getWidth = () => {
    if (blok?.image_transform_effect === 'left_side_skew') {
      return 'lg:w-[44%]'
    }

    return 'lg:w-[50%]'
  }

  const getLeft = () => {
    if (blok?.image_transform_effect === 'left_side_skew') {
      return 'left-[44%]'
    }

    return 'left-[50%]'
  }

  return (
    <div
      {...sbEditable(blok)}
      className={
        'flex justify-center w-full relative min-h-[10px] lg:min-h-[400px]'
      }
      style={{
        backgroundColor: blok?.background_color ?? 'white',
      }}
    >
      <div className={'w-full px-3 py-6 lg:py-0'}>
        <div
          className={classNames(
            'w-full flex flex-col h-full lg:pr-10 lg:pb-10 justify-center',
            getWidth(),
          )}
        >
          {blok?.show_breadcrumb && (
            <div className={'flex w-full justify-center lg:justify-start'}>
              <BreadcrumbsSharable data={data} />
            </div>
          )}

          {blok?.title && (
            <h1
              className={
                'text-3xl lg:text-4xl font-bold text-center lg:text-left font-title'
              }
            >
              {getText(blok?.title, data)}
            </h1>
          )}

          {html && (
            <>
              <div
                className={classNames(
                  'text-black mt-3 text-sm leading-relaxed  overflow-hidden',
                  blok?.show_desc_on_mobile
                    ? 'block text-center lg:text-left'
                    : 'lg:block hidden',
                  showMore
                    ? ''
                    : blok?.show_more_title?.length > 0
                    ? 'max-h-[50px] lg:max-h-full'
                    : '',
                )}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: getText(html, data),
                  }}
                />
              </div>
              {blok?.show_more_title?.length > 0 && (
                <div
                  className={
                    'w-full lg:hidden flex justify-center  items-center text-xs mt-[-45px] pt-14 pb-1 cursor-pointer font-light'
                  }
                  onClick={() => {
                    setShowMore(!showMore)
                  }}
                >
                  {showMore
                    ? getText(blok?.show_more_hide_title, data)
                    : getText(blok?.show_more_title, data)}
                  {showMore ? (
                    <ChevronUpIcon className="ml-2 h-4 w-4" />
                  ) : (
                    <ChevronDownIcon className="ml-2  h-4 w-4" />
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div
        className={classNames(
          'absolute top-0 bottom-0 right-0 hidden lg:block',
          getLeft(),
        )}
      >
        <Image
          alt={getImageAlt({
            alt: blok?.image?.alt,
            image: getImage(blok?.image?.filename),
          })}
          src={getImage(blok?.image?.filename)}
          width={imageWidth}
          height={imageHeight}
          className={classNames(
            'w-full h-full object-cover',
            getImageTransformEffect(),
          )}
        />
      </div>
    </div>
  )
}
