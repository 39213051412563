import { sbEditable } from '@storyblok/storyblok-editable'
import classNames from 'classnames'
import { getFontWeight } from '../../../../helpers/getFontWeight'
import { getFontSize } from '../../../../helpers/getFontSize'
import { getLineHeight } from '../../../../helpers/getLineHeight'
import { getTextAlign } from '../../../../helpers/getTextAlign'
import { PriceLoader } from './PriceLoader'
import { observer } from 'mobx-react'
import { useContext } from 'react'
import { CartStoreContext } from '../../../../store/cartStore'
import { ProductsDataStoreContext } from '../../../../store/productsDataStore'
import { getProductPrice } from '../../../../helpers/getProductPrice'

export const ProductPagePrice = observer(({ data, blok }) => {
  const { CSObject } = useContext(CartStoreContext)
  const { productPageProduct } = useContext(ProductsDataStoreContext)
  const product = productPageProduct

  const prices = getProductPrice({ CSObject, product })

  return (
    <div className="flex min-h-[30px] relative" {...sbEditable(blok)}>
      <p
        style={{
          color: blok?.text_style?.[0]?.text_color
            ? blok?.text_style?.[0]?.text_color
            : 'black',
        }}
        className={classNames(
          'flex-1',
          getFontWeight(blok?.text_style?.[0]?.font_weight),
          getFontSize(blok?.text_style?.[0]?.font_size),
          getLineHeight(blok?.text_style?.[0]?.line_height),
          getTextAlign(blok?.text_style?.[0]?.text_align),
        )}
      >
        {prices?.priceBeforeDiscount !== prices?.price && (
          <span className="line-through mr-2 text-gray-400">
            {prices?.priceBeforeDiscount}
          </span>
        )}
        {prices?.price}
      </p>

      <PriceLoader loading={!prices} />
    </div>
  )
})
