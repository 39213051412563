import Link from 'next/link'
import classNames from 'classnames'
import { getFontWeight } from '../../helpers/getFontWeight'
import { getFontSize } from '../../helpers/getFontSize'
import { useContext, useState } from 'react'
import { getPadding } from '../../helpers/getPadding'
import DynamicComponent from '../DynamicComponent'
import { sbEditable } from '@storyblok/storyblok-editable'
import { observer } from 'mobx-react'
import { HeaderLinkStoreContext } from '../../store/headerLinkStore'
import { Transition } from '@headlessui/react'
import { getText } from '../../helpers/getText'
import { getHref } from '../../helpers/getHref'

const isHeaderAnimationTransparent = (data) => {
  return (
    data?.layoutData?.header_animation?.[0]?.component ===
    'transparent_header_animation'
  )
}
export const HeaderLink = observer(({ blok, data, title, preview }) => {
  const { setLink, link } = useContext(HeaderLinkStoreContext)
  const paddingX = blok?.padding_x?.length > 0 ? blok?.padding_x : 0
  const doShow = () => {
    window.header_link_hax_show = true
    setLink(blok?._uid)
  }

  const doHide = () => {
    window.header_link_hax_show = false
  }

  const href = blok?.centra_category_uri
    ? '/category/' + blok?.centra_category_uri
    : getHref(blok?.url)

  return (
    <div
      {...sbEditable(blok)}
      onMouseEnter={() => {
        doShow()
      }}
      onMouseLeave={() => {
        doHide()
      }}
      style={{ color: blok?.text_color ? blok?.text_color : 'black' }}
      className={classNames('block group', 'h-full')}
    >
      <Link href={href}>
        <a
          className={classNames(
            'flex relative h-full',
            getPadding({
              prefix: 'px',
              desktop: paddingX,
              mobile: paddingX,
            }),
          )}
          onClick={() => {
            setLink(null)
          }}
        >
          <div
            className={classNames(
              'whitespace-nowrap',
              'relative h-full flex items-center',
              'after:absolute after:h-[1px] after:w-full after:bottom-[0] after:left-0 after:bg-white after:scale-x-0 after:transition-transform',
              'group-hover:after:scale-x-100',
              getFontWeight(blok?.font_weight),
              getFontSize(blok?.font_size),
            )}
          >
            {title ? getText(title, data) : getText(blok?.title, data)}
          </div>
        </a>
      </Link>

      <Transition
        // We need this when animation i transparent
        // If we dont the invert will blink if you hover the link
        // Try set this to true and try to hover a link you will see the blink
        //TODO maybe fix better solution in the future? 🫶
        show={!isHeaderAnimationTransparent(data) ? true : link === blok?._uid}
        enter="transition-opacity duration-5"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-5"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          onMouseEnter={() => {
            doShow()
          }}
          onMouseLeave={() => {
            doHide()
          }}
          style={{
            width: '100%',
            backgroundColor: blok?.submenu?.[0]?.background_color
              ? blok?.submenu?.[0]?.background_color
              : data?.layoutData?.header_desktop_default_submenu?.[0]
                  ?.background_color
              ? data?.layoutData?.header_desktop_default_submenu?.[0]
                  ?.background_color
              : 'white',
            marginTop: data?.headerHeight,
          }}
          className={classNames(
            link === blok?._uid ? 'flex' : 'hidden',
            'fixed left-0 top-0 w-full z-30',
          )}
        >
          {!blok?.submenu?.[0] &&
            data?.layoutData?.header_desktop_default_submenu?.[0] &&
            data?.layoutData?.header_desktop_default_submenu?.[0]?.bloks?.map(
              (nestedBlok, index) => (
                <DynamicComponent
                  key={index}
                  blok={nestedBlok}
                  data={{
                    ...data,
                    uri: blok?.centra_category_uri,
                    onClickCategory: () => {
                      doHide()
                    },
                  }}
                  preview={preview}
                />
              ),
            )}
          {blok?.submenu?.[0] &&
            blok?.submenu?.[0]?.bloks?.map((nestedBlok, index) => (
              <DynamicComponent
                key={index}
                blok={nestedBlok}
                data={{
                  ...data,
                  uri: blok?.centra_category_uri,
                  onClickCategory: () => {
                    doHide()
                  },
                }}
                preview={preview}
              />
            ))}
        </div>
      </Transition>
    </div>
  )
})
