export const getText = (text, data) => {
  const translations = data?.settingsData?.translations
  if (text && text?.includes('{{') && text?.includes('}}') && translations) {
    let pattern = new RegExp('{{(.*?)}}', 'g')
    let match
    const results = []
    for (let i = 0; (match = pattern.exec(text)) !== null; i++) {
      const value = translations?.find((tl) => tl?.key === match[1])
      if (value?.value) {
        results.push({ key: value.key, value: value?.value })
      }
    }
    if (results?.length) {
      for (const result of results) {
        text = text.replaceAll('{{' + result.key + '}}', result.value)
      }
    }
    return text
  }

  return text
}
