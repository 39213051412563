import { FilterStoreContext } from '../../../../store/filterStore'
import { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import {
  MinusSmIcon,
  PlusSmIcon,
  AdjustmentsIcon,
} from '@heroicons/react/outline'
import classNames from 'classnames'
import { getBolder, getFontWeight } from '../../../../helpers/getFontWeight'
import { getFontSize } from '../../../../helpers/getFontSize'
import { getLineHeight } from '../../../../helpers/getLineHeight'
import { getTextAlign } from '../../../../helpers/getTextAlign'
import { getHeight, getWidth } from '../../../../helpers/getSize'
import { getText } from '../../../../helpers/getText'
import { SideModal } from '../../../sharable/modals/SideModal'
import { Checkbox } from '../../../atoms/Checkbox'
import { sbEditable } from '@storyblok/storyblok-editable'
import { getPadding } from '../../../../helpers/getPadding'

export const Filters = ({ blok, data, preview }) => {
  return (
    <div className={'lg:w-full'} {...sbEditable(blok)}>
      <FilterSelect data={data} blok={blok} preview={preview} />
    </div>
  )
}

const FilterSelect = ({ blok, data, preview }) => {
  const [showModal, setShowModal] = useState(false)
  const alwaysCollapsed = blok?.always_collapsed === 'yes' || false
  return (
    <>
      {/*Mobile*/}
      <div className={alwaysCollapsed ? 'blok' : 'lg:hidden blok'}>
        <div
          className={'flex items-center cursor-pointer'}
          onClick={() => {
            setShowModal(!showModal)
          }}
        >
          <p
            style={{
              color: blok?.mobile_title_text_style?.[0]?.text_color
                ? blok?.mobile_title_text_style?.[0]?.text_color
                : 'black',
            }}
            className={classNames(
              getFontWeight(blok?.mobile_title_text_style?.[0]?.font_weight) ||
                'font-medium', // this was the default before we added text_style
              getFontSize(blok?.mobile_title_text_style?.[0]?.font_size) ||
                'text-base', // this was the default before we added text_style
              getLineHeight(blok?.mobile_title_text_style?.[0]?.line_height),
              getTextAlign(blok?.mobile_title_text_style?.[0]?.text_align),
              'pr-2',
            )}
          >
            {blok?.mobile_title || 'Filter'}
          </p>

          {!showModal && (
            <AdjustmentsIcon
              style={{
                color: blok?.mobile_title_text_style?.[0]?.text_color
                  ? blok?.mobile_title_text_style?.[0]?.text_color
                  : 'black',
              }}
              className={classNames(
                blok?.icon_size ? getHeight(blok?.icon_size) : 'h-6',
                blok?.icon_size ? getWidth(blok?.icon_size) : 'w-6',
              )}
              aria-hidden="true"
            />
          )}

          {showModal && (
            <AdjustmentsIcon
              style={{
                color: blok?.mobile_title_text_style?.[0]?.text_color
                  ? blok?.mobile_title_text_style?.[0]?.text_color
                  : 'black',
              }}
              className={classNames(
                blok?.icon_size ? getHeight(blok?.icon_size) : 'h-6',
                blok?.icon_size ? getWidth(blok?.icon_size) : 'w-6',
                'pl-1',
              )}
              aria-hidden="true"
            />
          )}
        </div>

        <SideModal
          onClose={() => {
            setShowModal(false)
          }}
          show={showModal}
          left={blok?.modal_direction === 'left' ? true : false}
          title={getText(blok?.mobile_title || 'Filter', data)}
          titleStyle={blok?.mobile_side_menu_title_text_style?.[0]}
          backgroundColor={blok?.modal_background_color || 'white'}
          textColor={
            blok?.mobile_side_menu_title_text_style?.[0]?.text_color
              ? blok?.mobile_side_menu_title_text_style?.[0]?.text_color
              : 'black'
          }
          iconSize={
            blok?.side_menu_close_icon_size
              ? getHeight(blok?.side_menu_close_icon_size)
              : 'h-6' + ' ' + blok?.side_menu_close_icon_size
              ? getWidth(blok?.side_menu_close_icon_size)
              : 'w-6'
          }
          px={
            blok?.side_menu_padding_x
              ? getPadding({
                  prefix: 'px',
                  desktop: blok?.side_menu_padding_x,
                  mobile: blok?.side_menu_padding_x,
                })
              : 'px-6'
          }
        >
          <div className={' overflow-hidden'}>
            <FilterMap
              filters={filterOutFilters(data?.filters) ?? []}
              data={data}
              checkboxBorderColor={blok?.checkbox_border_color || 'lightGrey'}
              checkboxBackgroundColor={
                blok?.checkbox_background_color || 'lightGrey'
              }
              checkboxSelectedBackgroundColor={
                blok?.checkbox_selected_background_color || 'lightGrey'
              }
              checkboxSelectedColor={blok?.checkbox_tick_color || 'white'}
              inputSize={blok?.input_size}
              iconSize={blok?.icon_size}
              valueTextStyle={blok?.value_text_style?.[0]}
              titleTextStyle={blok?.title_text_style?.[0]}
              iconColor={blok?.icon_color || 'lightGrey'}
              borderColor={blok?.border_color || 'lightGrey'}
              animationDuration={blok?.animation_duration}
            />
          </div>
        </SideModal>
      </div>

      {/*Dekstop*/}
      {!alwaysCollapsed && (
        <div className={'w-full lg:block hidden'}>
          <FilterMap
            filters={filterOutFilters(data?.filters) ?? []}
            data={data}
            checkboxBorderColor={
              blok?.checkbox_border_color_desktop
                ? blok?.checkbox_border_color_desktop
                : blok?.checkbox_border_color || 'lightGrey'
            }
            checkboxBackgroundColor={
              blok?.checkbox_background_color_desktop
                ? blok?.checkbox_background_color_desktop
                : blok?.checkbox_background_color || 'lightGrey'
            }
            checkboxSelectedBackgroundColor={
              blok?.checkbox_selected_background_color_desktop
                ? blok?.checkbox_selected_background_color_desktop
                : blok?.checkbox_selected_background_color || 'lightGrey'
            }
            checkboxSelectedColor={
              blok?.checkbox_tick_color_desktop
                ? blok?.checkbox_tick_color_desktop
                : blok?.checkbox_tick_color || 'white'
            }
            inputSize={blok?.input_size_desktop || blok?.input_size}
            iconSize={blok?.icon_size_desktop || blok?.icon_size}
            valueTextStyle={
              blok?.value_text_style_desktop?.[0] ?? blok?.value_text_style?.[0]
            }
            titleTextStyle={
              blok?.title_text_style_desktop?.[0] ?? blok?.title_text_style?.[0]
            }
            iconColor={
              blok?.icon_color_desktop
                ? blok?.icon_color_desktop
                : blok?.icon_color || 'lightGrey'
            }
            borderColor={
              blok?.border_color_desktop
                ? blok?.border_color_desktop
                : blok?.border_color || 'lightGrey'
            }
            animationDuration={blok?.animation_duration}
          />
        </div>
      )}
    </>
  )
}

const FilterMap = observer(
  ({
    filters,
    data,
    checkboxBorderColor,
    checkboxBackgroundColor,
    checkboxSelectedColor,
    checkboxSelectedBackgroundColor,
    inputSize,
    valueTextStyle,
    titleTextStyle,
    borderColor,
    iconColor,
    iconSize,
    animationDuration,
  }) => {
    const { addFilter, removeFilter, isFilterSelected } =
      useContext(FilterStoreContext)

    return (
      <>
        {filters?.map((filter) => {
          return (
            <FilterCell
              key={filter?.field}
              titleTextStyle={titleTextStyle}
              borderColor={borderColor}
              iconColor={iconColor}
              iconSize={iconSize}
              animationDuration={animationDuration}
              title={getTitle(data?.settingsData?.filter_map, filter.field)}
            >
              <div key={filter.field} className={'w-full'}>
                {filter?.values?.map((value) => {
                  return (
                    <div
                      key={value?.value}
                      className={'flex space-x-3 items-center mb-2'}
                    >
                      <Checkbox
                        w={inputSize ? getHeight(inputSize) : 'h-6'}
                        h={inputSize ? getWidth(inputSize) : 'w-6'}
                        backgroundColor={checkboxBackgroundColor}
                        borderColor={checkboxBorderColor}
                        selectedColor={checkboxSelectedColor}
                        selectedBackgroundColor={
                          checkboxSelectedBackgroundColor
                        }
                        checked={isFilterSelected({ filter, value })}
                        onChange={() => {
                          if (isFilterSelected({ filter, value })) {
                            removeFilter({
                              value: value?.value,
                              field: filter.field,
                            })
                          } else {
                            addFilter({
                              value: value?.value,
                              field: filter.field,
                            })
                          }
                        }}
                      />
                      <div className={'flex items-center justify-center'}>
                        <p
                          style={{
                            color: valueTextStyle?.text_color
                              ? valueTextStyle?.text_color
                              : 'black',
                          }}
                          className={classNames(
                            getFontWeight(valueTextStyle?.font_weight),
                            getFontSize(valueTextStyle?.font_size),
                            getLineHeight(valueTextStyle?.line_height),
                            getTextAlign(valueTextStyle?.text_align),
                          )}
                        >
                          {value?.value}
                        </p>
                        {getColorFromFilter(value) && (
                          <div
                            style={{
                              backgroundColor: getColorFromFilter(value),
                            }}
                            className={classNames(
                              'ml-2 rounded-full',
                              inputSize ? getHeight(inputSize) : 'h-6',
                              inputSize ? getWidth(inputSize) : 'w-6',
                            )}
                          ></div>
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            </FilterCell>
          )
        })}
      </>
    )
  },
)

const FilterCell = ({
  children,
  title,

  borderColor,
  titleTextStyle,
  iconColor,
  iconSize,
  animationDuration,
}) => {
  const [show, setShow] = useState(false)
  const ref = useRef(null)
  return (
    <div className={'w-full'}>
      <div
        onClick={() => {
          setShow(!show)
        }}
        style={{ borderColor: borderColor || 'lightGrey' }}
        className={
          'py-3 flex justify-between w-full border-t cursor-pointer items-center pl-3'
        }
      >
        <p
          style={{
            color: titleTextStyle?.text_color
              ? titleTextStyle?.text_color
              : 'black',
          }}
          className={classNames(
            getFontSize(titleTextStyle?.font_size) || 'text-base', // this was the default before we added text_style
            getLineHeight(titleTextStyle?.line_height),
            getTextAlign(titleTextStyle?.text_align),
            show
              ? getBolder(titleTextStyle?.font_weight) || 'font-bold'
              : getFontWeight(titleTextStyle?.font_weight) || 'font-medium', // this was the default before we added text_style
          )}
        >
          {title}
        </p>
        {!show && (
          <PlusSmIcon
            style={{ color: iconColor || 'lightGrey' }}
            className={classNames(
              iconSize ? getHeight(iconSize) : 'h-6',
              iconSize ? getWidth(iconSize) : 'w-6',
            )}
            aria-hidden="true"
          />
        )}
        {show && (
          <MinusSmIcon
            style={{ color: iconColor || 'lightGrey' }}
            className={classNames(
              iconSize ? getHeight(iconSize) : 'h-6',
              iconSize ? getWidth(iconSize) : 'w-6',
            )}
            aria-hidden="true"
          />
        )}
      </div>

      <div
        style={{
          transition: 'all',
          transitionTimingFunction: 'ease',
          transitionDuration: animationDuration
            ? animationDuration + 'ms'
            : '350ms',
          ...(show
            ? {
                opacity: 100,
                pointerEvents: 'auto',
                maxHeight: ref?.current?.clientHeight + 100 + 'px',
              }
            : { opacity: 0, pointerEvents: 'none', maxHeight: '0px' }),
        }}
        className={classNames(show ? 'pb-3 pl-3' : 'pb-0 pl-0')}
      >
        <div className={'w-full h-full'} ref={ref}>
          {children}
        </div>
      </div>
    </div>
  )
}

const filterOutFilters = (filters) => {
  filters.forEach((f) => {
    f.values = f.values?.filter((v) => v?.count !== 0)
  })
  filters = filters?.filter((f) => f?.values?.length > 0)
  return filters
}
const getColorFromFilter = (filter) => {
  if (filter?.data?.['hex']) {
    return filter?.data['hex']
  }
  return null
}
const getTitle = (filterMap, field) => {
  const filter = filterMap?.find((f) => f.key === field)
  if (filter?.value) {
    return filter?.value
  }
  return field
}
