import { MenuIcon } from '@heroicons/react/solid'
import { sbEditable } from '@storyblok/storyblok-editable'
import classNames from 'classnames'
import { getHeight, getWidth } from '../../helpers/getSize'
import { SideModal } from '../sharable/modals/SideModal'
import { useContext, useEffect, useState } from 'react'
import DynamicComponent from '../DynamicComponent'
import { getPadding } from '../../helpers/getPadding'
import { observer } from 'mobx-react'
import { SideMenuStoreContext } from '../../store/sideMenuStore'
import { TransitionWrapper } from './TransitionWrapper'
import { ArrowSmLeftIcon } from '@heroicons/react/outline'

export const SideMenu = observer(({ blok, data }) => {
  const {
    viewIndex,
    setViewIndex,
    setBackgroundColor,
    setCustom,
    custom,
    showMobileMenu,
    setShowMobileMenu,
  } = useContext(SideMenuStoreContext)

  useEffect(() => {
    setBackgroundColor(blok?.background_color)
  }, [blok?.background_color, setBackgroundColor])

  return (
    <div {...sbEditable(blok)}>
      <button
        type="button"
        style={{
          backgroundColor: 'transparent',
          color: blok?.icon_color ? blok?.icon_color : 'black',
        }}
        className="flex rounded-md text-black "
        onClick={() => {}}
      >
        <span className="sr-only">Open menu</span>
        <MenuIcon
          className={classNames(
            getWidth(blok?.size ?? 6),
            getHeight(blok?.size ?? 6),
          )}
          aria-hidden="true"
          onClick={() => {
            setShowMobileMenu(true)
            setViewIndex(0)
          }}
        />
      </button>

      <SideModal
        px={getPadding({
          prefix: 'px',
          mobile: blok?.padding_x ?? 6,
          desktop: blok?.padding_x ?? 6,
        })}
        py={getPadding({
          prefix: 'py',
          mobile: blok?.padding_y ?? 6,
          desktop: blok?.padding_y ?? 6,
        })}
        show={showMobileMenu}
        onClose={() => {
          setShowMobileMenu(false)
          setViewIndex(0)
        }}
        left={!blok?.slide_sidemenu_from_right}
        backgroundColor={
          blok?.background_color ? blok?.background_color : 'white'
        }
        textColor={blok?.text_color ? blok?.text_color : 'black'}
      >
        <>
          {viewIndex > 0 && (
            <div
              style={{
                backgroundColor: blok?.background_color
                  ? blok?.background_color
                  : 'white',
              }}
              className={'z-10 top-0 bottom-0 left-0 right-0 absolute'}
            />
          )}
          <div
            className={
              'relative h-[calc(100%-52px)] flex flex-col justify-between'
            }
          >
            {viewIndex > 0 && (
              <GoBackArrow
                textColor={blok?.text_color ? blok?.text_color : 'black'}
                onClick={() => {
                  setViewIndex(viewIndex - 1)
                  if (viewIndex - 1 === 0) {
                    setCustom(null)
                  }
                }}
              />
            )}

            <div>
              {blok.bloks?.map((nestedBlok, index) => (
                <DynamicComponent key={index} blok={nestedBlok} data={data} />
              ))}
            </div>

            <div className={'w-full'}>
              {blok.bottom_bloks?.map((nestedBlok, index) => (
                <DynamicComponent key={index} blok={nestedBlok} data={data} />
              ))}
            </div>

            <TransitionWrapper
              blok={{
                background_color: blok?.background_color
                  ? blok?.background_color
                  : 'white',
              }}
              data={data}
              show={viewIndex > 0}
            >
              {!custom &&
                blok?.default_submenu?.length > 0 &&
                blok?.default_submenu?.map((nestedBlok, index) => {
                  return (
                    <DynamicComponent
                      key={index}
                      blok={nestedBlok}
                      data={data}
                    />
                  )
                })}
              {custom &&
                custom.bloks?.map((nestedBlok, index) => {
                  return (
                    <DynamicComponent
                      key={index}
                      blok={nestedBlok}
                      data={data}
                    />
                  )
                })}
            </TransitionWrapper>
          </div>
        </>
      </SideModal>
    </div>
  )
})

const GoBackArrow = ({ onClick, textColor }) => {
  return (
    <div className={classNames('fixed top-0 z-50 flex items-center h-20 pb-2')}>
      <ArrowSmLeftIcon
        style={{
          color: textColor,
        }}
        className={classNames('h-6 w-6 text-black')}
        onClick={() => {
          if (onClick) {
            onClick()
          }
        }}
      />
    </div>
  )
}
