const height = {
  none: 'leading-none',
  tight: 'leading-tight',
  snug: 'leading-snug',
  normal: 'leading-normal',
  relaxed: 'leading-relaxed',
  loose: 'leading-loose',
}

export const getLineHeight = (h) => {
  return height[h] ?? ''
}

// For tailwind
const heightDesktop = {
  none: 'lg:leading-none',
  tight: 'lg:leading-tight',
  snug: 'lg:leading-snug',
  normal: 'llg:eading-normal',
  relaxed: 'lg:leading-relaxed',
  loose: 'lg:leading-loose',
}
