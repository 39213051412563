import classNames from 'classnames'
import DynamicComponent from '../DynamicComponent'
import Link from 'next/link'
import { getHref } from '../../helpers/getHref'
import { getPadding } from '../../helpers/getPadding'
import { getGridColSpan } from '../../helpers/getGridColSpan'
import { geMobileOrder } from '../../helpers/getMobileOrder'
import { getBorderRadius } from '../../helpers/getBorderRadius'
import { sbEditable } from '@storyblok/storyblok-editable'
import { getFlexDirection } from '../../helpers/getFlexDirection'
import { getSpaceY } from '../../helpers/getSpaceY'
import { getSpaceX } from '../../helpers/getSpaceX'
import { getJustifyContent } from '../../helpers/getJustifyContent'
import { getOverflow } from '../../helpers/getOverflow'
import { getBackground } from '../../helpers/getBackground'
import { useCheckNestedLink } from '../../hooks/useCheckNestedLink'

export function Column({ data, blok, preview }) {
  const link =
    blok?.link?.url?.length > 0 || blok?.link?.id?.length > 0
      ? blok?.link
      : false
  const Tag = link ? 'a' : 'div'
  const flexDirection = getFlexDirection(blok?.flex_direction)
  const [isNestedStyle] = useCheckNestedLink({ blok, preview, isLink: link })

  const item = (
    <Tag
      {...sbEditable(blok)}
      className={classNames(
        'relative flex w-full',
        getBorderRadius(blok?.border_radius),
        geMobileOrder({ mobile: blok?.order_mobile }),
        getPadding({
          prefix: 'px',
          desktop: blok?.padding_x_desktop,
          mobile: blok?.padding_x_mobile,
        }),
        getPadding({
          prefix: 'py',
          desktop: blok?.padding_y_desktop,
          mobile: blok?.padding_y_mobile,
        }),
        getPadding({
          prefix: 'pr',
          desktop: blok?.padding_r_desktop,
          mobile: blok?.padding_r_mobile,
        }),
        getPadding({
          prefix: 'pl',
          desktop: blok?.padding_l_desktop,
          mobile: blok?.padding_l_mobile,
        }),
        getPadding({
          prefix: 'pt',
          desktop: blok?.padding_t_desktop,
          mobile: blok?.padding_t_mobile,
        }),
        getPadding({
          prefix: 'pb',
          desktop: blok?.padding_b_desktop,
          mobile: blok?.padding_b_mobile,
        }),
        getGridColSpan({
          mobile: blok?.col_span_mobile,
          desktop: blok?.col_span_desktop,
        }),
        getHeight({
          mobile: blok?.height_mobile,
          desktop: blok?.height_desktop,
        }),
        getOverflow(blok?.overflow),
      )}
      style={{ ...getStyles({ blok }), ...isNestedStyle }}
      {...(blok?.link_target?.length > 0 ? { target: '_blank' } : {})}
    >
      {getBackground({ blok, aboveFold: data.aboveFold })}
      <div
        className={classNames(
          'relative flex w-full ',
          flexDirection,
          getSpaceX(blok?.space_x_desktop, 'lg'),
          getSpaceY(blok?.space_y_desktop, 'lg'),
          getSpaceX(blok?.space_x_mobile),
          getSpaceY(blok?.space_y_mobile),
          getAlign(
            blok?.align_y_mobile,
            flexDirection === 'flex-row' ? 'items' : 'justify',
          ),
          getAlign(
            blok?.align_x_mobile,
            flexDirection === 'flex-row' ? 'justify' : 'items',
          ),
          getAlignDesktop(
            blok?.align_y_desktop,
            flexDirection === 'flex-row' ? 'lg:items' : 'lg:justify',
          ),
          getAlignDesktop(
            blok?.align_x_desktop,
            flexDirection === 'flex-row' ? 'lg:justify' : 'lg:items',
          ),
          getJustifyContent(blok?.justify_content),
        )}
      >
        {blok.bloks?.map((nestedBlok, index) => (
          <DynamicComponent
            key={index}
            blok={{ ...nestedBlok, parentIsLink: link }}
            data={data}
            preview={preview}
          />
        ))}
      </div>
    </Tag>
  )

  // if column is clickable and have URL
  if (link) {
    return <Link href={getHref(link)}>{item}</Link>
  }
  return item
}

const classesYX = {
  top: '-start',
  center: '-center',
  bottom: '-end',
  left: '-start',
  right: '-end',
}
const getAlign = (align, prefix) => {
  if (align) {
    return prefix + classesYX[align] ?? false
  }
  return ''
}

const getAlignDesktop = (align, prefix) => {
  if (align) {
    return prefix + classesYX[align] ?? false
  }
  return ''
}

const getStyles = ({ blok }) => {
  let styles = {}

  if (blok?.background_color) {
    styles['backgroundColor'] = blok?.background_color
  }
  if (blok?.border_top) {
    styles['borderTop'] = blok?.border_top
  }
  if (blok?.border_right) {
    styles['borderRight'] = blok?.border_right
  }
  if (blok?.border_bottom) {
    styles['borderBottom'] = blok?.border_bottom
  }
  if (blok?.border_left) {
    styles['borderLeft'] = blok?.border_left
  }

  return styles
}

const heights = {
  sm: 'h-sm-vh',
  md: 'h-md-vh',
  lg: 'h-lg-vh',
  full: 'h-full-vh',
  square: 'aspect-square',
  auto: 'h-auto',
  'vh-35': 'h-[35vh]',
  'vh-45': 'h-[45vh]',
  'vh-55': 'h-[55vh]',
  'vh-65': 'h-[65vh]',
  'vh-75': 'h-[75vh]',
  'vh-85': 'h-[85vh]',
  h1: 'h-1',
  h2: 'h-2',
  h3: 'h-3',
  h4: 'h-4',
  h5: 'h-5',
  h6: 'h-6',
  h7: 'h-7',
  h8: 'h-8',
  h9: 'h-9',
  h10: 'h-10',
  h11: 'h-11 ',
  h12: 'h-12 ',
  h13: 'h-13 ',
  h14: 'h-14 ',
  h16: 'h-16 ',
  h20: 'h-20 ',
  h24: 'h-24 ',
  h28: 'h-28 ',
  h32: 'h-32 ',
  h36: 'h-36 ',
  h40: 'h-40 ',
  h44: 'h-44 ',
  h48: 'h-48 ',
  h52: 'h-52 ',
  h56: 'h-56 ',
  h60: 'h-60 ',
  h62: 'h-62 ',
  h72: 'h-72 ',
  h80: 'h-80 ',
  h96: 'h-96 ',
}
const heightsDesktop = {
  sm: 'lg:h-sm-vh lg:aspect-auto',
  md: 'lg:h-md-vh lg:aspect-auto',
  lg: 'lg:h-lg-vh lg:aspect-auto',
  full: 'lg:h-full-vh lg:aspect-auto',
  square: 'lg:h-auto lg:aspect-square',
  auto: 'lg:h-auto lg:aspect-auto',
  'vh-35': 'lg:h-[35vh] g:aspect-auto',
  'vh-45': 'lg:h-[45vh] g:aspect-auto',
  'vh-55': 'lg:h-[55vh] g:aspect-auto',
  'vh-65': 'lg:h-[65vh] g:aspect-auto',
  'vh-75': 'lg:h-[75vh] g:aspect-auto',
  'vh-85': 'lg:h-[85vh] g:aspect-auto',
  h1: 'lg:h-1 g:aspect-auto',
  h2: 'lg:h-2 g:aspect-auto',
  h3: 'lg:h-3 g:aspect-auto',
  h4: 'lg:h-4 g:aspect-auto',
  h5: 'lg:h-5 g:aspect-auto',
  h6: 'lg:h-6 g:aspect-auto',
  h7: 'lg:h-7 g:aspect-auto',
  h8: 'lg:h-8 g:aspect-auto',
  h9: 'lg:h-9 g:aspect-auto',
  h10: 'lg:h-10 lg:aspect-auto',
  h11: 'lg:h-11  lg:aspect-auto',
  h12: 'lg:h-12  lg:aspect-auto',
  h13: 'lg:h-13  lg:aspect-auto',
  h14: 'lg:h-14  lg:aspect-auto',
  h16: 'lg:h-16  lg:aspect-auto',
  h20: 'lg:h-20  lg:aspect-auto',
  h24: 'lg:h-24  lg:aspect-auto',
  h28: 'lg:h-28  lg:aspect-auto',
  h32: 'lg:h-32  lg:aspect-auto',
  h36: 'lg:h-36  lg:aspect-auto',
  h40: 'lg:h-40  lg:aspect-auto',
  h44: 'lg:h-44  lg:aspect-auto',
  h48: 'lg:h-48  lg:aspect-auto',
  h52: 'lg:h-52  lg:aspect-auto',
  h56: 'lg:h-56  lg:aspect-auto',
  h60: 'lg:h-60  lg:aspect-auto',
  h62: 'lg:h-62  lg:aspect-auto',
  h72: 'lg:h-72  lg:aspect-auto',
  h80: 'lg:h-80  lg:aspect-auto',
  h96: 'lg:h-96  lg:aspect-auto',
}
const getHeight = ({ mobile, desktop }) => {
  let heightMobile = heights[mobile] ?? ''
  let heightDesktop = heightsDesktop[desktop] ?? ''

  return `${heightMobile} ${heightDesktop}`.trim()
}

// For tailwind
const classes = {
  left: 'items-start',
  center: 'items-center',
  right: 'items-end',
  center2: 'justify-center',
  bottom: 'justify-end',
}

// For tailwind
const classesYDesktop = {
  top: 'lg:justify-start',
  center: 'lg:justify-center',
  bottom: 'lg:justify-end',
  left: 'lg:items-start',
  center2: 'lg:items-center',
  right: 'lg:items-end',
}
