const classes = {
  left: 'text-left',
  center: 'text-center',
  right: 'text-right',
  justify: 'text-justify',
}

export const getTextAlign = (textAlign) => {
  if (!textAlign || textAlign === '' || textAlign == null) {
    return ''
  }

  if (classes[textAlign]) {
    return classes[textAlign]
  } else {
    console.error('Error setting text align', textAlign)
    return ''
  }
}
