import { getProductPrice } from '../../../../helpers/getProductPrice'
import { getProductImages } from '../../../../helpers/getProductImage'
import { useContext, useEffect, useRef } from 'react'
import { CartStoreContext } from '../../../../store/cartStore'
import { observer } from 'mobx-react'
import { getYotpoProductId } from '../../../../helpers/getProductTrackingId'
import { isServer } from '../../../../helpers/isServer'
import { YotpoScript } from '../../../../helpers/getYotpoScript'
import { ClientOnly } from '../../../atoms/ClientOnly'
import { useRouter } from 'next/router'

export const YotpoProductReviews = observer(({ blok, data }) => {
  const { CSObject } = useContext(CartStoreContext)
  const router = useRouter()
  const prices = getProductPrice({ CSObject, product: data?.product })
  const images = getProductImages(data?.product)
  const element = useRef()
  const url = !isServer ? window.location.href : ''
  useEffect(() => {
    if (window?.yotpo && window.yotpo?.refreshWidgets) {
      window.yotpo.refreshWidgets()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.asPath, element?.current])

  return (
    <ClientOnly>
      <div className={'flex justify-center w-full'}>
        <YotpoScript data={data} />
        <div
          ref={element}
          className="yotpo yotpo-main-widget"
          data-product-id={getYotpoProductId({ product: data.product })}
          data-price={prices?.priceAsNumber}
          data-currency={CSObject?.selection?.currency}
          data-name={data?.product.name}
          data-url={url}
          data-image-url={images?.[0]?.url}
        ></div>
      </div>
    </ClientOnly>
  )
})
