import { observer } from 'mobx-react'
import { useContext, useState } from 'react'
import { ProductsDataStoreContext } from '../../../../store/productsDataStore'
import classNames from 'classnames'
import { addClassesToText } from '../../../../helpers/addClassesToText'
import { getText } from '../../../../helpers/getText'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'
import { sbEditable } from '@storyblok/storyblok-editable'

export const ProductPageShortDesc = observer(({ data, blok }) => {
  const { productPageProduct } = useContext(ProductsDataStoreContext)
  const product = productPageProduct

  const [showMore, setShowMore] = useState(false)

  return (
    <>
      {/* Short Description */}
      {blok?.fixed_description_height === 'yes' &&
      product?.excerptHtml !== '' ? (
        <>
          <div
            {...sbEditable(blok)}
            className={classNames(
              'text-sm leading-6 mb-3 mt-6 overflow-hidden ',

              showMore ? '' : 'max-h-[100px] ',
            )}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: addClassesToText(product?.excerptHtml),
              }}
            />
          </div>
          <div
            className={
              'flex items-center w-full text-gray-500 flex justify-center items-end text-2xs pb-3 cursor-pointer font-light'
            }
            onClick={() => {
              setShowMore(!showMore)
            }}
          >
            {showMore
              ? getText(blok?.show_more_title, data)
              : getText(blok?.hide_title, data)}
            {showMore ? (
              <ChevronUpIcon className="ml-2 h-4 w-4" />
            ) : (
              <ChevronDownIcon className="ml-2  h-4 w-4" />
            )}
          </div>
        </>
      ) : (
        <div
          {...sbEditable(blok)}
          className="text-sm leading-6 mb-6 mt-6"
          dangerouslySetInnerHTML={{
            __html: addClassesToText(product?.excerptHtml),
          }}
        />
      )}
    </>
  )
})
