const sizesW = {
  0: 'w-0',
  1: 'w-1',
  2: 'w-2',
  3: 'w-3',
  4: 'w-4',
  5: 'w-5',
  6: 'w-6',
  7: 'w-7',
  8: 'w-8',
  9: 'w-9',
  10: 'w-10',
  12: 'w-12',
  14: 'w-14',
  16: 'w-16',
  20: 'w-20',
  24: 'w-24',
  28: 'w-28',
  32: 'w-32',
  36: 'w-36',
  40: 'w-40',
  44: 'w-44',
  48: 'w-48',
  52: 'w-52',
  56: 'w-56',
  60: 'w-60',
  64: 'w-64',
  72: 'w-72',
  80: 'w-80',
  96: 'w-96',
}
const sizesH = {
  0: 'h-0',
  1: 'h-1',
  2: 'h-2',
  3: 'h-3',
  4: 'h-4',
  5: 'h-5',
  6: 'h-6',
  7: 'h-7',
  8: 'h-8',
  9: 'h-9',
  10: 'w-10',
  12: 'h-12',
  14: 'h-14',
  16: 'h-16',
  20: 'h-20',
  24: 'h-24',
  28: 'h-28',
  32: 'h-32',
  36: 'h-36',
  40: 'h-40',
  44: 'h-44',
  48: 'h-48',
  52: 'h-52',
  56: 'h-56',
  60: 'h-60',
  64: 'h-64',
  72: 'h-72',
  80: 'h-80',
  96: 'h-96',
}
const sizeRem = {
  0: '0',
  1: '0.25rem',
  2: '0.50rem',
  3: '0.75rem',
  6: '1.5rem',
  9: '2.25rem',
  12: '3rem',
  14: '3.5rem',
  16: '4rem',
  20: '5rem',
  24: '6rem',
  28: '7rem',
  32: '8rem',
  36: '9rem',
  40: '10rem',
  44: '11rem',
  48: '12rem',
  52: '13rem',
  56: '14rem',
  60: '15rem',
  64: '16rem',
  72: '18rem',
  80: '20rem',
  96: '24rem',
}

export const getWidth = (size) => {
  return sizesW[size] ?? ''
}

export const getHeight = (size) => {
  return sizesH[size] ?? ''
}

export const getRem = (size) => {
  return sizeRem[size] ?? ''
}
