import React, { useEffect } from 'react'
import classNames from 'classnames'
import { getPadding } from '../../helpers/getPadding'
import { sbEditable } from '@storyblok/storyblok-editable'

export const TrustPilot = ({ blok, data }) => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null)
  useEffect(() => {
    renderTrust()
    const interval = setInterval(() => {
      if (window.Trustpilot && ref.current) {
        window.Trustpilot.loadFromElement(ref.current, true)
        clearInterval(interval)
      }
    }, 200)
    return () => clearInterval(interval)
  }, [])
  return (
    <div
      {...sbEditable(blok)}
      className={classNames(
        'flex justify-center w-full items-center',
        getPadding({ prefix: 'py', mobile: blok?.padding_y }),
      )}
    >
      <div
        ref={ref}
        className="trustpilot-widget"
        data-locale={blok?.data_locale}
        data-template-id={blok?.data_template_id}
        data-businessunit-id={blok?.data_businessunit_unit_id}
        data-style-height={blok?.data_style_height + 'px'}
        data-style-width="100%"
        data-theme={data?.data_theme}
      >
        <a target="_blank" rel="noopener">
          Trustpilot
        </a>
      </div>
    </div>
  )
}

const renderTrust = () => {
  const id = "trust-pilot-widget-1337'"
  const element = document.getElementById(id)
  if (!element) {
    const script = document.createElement('script')

    script.src =
      '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
    script.async = true
    script.id = id

    document.body.appendChild(script)
  }
}
