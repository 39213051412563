import React, { useState } from 'react'
import NextImage from 'next/future/image'
import classNames from 'classnames'

export const ImageZoom = ({
  src,
  className,
  alt,
  width,
  height,
  priority,
  rounded,
}) => {
  const [zoomed, setZoomed] = useState('1')
  const [position, setPosition] = useState('50% 50%')

  const zoomInPosition = (e) => {
    const zoomer = e.currentTarget.getBoundingClientRect()
    let x = ((e.clientX - zoomer.x) / zoomer.width) * 100
    let y = ((e.clientY - zoomer.y) / zoomer.height) * 100
    setPosition(`${x}% ${y}%`)
  }

  const toggleZoomImage = (e) => {
    if (zoomed === '0') {
      // zoom out
      setZoomed('1')
    } else {
      //zoom in and set the background position correctly
      setZoomed('0')
      zoomInPosition(e)
    }
  }

  const handleClick = (e) => {
    toggleZoomImage(e)
  }

  const handleMove = (e) => {
    if (zoomed === '0') {
      zoomInPosition(e)
    }
  }

  const handleLeave = () => {
    // Resets the state of the component on mouse leave
    setZoomed('1')
    setPosition('50% 50%')
  }

  return (
    <div
      style={{
        backgroundImage: `url( ${src} )`,
        backgroundSize: zoomed === '0' ? '200%' : '100%',
        backgroundPosition: position,
        height: '100%',
        width: '100%',
      }}
      onClick={(e) => handleClick(e)}
      onMouseMove={(e) => handleMove(e)}
      onMouseLeave={() => handleLeave()}
      className={classNames(rounded)}
      // onMouseEnter={(e) => handleClick(e)}
    >
      <NextImage
        src={src}
        className={className}
        alt={alt}
        style={{ opacity: zoomed, cursor: 'zoom-in' }}
        width={width}
        height={height}
        priority={priority}
      />
    </div>
  )
}
