import { Button } from '../../../atoms/Button'
import { getText } from '../../../../helpers/getText'
import classNames from 'classnames'
import { CartStoreContext } from '../../../../store/cartStore'
import { ProductsDataStoreContext } from '../../../../store/productsDataStore'
import { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import { isItemInStock } from '../../../../helpers/isItemInStock'
import { sbEditable } from '@storyblok/storyblok-editable'

export const ProductPageAddToCartButton = observer(({ blok, data }) => {
  const { addToCart } = useContext(CartStoreContext)
  const { getItem, productPageProduct } = useContext(ProductsDataStoreContext)
  const product = productPageProduct
  const [addToCartLoading, setAddToCartLoading] = useState(false)

  const isInStock = () => {
    const i = getItem()
    if (i) {
      return isItemInStock(i?.stock)
    }

    return false
  }

  return (
    <div
      id={'v95-add-to-cart-button'}
      name={'ventanas-add-to-cart-button'}
      {...sbEditable(blok)}
    >
      <Button
        loading={addToCartLoading}
        afterLoadingMessage={getText(
          data?.settingsData?.added_to_cart_message,
          data,
        )}
        disabled={!isInStock()}
        type="button"
        variant={blok?.button_style}
        className={classNames('w-full mt-3 ')}
        onClick={async () => {
          setAddToCartLoading(true)
          await addToCart(getItem(), product)
          setAddToCartLoading(false)
        }}
      >
        {isInStock()
          ? getText(data?.settingsData?.add_to_cart_button_title, data)
          : getText(data?.settingsData?.out_of_stock_title, data)}
      </Button>
    </div>
  )
})
