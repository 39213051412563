import { ChevronRightIcon } from '@heroicons/react/outline'
import { sbEditable } from '@storyblok/storyblok-editable'
import classNames from 'classnames'
import { getPadding } from '../../helpers/getPadding'
import { getFontWeight } from '../../helpers/getFontWeight'
import { getFontSize } from '../../helpers/getFontSize'
import { getHeight, getWidth } from '../../helpers/getSize'
import Link from 'next/link'
import { getHref } from '../../helpers/getHref'
import { SideMenuStoreContext } from '../../store/sideMenuStore'
import { useContext } from 'react'
import { getText } from '../../helpers/getText'

export const SideMenuLink = ({ blok, data }) => {
  const {
    viewIndex,
    setViewIndex,
    setCentraCategoryUri,
    setCustom,
    setShowMobileMenu,
  } = useContext(SideMenuStoreContext)
  const link =
    blok?.url?.url?.length > 0 || blok?.url?.id?.length > 0 ? blok?.url : false

  return (
    <>
      <SideMenuLinkItem
        data={data}
        blok={blok}
        link={link ? getHref(link) : false}
        onClick={() => {
          if (!link) {
            setCentraCategoryUri(blok?.centra_category_uri)
            if (blok?.submenu?.[0]) {
              setCustom(blok?.submenu?.[0])
            }
            setViewIndex(viewIndex + 1)
          } else {
            setShowMobileMenu(false)
          }
        }}
      />
    </>
  )
}

export const SideMenuLinkItem = ({ blok, data, link, onClick }) => {
  const padding = blok?.padding_y?.length > 0 ? blok?.padding_y : 3
  const linkClasses = classNames(
    'w-full',
    getFontWeight(blok?.font_weight),
    getFontSize(blok?.font_size),
    getPadding({
      prefix: 'py',
      desktop: padding,
      mobile: padding,
    }),
  )

  return (
    <div
      {...sbEditable(blok)}
      style={{ color: blok?.text_color ? blok?.text_color : 'black' }}
      className={classNames(
        'flex items-center justify-between cursor-pointer w-full ',
      )}
      onClick={() => {
        if (onClick) {
          onClick()
        }
      }}
    >
      {link ? (
        <Link href={link}>
          <a className={linkClasses}>{getText(blok?.title, data)}</a>
        </Link>
      ) : (
        <p className={linkClasses}> {getText(blok?.title, data)}</p>
      )}

      {!link && (
        <ChevronRightIcon
          style={{ color: blok?.text_color ? blok?.text_color : 'black' }}
          className={classNames(
            getWidth(blok?.icon_size ?? 6),
            getHeight(blok?.icon_size ?? 6),
          )}
          aria-hidden="true"
        />
      )}
    </div>
  )
}
