import { Button } from '../../atoms/Button'
import { useContext, useState } from 'react'
import { CartStoreContext } from '../../../store/cartStore'
import classNames from 'classnames'
import { getFontSize } from '../../../helpers/getFontSize'
import { Input } from '../../atoms/Input'
import Storyblok from '../../../lib/storyblok/storyblok'

export const FooterNewsLetter = ({ blok }) => {
  const { subscribeToNewLetter } = useContext(CartStoreContext)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)
  const textColor = blok?.text_color?.length > 0 ? blok.text_color : ''
  const input_border_color = blok?.input_border_color ?? 'black'
  const [checked, setChecked] = useState(false)
  const needCheck = blok?.show_confirm_check === 'yes'
  const html = blok?.confirm_text?.content
    ? Storyblok.richTextResolver.render(blok?.confirm_text)
    : blok?.confirm_text
  const uid = blok?._uid

  return (
    <div className={'w-full'} style={{ color: textColor }}>
      {blok?.info && (
        <p className={classNames('mb-1', getFontSize(blok?.font_size))}>
          {blok?.info}
        </p>
      )}

      <form
        className="flex flex-col"
        onSubmit={async (event) => {
          event.preventDefault()
          if (email && email.length > 3) {
            setLoading(true)
            const s = await subscribeToNewLetter({ email: email })
            if (s) {
              setSuccess(true)
            }
            setLoading(false)
          }
        }}
      >
        <label htmlFor={`email-address-${uid}`} className="sr-only">
          {blok?.email_placeholder}
        </label>
        {success ? (
          <div>
            <p className={classNames(getFontSize(blok?.font_size))}>
              {blok?.subscribed_title ?? 'Subscribed!'}
            </p>
          </div>
        ) : (
          <>
            <Input
              id={`email-address-${uid}`}
              type="text"
              autoComplete="email"
              onChange={(event) => {
                setEmail(event.target.value)
              }}
              value={email}
              placeholder={blok?.email_placeholder}
              required
              style={{ borderColor: input_border_color }}
            />
            <div className="mt-3 flex-shrink-0">
              {needCheck ? (
                <Button
                  disabled={!checked}
                  loading={loading}
                  type={'submit'}
                  className="w-full"
                  variant={blok?.button_style}
                >
                  {blok?.subscribe_button_title}
                </Button>
              ) : (
                <Button
                  loading={loading}
                  type={'submit'}
                  className="w-full"
                  variant={blok?.button_style}
                >
                  {blok?.subscribe_button_title}
                </Button>
              )}
            </div>
          </>
        )}
      </form>

      {!success && needCheck && (
        <div className={'flex space-x-3 pt-3 items-center'}>
          <input
            id={`email-check-${uid}`}
            type="checkbox"
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
          <label
            htmlFor={`email-check-${uid}`}
            className={'text-sm'}
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          ></label>
        </div>
      )}
    </div>
  )
}
