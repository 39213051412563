import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { getFontWeight } from '../../../helpers/getFontWeight'
import { getFontSize } from '../../../helpers/getFontSize'
import { getLineHeight } from '../../../helpers/getLineHeight'
import { getTextAlign } from '../../../helpers/getTextAlign'

export const SideModal = ({
  title,
  show,
  onClose,
  children,
  left = false,
  titleStyle,
  backgroundColor = 'white',
  textColor = 'black',
  iconSize = 'w-6 h-6',
  px = 'px-6',
  py = 'py-6',
}) => {
  let refDiv = useRef(null)

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        initialFocus={refDiv}
        as="div"
        className="fixed inset-0 z-[99991]"
        // This fix the dubbel portal modals click through 💪
        onClose={() => {}}
      >
        <div className="absolute inset-0" ref={refDiv}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-50 transition-opacity">
              <div
                className={'absolute inset-0 '}
                onClick={() => {
                  // This fix the dubbel portal modals click through 💪
                  onClose()
                }}
              />
            </Dialog.Overlay>
          </Transition.Child>

          <div
            className={classNames(
              'fixed inset-y-0 max-w-full flex',
              left
                ? 'left-0 right-12 md:right-auto'
                : 'right-0 left-12 md:left-auto',
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300"
              enterFrom={left ? '-translate-x-full' : 'translate-x-full'}
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-300"
              leaveFrom="translate-x-0"
              leaveTo={left ? '-translate-x-full' : 'translate-x-full'}
            >
              <div
                className="w-screen max-w-md relative"
                style={{ backgroundColor: backgroundColor }}
              >
                <div className="h-full flex flex-col shadow-xl lg:overflow-auto">
                  <div
                    className={classNames(
                      'flex-1 overflow-y-auto scrollbar-thin',
                      py,
                      px,
                    )}
                  >
                    <div className="flex items-start justify-between mb-6">
                      <Dialog.Title
                        style={{ color: textColor }}
                        className={classNames(
                          getFontWeight(titleStyle?.font_weight) ??
                            'font-medium', // this was the default before we added text_style
                          getFontSize(titleStyle?.font_size) ?? 'text-lg', // this was the default before we added text_style
                          getLineHeight(titleStyle?.line_height),
                          getTextAlign(titleStyle?.text_align),
                        )}
                      >
                        {title}
                      </Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          style={{ color: textColor }}
                          type="button"
                          className="-m-2 p-2 text-black hover:text-gray-500 outline-none"
                          onClick={() => onClose(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon
                            className={classNames(iconSize)}
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                    {children}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
