import { Button } from '../../atoms/Button'

export const PaginationLoadMore = ({
  total,
  limit,
  skipPages,
  onPageClick,
  loading,
  info,
  loadMoreTitle,
}) => {
  const currentPageIndex = skipPages
  if (total <= limit) {
    //return null
  }

  let count = (currentPageIndex + 1) * limit
  count = count > total ? total : count
  count = isNaN(count) ? total : count

  return (
    <div className="py-6 flex items-center flex-col justify-center">
      {info && (
        <div className={'mb-3'}>
          <p className="text-sm text-gray-700 text-center">
            {info?.replace('%count%', count)?.replace('%total%', total)}
          </p>
        </div>
      )}
      {total > count && (
        <Button
          loading={loading}
          onClick={() => {
            if (total > (currentPageIndex + 1) * limit) {
              onPageClick(currentPageIndex + 1)
            }
          }}
        >
          {loadMoreTitle}
        </Button>
      )}
    </div>
  )
}
