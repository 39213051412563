import Storyblok from '../../lib/storyblok/storyblok'
import classNames from 'classnames'
import { sbEditable } from '@storyblok/storyblok-editable'
import { getTextAlign } from '../../helpers/getTextAlign'
import { getFontWeight } from '../../helpers/getFontWeight'
import { getFontSize } from '../../helpers/getFontSize'
import { addClassesToText } from '../../helpers/addClassesToText'
import { getLineHeight } from '../../helpers/getLineHeight'
import { getText } from '../../helpers/getText'
import { useCheckNestedLink } from '../../hooks/useCheckNestedLink'

export const RichText = ({ data, blok, preview }) => {
  let html = Storyblok.richTextResolver.render(blok?.body)
  const classesToText = addClassesToText(getText(html, data), data)

  const [isNestedStyle] = useCheckNestedLink({
    blok,
    preview,
    isLink: classesToText?.includes('href='),
  })

  return (
    <div
      {...sbEditable(blok)}
      style={{
        color: blok?.text_color ?? 'black',
        wordBreak: 'break-word',
        ...isNestedStyle,
      }}
      className={classNames(
        getFontWeight(blok?.font_weight),
        getFontSize(blok?.font_size),
        getTextAlign(blok?.text_align),
        getLineHeight(blok?.line_height),
      )}
      dangerouslySetInnerHTML={{
        __html: classesToText,
      }}
    ></div>
  )
}
