import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { getPadding } from '../../helpers/getPadding'
import { sbEditable } from '@storyblok/storyblok-editable'
import Script from 'next/script'

export const Instagram = ({ blok, data }) => {
  return (
    <div
      {...sbEditable(blok)}
      className={classNames(
        'flex justify-center items-center',
        getPadding({ prefix: 'py', mobile: blok?.padding_y }),
      )}
    >
      <Script
        src="https://cdn.lightwidget.com/widgets/lightwidget.js"
        strategy="lazyOnload"
      ></Script>
      <iframe
        src={blok?.lightwidget_url}
        scrolling="no"
        className="lightwidget-widget"
        style={{ width: '100%', border: 0, overflow: 'hidden' }}
      />
    </div>
  )
}
